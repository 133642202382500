import classNames from 'classnames';
import { isUnread } from 'helpers/connections';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getAcceptedConnections, getRumiConnections } from 'selectors';
import { ProfileStatus } from 'types';
import { history } from '../../helpers/history';
import ConnectionPlaceholder from './ConnectionPlaceholder';
import './Connections.scss';

const NoMessagesYet = () => {
  return (
    <div className="w-full py-16 text-center text-gray-400 select-none">
      <div className="block p-4">Start waving to connect with a potential roommate!</div>
      <Link to="/search" className="button button-primary">
        Search
      </Link>
    </div>
  );
};

const ConnectionTab = ({ con, slug, connectionUser, isUnreadConnection }) => {
  return (
    <div
      className={classNames(slug === con.slug && 'active', 'user')}
      onClick={() => history.push(`/connections/messages/${con.slug}`)}
      key={con.id}
    >
      <div className="flex flex-wrap justify-center mr-2">
        <div className="flex items-center w-12 h-12 overflow-hidden bg-white border rounded-full shadow">
          <img
            className="w-full"
            src={connectionUser.image || require('../../assets/default_avatar.png').default}
            alt={connectionUser.firstName + ' profile photo'}
          />
        </div>
      </div>
      <div className="flex flex-1">
        <div className="flex flex-col justify-center flex-1 truncate">
          <div className="flex flex-row justify-between">
            <div className="font-semibold">{connectionUser.firstName}</div>
            {connectionUser.status === ProfileStatus.PAUSED && (
              <>
                <div
                  data-tip="React-tooltip"
                  data-for={`react-tooltip-${connectionUser?.id}`}
                  className="px-2 font-semibold text-white bg-blue-500 rounded-full"
                >
                  Paused
                </div>
                <ReactTooltip
                  id={`react-tooltip-${connectionUser?.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  {connectionUser.firstName} has temporarily paused their account
                </ReactTooltip>
              </>
            )}
          </div>
          <div className="w-full overflow-hidden text-gray-500 overflow-ellipsis">
            {isUnreadConnection ? (
              !con.receiverReadAt ? (
                <>New connection!</>
              ) : (
                <>New message</>
              )
            ) : con.lastMessage ? (
              <>{con.lastMessage.content}</>
            ) : (
              <>You connected with {connectionUser.firstName}!</>
            )}
          </div>
        </div>
        {isUnreadConnection && (
          <div className="flex items-center">
            <div className="w-3 h-3 bg-blue-500 rounded-full" />
          </div>
        )}
      </div>
    </div>
  );
};

const MessageList = ({ isLoading }) => {
  const connections: any = useSelector(getAcceptedConnections);
  const rumiConnections: any = useSelector(getRumiConnections);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const { slug }: any = useParams();

  // automatically opens the first message
  // useEffect(() => {
  //   if (connections?.length && !slug && !isMobileOrTablet()) {
  //     const firstConnection = connections[0];
  //     // is read
  //     if (!isUnread(firstConnection, currentUser.id)) {
  //       history.replace(`/connections/messages/${firstConnection?.slug}`);
  //     }
  //   }
  // }, [connections]);

  return (
    <>
      <Helmet>
        <title>Messages - Connections | Rumi</title>
      </Helmet>
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        {isLoading ? (
          [1, 2, 3, 4].map((i) => {
            const placeholderClassNames = classNames(`opacity-${100 - i * 20}`);
            return <ConnectionPlaceholder key={i} className={placeholderClassNames} />;
          })
        ) : !connections?.length && !rumiConnections?.length ? (
          <NoMessagesYet />
        ) : (
          <>
            {!!rumiConnections && !!rumiConnections.length && (
              <>
                <h2 className="flex flex-1 p-2 border-b">Rumi Team</h2>
                {rumiConnections.map((con: any) => {
                  const connectionUser =
                    con.initiator.id === currentUser.id ? con.receiver : con.initiator;
                  const isUnreadConnection = isUnread(con, currentUser.id);

                  return (
                    <ConnectionTab
                      key={con.id}
                      con={con}
                      slug={slug}
                      connectionUser={connectionUser}
                      isUnreadConnection={isUnreadConnection}
                    />
                  );
                })}
              </>
            )}

            {!!connections && !!connections.length && (
              <>
                <h2 className="flex flex-1 p-2 border-b">Potential Roommates</h2>
                {connections.map((con: any) => {
                  // I sent the invite
                  const connectionUser =
                    con.initiator.id === currentUser.id ? con.receiver : con.initiator;
                  const isUnreadConnection = isUnread(con, currentUser.id);

                  return (
                    <ConnectionTab
                      key={con.id}
                      con={con}
                      slug={slug}
                      connectionUser={connectionUser}
                      isUnreadConnection={isUnreadConnection}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MessageList;
