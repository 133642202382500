import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDetectOutsideClick } from '../../helpers/useDetectOutsideClick';

const DropdownMenu = ({ menuItems, className = '', buttonClassName = '', children }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const onClick = () => setIsActive(!isActive);

  return (
    <div className={className}>
      <button
        type="button"
        className={
          buttonClassName
            ? buttonClassName
            : 'inline-flex items-center justify-center px-2 py-2 text-gray-700 text-sm font-medium hover:bg-gray-50 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:ring-offset-gray-100 focus:ring-offset-2 focus:ring-2'
        }
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={onClick}
      >
        {children}
      </button>
      {isActive && (
        <div ref={dropdownRef} className="relative z-10 inline-block">
          <div
            className="absolute right-0 z-10 mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-xl ring-black ring-opacity-5 ring-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {menuItems.map((items: any, index: number) => (
              <div key={index} className="py-2" onClick={onClick}>
                {items.map((item) => (
                  <li
                    className={classNames(
                      item.className ? item.className : '',
                      'block px-4 py-3 w-full text-left text-gray-700 hover:text-gray-900 whitespace-nowrap text-lg hover:bg-gray-100 cursor-pointer',
                    )}
                    key={item.label}
                    role="menuitem"
                    onClick={item.value}
                  >
                    {item.label}
                  </li>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
