import LoadingButton from 'components/Common/LoadingButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ImBook } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../actions/user';
import { AppDispatch } from '../../store';
import Card from '../Common/Card';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';

interface RootState {
  auth: any;
}

type Props = {
  history: any;
};

const StoryBio = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [bio, setBio] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [charCount, setCharCount] = React.useState(0);
  const [randomQuestions, setRandomQuestions] = useState([]);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (currentUser.profile.bio) {
      onBioChange(currentUser.profile.bio);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser.profile.type === 'CAREGIVER') {
      setRandomQuestions(getRandom(caregiverPrompts, 2));
    } else {
      setRandomQuestions(getRandom(carereceiverPrompts, 2));
    }
  }, []);

  const submitBio = async () => {
    setIsLoading(true);
    setGenericError('');

    try {
      await dispatch(updateProfile({ bio }));
      history.push('/onboarding');
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onBioChange = (value) => {
    setBio(value);
    setCharCount(value.length);
  };

  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len) throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  const caregiverPrompts = [
    'What are some things you enjoy doing in the community?',
    'Do you already work outside of the home? If so, how many hours.',
    'How many hours of support are you looking to provide as a caregiver?',
    'Do you have any family members or pets that will be living with you?',
    'What are some of your strengths?',
    'What things are you passionate about?',
    'How do you deal with stressful situations?',
    'Are you talkative or quiet? Are you funny or serious?',
    'Do you have any personal problems with any religion, ethnicity, sexual orientation or gender identity?',
  ];

  const carereceiverPrompts = [
    'How would you like to engage more in the community and/or social activities?',
    'How do you feel about cooking/cleaning? Would you like support in these areas?',
    'What types of things excite you or bring you joy?',
    'What types of things upset or frustrate you?',
    "What's your dream for the future?",
  ];

  return (
    <OnboardingLayout
      title={
        <>
          Tell us about yourself <ImBook size={34} className="inline mb-1 ml-3 text-white" />
        </>
      }
    >
      <>
        <Card expanded={false}>
          <div className="space-y-4">
            <div className="text-xl font-semibold">Some questions to get you started:</div>
            <div className="w-full text-base">
              <ul className="ml-8 list-disc">
                <li>What makes you the ideal roommate?</li>
                <li>What are your favorite hobbies?</li>
                <li>Are you a morning person or a night owl?</li>
                {randomQuestions.map((p) => (
                  <li key={p}>{p}</li>
                ))}
              </ul>
            </div>
          </div>
          <textarea
            rows={10}
            onChange={(e) => onBioChange(e.target.value)}
            name="freeformBio"
            className="block w-full px-3 py-2 mt-5 placeholder-gray-400 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
            required
            value={bio}
            maxLength={5000}
            data-hj-allow
          ></textarea>
          <span className="float-right text-gray-500">{charCount}/5000</span>

          {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
          <LoadingButton
            isLoading={isLoading}
            disabled={isEmpty(bio) || isLoading}
            onClick={submitBio}
            className="w-full mt-6 text-lg button button-primary"
          >
            Save and continue
          </LoadingButton>
        </Card>
      </>
    </OnboardingLayout>
  );
};

export default StoryBio;
