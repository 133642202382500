import newConnectionModal from './NewConnectionModal';
import newWaveModal from './NewWaveModal';
import notificationSummaryModal from './NotificationSummaryModal';
import waveMessageModal from './WaveMessageModal';
import ignoreWaveModal from './IgnoreWaveModal';

const modalTypes = {
  waveMessageModal,
  ignoreWaveModal,
  newWaveModal,
  newConnectionModal,
  notificationSummaryModal,
};

export default modalTypes;
