import FormField from 'components/Common/FormField';
import LoadingButton from 'components/Common/LoadingButton';
import { arraysEqual } from 'helpers/utils';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ChoiceType, Pet, PetsPreference } from 'types';
import { myPets, roommatePets as roommatePet } from '../../actions/onboarding';
import { AppDispatch } from '../../store';
import PillsInput from '../Common/PillsInput';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';

type Props = {
  history: any;
};

const OPTIONS = [
  { label: 'Yes', value: ChoiceType.YES },
  { label: 'No', value: ChoiceType.NO },
  { label: 'No, but I want one', value: ChoiceType.WANTS },
];

const PET_OPTIONS = [
  { label: 'Cat', value: Pet.CAT },
  { label: 'Dog', value: Pet.DOG },
  { label: 'Other', value: Pet.OTHER },
];

const petOptions = [
  { label: 'No preference', value: PetsPreference.NO_PREFERENCE },
  { label: 'No pets', value: PetsPreference.NONE },
  { label: 'No dogs', value: PetsPreference.DOG },
  { label: 'No cats', value: PetsPreference.CAT },
];

const StoryPets = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [petBoolean, setPetBoolean] = useState<ChoiceType[]>([]);
  const [petTypes, setPetTypes] = useState<Pet[]>([]);
  const [petsOther, setPetsOther] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<PetsPreference[]>([]);
  const [genericError, setGenericError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (currentUser.profile?.pets) {
      resetDefault(currentUser.profile?.pets);
    }
  }, [currentUser]);

  useEffect(() => {
    const options: any[] = petOptions.map((o) => o.value);
    const selected = options.filter((g) => currentUser?.profile?.preferences?.pets?.includes(g));

    setSelectedOption(selected);
  }, [currentUser]);

  const resetDefault = (pets) => {
    if (
      pets &&
      pets.length &&
      !arraysEqual(pets, [Pet.NONE]) &&
      !arraysEqual(pets, [ChoiceType.WANTS])
    ) {
      setPetBoolean([ChoiceType.YES]);
      const petTypes = pets?.filter(
        (pet) => pet === Pet.DOG || pet === Pet.CAT || pet === Pet.OTHER,
      );
      const otherPets = pets?.find(
        (pet) => pet !== Pet.DOG && pet !== Pet.CAT && pet !== Pet.OTHER && pet !== Pet.NONE,
      );
      if (otherPets) {
        setPetsOther(otherPets);
        petTypes.push(Pet.OTHER);
      }
      setPetTypes(petTypes);
    } else if (arraysEqual(pets, [ChoiceType.WANTS])) {
      setPetBoolean([ChoiceType.WANTS]);
    } else if (arraysEqual(pets, [Pet.NONE])) {
      setPetBoolean([ChoiceType.NO]);
    }
  };

  const onChangePetBoolean = (option: string[]) => {
    setPetBoolean(option as ChoiceType[]);
  };

  const onChangeMyPet = (options: string[]) => {
    setGenericError('');
    setPetTypes(options as Pet[]);
  };

  const onChangePetsOther = (petsOther: string) => {
    setPetsOther(petsOther);
  };

  const onChangeRoommatePet = (options: string[]) => {
    setSelectedOption(options as PetsPreference[]);
  };

  const submitPets = async () => {
    let pets: string[] = [];

    setIsLoading(true);

    switch (petBoolean[0]) {
      case ChoiceType.YES:
        pets = petTypes;
        if (!pets.length) {
          setGenericError('Please select any animals you currently own');
          return;
        }
        if (pets.includes(Pet.OTHER) && petsOther) {
          // remove OTHER
          const otherIndex = pets.indexOf(Pet.OTHER);
          pets.splice(otherIndex, 1);

          pets.push(petsOther);
        }
        break;
      case ChoiceType.NO:
        pets = [Pet.NONE];
        break;
      case ChoiceType.WANTS:
        pets = [ChoiceType.WANTS];
        break;
      default:
        pets = [];
    }
    setGenericError('');

    try {
      await dispatch(myPets(pets));
      await dispatch(roommatePet(selectedOption));
      history.push('/onboarding');
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <OnboardingLayout title="Do you have any pets?">
        <PillsInput
          onChange={onChangePetBoolean}
          values={petBoolean}
          options={OPTIONS}
          selectOne={true}
        />
        {petBoolean.includes(ChoiceType.YES) && (
          <>
            <p className="text-white"> Which pets do you currently have?</p>
            <PillsInput
              onChange={onChangeMyPet}
              values={petTypes}
              options={PET_OPTIONS}
              selectOne={false}
            />
            {petTypes.includes(Pet.OTHER) && (
              <FormField
                type="text"
                name="other"
                value={petsOther}
                placeholder="Please specify animals you live with"
                className="mb-6 max-w-md"
                onChange={(e) => onChangePetsOther(e.target.value)}
                data-hj-allow
              />
            )}
          </>
        )}

        <p className="mt-10 text-white text-3xl font-bold sm:mb-6 sm:text-4xl">
          Are you willing to live with a roommate that has pets?
        </p>
        <PillsInput
          onChange={onChangeRoommatePet}
          values={selectedOption}
          options={petOptions}
          selectOne={true}
        />

        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <LoadingButton
          disabled={
            isEmpty(petBoolean) ||
            (petBoolean[0] === ChoiceType.YES && isEmpty(petTypes)) ||
            isEmpty(selectedOption) ||
            isLoading
          }
          isLoading={isLoading}
          onClick={submitPets}
          className="hidden mt-6 w-full text-lg md:flex"
          variant="primary-white"
        >
          Save and continue
        </LoadingButton>
      </OnboardingLayout>
      <div className="fixed bottom-0 px-2 w-full bg-white md:hidden">
        <LoadingButton
          disabled={
            isEmpty(petBoolean) ||
            (petBoolean[0] === ChoiceType.YES && isEmpty(petTypes)) ||
            isEmpty(selectedOption) ||
            isLoading
          }
          isLoading={isLoading}
          onClick={submitPets}
          className="w-full text-lg"
          variant="primary"
        >
          Save and continue
        </LoadingButton>
      </div>
    </>
  );
};

export default StoryPets;
