import { deleteMessage } from 'actions/admin';
import classNames from 'classnames';
import { history } from 'helpers/history';
import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

type SingleMessagePropTypes = {
  message: any;
  canDelete?: boolean;
  user: any;
  isOwn?: boolean;
  emoji?: boolean;
};

const SingleMessage = ({ message, canDelete, user, isOwn, emoji }: SingleMessagePropTypes) => {
  const dispatch: any = useDispatch();

  const classes = classNames('px-4 py-3  max-w-3/4', {
    'mr-2  bg-messageOwn rounded-bl-3xl text-white rounded-tl-3xl rounded-tr-2xl flex-reverse':
      isOwn,
    'ml-2  bg-gray-200 rounded-br-3xl text-black rounded-tr-3xl rounded-tl-2xl opacity-90': !isOwn,
    'text-4xl': emoji,
  });

  const _deleteMessage = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmDelete = confirm('Are you sure you want to delete this?');
    if (confirmDelete) {
      dispatch(deleteMessage(message.id))
        .then(() => {
          alert('Message was deleted.');
        })
        .catch((e) => {
          console.log(e);
          alert('There was an error deleting that message');
        });
    }
  };

  return (
    <div className={`flex ${isOwn ? 'flex-row-reverse' : 'flex-row'} justify-start group mb-4`}>
      <div className="flex flex-wrap justify-center select-none">
        <div
          data-tip="React-tooltip"
          data-for={`react-tooltip-${message.id}`}
          className={classNames(
            'flex items-center w-12 h-12 bg-white border rounded-full shadow overflow-hidden',
            !isOwn && 'cursor-pointer',
          )}
          onClick={() => !isOwn && history.push(`/profile/${user?.slug}`)}
        >
          <img
            className="w-full"
            src={user?.image || require('../../assets/default_avatar.png').default}
            alt={user?.firstName + ' profile photo'}
          />
        </div>
        <ReactTooltip id={`react-tooltip-${message.id}`} place="top" type="dark" effect="solid">
          {isOwn ? 'Me' : user?.firstName}
        </ReactTooltip>
      </div>
      <div className={classes} title={new Date(message.timestamp).toLocaleString()}>
        {message.content}
      </div>
      {canDelete && (
        <div className="flex flex-wrap items-center mx-2 opacity-0 select-none group-hover:opacity-100">
          <button onClick={_deleteMessage} className="text-xs text-red-500 hover:underline">
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleMessage;
