import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef } from 'react';
import ReactConfetti from 'react-confetti';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import modalTypes from './Modals';

const mapStateToProps = (state: RootState) => ({
  ...state.modals,
});

const MODAL_TYPES = {
  waveMessage: modalTypes.waveMessageModal,
  ignoreWave: modalTypes.ignoreWaveModal,
  newWave: modalTypes.newWaveModal,
  newConnection: modalTypes.newConnectionModal,
  notificationSummary: modalTypes.notificationSummaryModal,
};

type ModalState = {
  isOpen?: boolean;
  modalType?: string;
  details?: {};
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  closeLabel?: string;
};

const ModalWrapper = ({
  isOpen,
  modalType = '',
  details,
  onConfirm = () => {},
  onCancel = () => {},
  confirmLabel = 'Confirm',
  closeLabel = 'Cancel',
}: ModalState) => {
  useEffect(() => {}, []);

  const cancelButtonRef = useRef(null);

  const hasConfetti = ['newConnection', 'newWave', 'notificationSummary'].includes(modalType);

  const SpecifiedModal = MODAL_TYPES[modalType];

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          open={isOpen}
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <div className="items-end justify-center block min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle " aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full px-6 pt-6 pb-4 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:p-6 sm:max-w-sm">
                {SpecifiedModal && (
                  <SpecifiedModal
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    details={details}
                    confirmLabel={confirmLabel}
                    cancelLabel={closeLabel}
                    cancelButtonRef={cancelButtonRef}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {hasConfetti && isOpen && <ReactConfetti />}
    </>
  );
};

export default connect(mapStateToProps, null)(ModalWrapper);
