import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const InternalServerError = () => {
  return (
    <section className="h-screen-mobile flex items-center justify-center px-4">
      <div className="max-w-auto mx-auto md:max-w-lg">
        <img
          className="mb-8"
          width={200}
          src={require('../../assets/rumi.png').default}
          alt="Rumi logo"
        />
        <h2 className="font-heading mb-2 text-black text-5xl">
          Whoops. There's an issue on our end.
        </h2>
        <p className="mb-3 text-gray-500">
          Our team has already been notified of the issue and are working our best to fix it as soon
          as possible. Please try again in a few minutes.
        </p>
        <p className="mb-6 text-gray-500">For more information, please call 651-695-5805.</p>
        <div>
          <a
            className="inline-flex items-center justify-center hover:underline font-medium"
            href="http://meetmyrumi.com"
          >
            <FaArrowRight className="mr-2" />
            Go home
          </a>
        </div>
      </div>
    </section>
  );
};
export default InternalServerError;
