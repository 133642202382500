import { Dialog } from '@headlessui/react';
import React from 'react';

const NewConnectionModal = ({ onConfirm, onCancel, cancelButtonRef, details }) => {
  return (
    <>
      <div>
        <div className="flex flex-wrap justify-center">
          <div className="flex items-center w-20 h-20 overflow-hidden bg-white border rounded-full shadow">
            <img
              className="w-full"
              src={details.image || require('../../assets/default_avatar.png').default}
              alt={details.name + ' profile photo'}
            />
          </div>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            You Connected With {details.name || details.firstName}!
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-gray-500">
              Send a message to get to know your new potential roommate!
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:grid sm:gap-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:mt-6">
        <button
          type="button"
          className="inline-flex justify-center w-full button button-primary sm:col-start-2 sm:text-sm"
          onClick={() => onConfirm()}
        >
          Send Message
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full button button-secondary sm:col-start-1 sm:text-sm"
          onClick={() => onCancel()}
          ref={cancelButtonRef}
        >
          View Later
        </button>
      </div>
    </>
  );
};

export default NewConnectionModal;
