import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

const Spinner = ({ size = 40, children }: any) => {
  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col items-center m-auto">
        <AiOutlineLoading className="mb-2 text-blue-500 animate-spin" size={size} />
        {children}
      </div>
    </div>
  );
};

export default Spinner;
