import { setSearchFilters } from 'actions/user';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import AccountPausedOverlay from 'components/Common/AccountPausedOverlay';
import MobileLogo from 'components/Common/MobileLogo';
import Spinner from 'components/Common/Spinner';
import { history } from 'helpers/history';
import { resizeEvent } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiOutlineLoading } from 'react-icons/ai';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AppDispatch } from 'store';
import {
  GenderSearchPreferences,
  PetSearchPreferences,
  ProfileStatus,
  WheelchairSearchPreferences,
} from 'types';
import userService from '../../services/user.service';
import Header from '../Common/Header';
import HelpButton from '../Common/HelpButton';
import SearchCard from './SearchCard';
import SearchFilters, { FilterValues } from './SearchFilters';

let timeout;
const Search = () => {
  const dispatch: AppDispatch = useDispatch();
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const { searchFilters } = useSelector((state: RootState) => state.users);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(true);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchUsers = async (filters: Partial<FilterValues>, _page = 1) => {
    if (currentUser.profile.status === ProfileStatus.PAUSED) {
      setLoadingMore(false);
      setHasMore(false);
      return;
    } else {
      setLoadingMore(true);
      setHasMore(true);
    }

    if (currentUser?.profile?.location?.housing) {
      // if user has a place, set max distance to anywhere since filters are hidden
      filters.maxDistance = [50];
    }

    setPage(_page);

    if (!users?.length) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const limit = 10;
      const response = await userService.getUserList(limit, _page, filters);

      if (!response.data?.length || response.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      const newSuggestedVideos = users.concat(response.data);
      const ids = newSuggestedVideos.map((o: any) => o.id);
      const filteredUsers = newSuggestedVideos.filter(
        ({ id }, index) => !ids.includes(id, index + 1),
      );
      setUsers(filteredUsers);
    } catch (error: any) {
      if (error.statusCode === 403) {
        console.error('You must unpause your account before you can search.');
      } else {
        throw new Error(error);
      }
    } finally {
      setLoadingMore(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setLoading(false);
      }, 400);
      resizeEvent();
    }
  };

  useEffect(() => {
    fetchUsers(searchFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  // history.listen((location) => {
  //   scrollToTop();
  // });

  // useEffect(() => {
  //   if (!loading) {
  //     const scrollState = sessionStorage.getItem('scrollState')!;
  //     scrollToTop(+scrollState, false);
  //   }
  // }, [loading]);

  const scrollToTop = (px: number = 0, animation = true) => {
    const scrollDiv: any = document.querySelector('#searchList');
    if (scrollDiv) {
      if (animation) {
        setTimeout(() => {
          animateScrollTo(px, { elementToScroll: scrollDiv, maxDuration: 800 });
        }, 100);
      } else {
        setTimeout(() => {
          scrollDiv.scrollTop = +px;
        }, 0);
      }
    }
  };

  const onChangeFilters = (data: Partial<FilterValues>) => {
    // if (!isEqual(data, searchFilters)) {
    //   sessionStorage.removeItem('scrollState');
    // }
    dispatch(setSearchFilters(data));

    // Reset pagination
    setPage(1);
    setHasMore(true);
    setUsers([]);
  };

  // const onScrollEvent = (e: any) => {
  //   const scrollTop = e.target.scrollTop;
  //   sessionStorage.setItem('scrollState', scrollTop);
  // };

  const viewProfile = (user: any, e: any) => {
    e.stopPropagation();
    const url = '/profile/' + user.slug;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Helmet>
        <title>Search | Rumi</title>
      </Helmet>
      <div className="relative flex flex-col h-full overflow-hidden search">
        <AccountPausedOverlay />
        <Header className="relative justify-between header header-small">
          <MobileLogo />
          <h2>Search</h2>
          <div className="flex flex-col">
            <HelpButton />
          </div>
        </Header>
        <div className="flex flex-1 overflow-hidden">
          <SearchFilters
            displayFilters={displayFilter}
            disabled={loading}
            onSubmit={onChangeFilters}
            users={users}
            filters={searchFilters}
            location={currentUser?.profile?.location?.city}
            onHideFilters={() => {
              scrollToTop();
              setDisplayFilter(false);
            }}
          />
          <div
            className={classNames(displayFilter && 'hidden lg:flex', 'flex flex-1 overflow-hidden')}
          >
            <div className="flex flex-col w-full">
              <div className="w-full m-0">
                <div className="select-none header">
                  <nav className="flex flex-row items-center bg-white border-b">
                    <button
                      onClick={() => setDisplayFilter(!displayFilter)}
                      className="tab lg:hidden"
                      id="searchPreferencesTab"
                    >
                      Preferences
                    </button>
                    <button
                      onClick={() => scrollToTop()}
                      className="tab active"
                      id="searchResultsTab"
                    >
                      Search Results
                    </button>
                  </nav>
                </div>
              </div>
              {loading ? (
                <Spinner>Finding roommates...</Spinner>
              ) : (
                <div
                  className="flex flex-col items-center flex-1 w-full px-4 py-6 pb-16 space-y-6 overflow-y-auto sm:px-6 lg:pt-0"
                  id="searchList"
                  // onScroll={(e) => onScrollEvent(e)}
                >
                  <div
                    id="searchFiltersMobile"
                    className="flex flex-col justify-center w-full max-w-2xl px-6 py-4 bg-white border border-gray-300 rounded-md lg:hidden"
                  >
                    <h2 className="text-center">Search Preferences</h2>{' '}
                    {searchFilters &&
                      Object.keys(searchFilters).map((keyName, i) => {
                        switch (keyName) {
                          case 'pets':
                            return (
                              <p key={keyName}>
                                <b>Pets</b>: {PetSearchPreferences[searchFilters[keyName]]}
                              </p>
                            );
                          case 'gender':
                            return (
                              <p key={keyName}>
                                <b>Gender</b>: {GenderSearchPreferences[searchFilters[keyName]]}
                              </p>
                            );
                          case 'ageRange':
                            return (
                              <p key={keyName}>
                                <b>Age Range</b>: {searchFilters[keyName][0]}-
                                {searchFilters[keyName][1]} years old
                              </p>
                            );
                          case 'maxDistance':
                            return (
                              <p key={keyName}>
                                <b>Location</b>:{' '}
                                {searchFilters[keyName][0] === 50 ? (
                                  <>Anywhere</>
                                ) : (
                                  <>
                                    within {searchFilters[keyName][0]} miles of{' '}
                                    {currentUser?.profile?.location?.city}
                                  </>
                                )}
                              </p>
                            );
                          case 'wheelchair':
                            return (
                              <p key={keyName}>
                                <b>Wheelchair</b>:{' '}
                                {WheelchairSearchPreferences[searchFilters[keyName]]}
                              </p>
                            );
                          default:
                            return <></>;
                        }
                      })}
                    <button
                      className="w-full button button-secondary"
                      onClick={() => setDisplayFilter(!displayFilter)}
                    >
                      Change preferences
                    </button>
                  </div>
                  <InfiniteScroll
                    threshold={0}
                    // Indexed from 0, but pagination starts at 1
                    pageStart={page - 1}
                    loadMore={() => {
                      if (!loadingMore && hasMore) {
                        fetchUsers(searchFilters, page + 1);
                      }
                    }}
                    initialLoad={false}
                    hasMore={hasMore}
                    loader={
                      <div className="flex flex-col items-center m-auto">
                        <AiOutlineLoading className="text-blue-500 animate-spin" size={40} />
                      </div>
                    }
                    useWindow={false}
                  >
                    {users.map((user: any, i: number) => (
                      <div
                        id={i === 0 ? 'firstSearchCard' : undefined}
                        key={user.slug}
                        className="block w-full max-w-2xl p-6 my-6 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                        onClick={(e) => viewProfile(user, e)}
                        role="button"
                      >
                        <SearchCard user={user} />
                      </div>
                    ))}
                    {!hasMore && (
                      <div className="max-w-2xl p-8 text-center text-gray-400">
                        <p>
                          You reached the end. <br />
                          Try changing filters to find more people.
                        </p>
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
