import FormField from 'components/Common/FormField';
import Modal from 'components/Common/Modal';
import Spinner from 'components/Common/Spinner';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import { history } from 'helpers/history';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import authService from 'services/auth.service';
import { authMe, login, resetPassword } from '../../actions/auth';
import { AppDispatch } from '../../store';

type ResetPasswordForm = {
  password: string;
  verifyPassword: string;
};

const ResetPassword = () => {
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [user, setUser] = useState(null as any);

  const search = useLocation().search;
  const token: string | null = new URLSearchParams(search).get('token');
  const dispatch: AppDispatch = useDispatch();

  if (!token) {
    history.replace('/login');
  }

  useEffect(() => {
    authService
      .checkResetPasswordToken(token)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        setTokenError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const {
    register: resetPasswordRegister,
    handleSubmit: handleSubmitResetPassword,
    formState: { errors: resetPasswordErrors },
    setError: setResetPasswordError,
  } = useForm<ResetPasswordForm>();

  const handleResetPassword = async ({ password, verifyPassword }: ResetPasswordForm) => {
    if (password !== verifyPassword) {
      setResetPasswordError('verifyPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      });
      return;
    }

    setSuccessful(false);
    setLoading(true);

    dispatch(resetPassword(password, token))
      .then(async (res) => {
        setSuccessful(true);
        await dispatch(login(res?.email, password));
        await dispatch(authMe());
        history.replace('/login');
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          setTokenError(true);
        } else {
          setSuccessful(false);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 z-10 bg-black"></div>
      <Modal
        show={true}
        hideAction={() => false}
        title={'Reset password'}
        onSecondary={() => history.replace('/login')}
        cancelLabel={'Cancel'}
        confirmLabel={'Save and Login'}
        onConfirm={() => handleSubmitResetPassword(handleResetPassword)()}
        loading={loading}
        icon={(className) => <RiLockPasswordLine className={className} />}
      >
        {!loading ? (
          <>
            {tokenError ? (
              <>
                <StatusMessage
                  type={StatusType.ERROR}
                  message={
                    'This password reset link expired. Please try submitting another request.'
                  }
                />
                <button
                  className="mx-auto text-sm button button-primary"
                  onClick={() => history.replace('/forgot-password')}
                >
                  Forgot Password
                </button>
              </>
            ) : (
              <>
                {successful ? (
                  <div className="space-y-2">
                    <StatusMessage
                      type={StatusType.SUCCESS}
                      message={'Your password was reset successfully!'}
                    />
                    <p>Redirecting you...</p>
                  </div>
                ) : (
                  <>
                    <div className="w-full">
                      <form
                        noValidate
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmitResetPassword(handleResetPassword)();
                        }}
                        className="space-y-4"
                      >
                        <p className="text-gray-600">
                          Hi {user?.name}, reset your password using the form below.
                        </p>
                        <div>
                          <FormField
                            type="password"
                            label="New password"
                            {...resetPasswordRegister('password', {
                              required: 'Please enter a new password',
                              minLength: {
                                value: 6,
                                message: 'Please enter at least 6 characters',
                              },
                            })}
                            disabled={loading}
                            errors={resetPasswordErrors}
                          />
                        </div>
                        <div>
                          <FormField
                            type="password"
                            {...resetPasswordRegister('verifyPassword', {
                              required: 'Please confirm your new password',
                              minLength: {
                                value: 6,
                                message: 'Please enter at least 6 characters',
                              },
                            })}
                            label="Verify password"
                            disabled={loading}
                            errors={resetPasswordErrors}
                          />
                        </div>
                        <button type="submit"></button>
                      </form>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className="h-full">
            <Spinner />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ResetPassword;
