import { Loader } from '@googlemaps/js-api-loader';
import classNames from 'classnames';
import Admin from 'components/Admin/Admin';
import Logout from 'components/Authentication/Logout';
import PasswordlessConfirmation from 'components/Authentication/PasswordlessConfirmation';
import AccessibleNavigationAnnouncer from 'components/Common/AccessibleNavigationAnnouncer';
import InProcessMode from 'components/InProcessMode';
import MoveInMode from 'components/MoveInMode';
import Walkthrough from 'components/Walkthrough';
import { isMobileOrTablet } from 'helpers/utils';
import rg4js from 'raygun4js';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { isProduction } from 'selectors';
import { authMe, logout } from './actions/auth';
import './App.scss';
import ChangeEmail from './components/Authentication/ChangeEmail';
import ChangeEmailVerify from './components/Authentication/ChangeEmailVerify';
import ForgotPassword from './components/Authentication/ForgotPassword';
import Login from './components/Authentication/Login';
import ResetPassword from './components/Authentication/ResetPassword';
import Signup from './components/Authentication/Signup';
import InternalServerError from './components/Common/InternalServerError';
import Loading from './components/Common/Loading';
import Connections from './components/Connections/Connections';
import Favorites from './components/Favorites/Favorites';
import Home from './components/Home/Home';
import ModalWrapper from './components/ModalWrapper';
import UserNavigation from './components/Navigation/UserNavigation';
import Onboarding from './components/Onboarding';
import Profile from './components/Profile/Profile';
import Search from './components/Search/Search';
import Settings from './components/Settings/Settings';
import { history } from './helpers/history';
import { ROLE } from './models/roles';
import { RootState } from './reducers';
import store, { AppDispatch } from './store';
import { ProfileStatus } from './types/index';

rg4js('enableCrashReporting', true);
rg4js('apiKey', process.env.REACT_APP_RAYGUN_API);
rg4js('enablePulse', true);
rg4js('withCustomData', () => {
  const state = store.getState();
  const authState = state.auth;
  return { authState };
});

// this is necessary for maintaining iOS full screen size height
const setScreenSize = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const mapDispatchToProps = (dispatch) => ({});
const connector = connect(undefined, mapDispatchToProps);

const App = () => {
  const { user: currentUser, isLoggedIn } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(
    !isProduction() || !!window.google?.maps?.Map,
  );

  useEffect((): any => {
    let mounted = true;
    const loadGoogleMaps = async () => {
      if (!window.google?.maps?.Map) {
        if (!process.env.REACT_APP_GOOGLE_MAPS) {
          throw Error('No Google Maps API key');
        }
        const loader = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS,
          version: 'weekly',
          libraries: ['places'],
        });
        await loader.load();
        setGoogleMapsLoaded(true);
      }
    };
    loadGoogleMaps();
    if (currentUser && mounted) {
      dispatch(authMe()).catch((err) => {
        if (err === 'Unauthorized') {
          dispatch(logout());
        } else if (err === 'Request failed with status code 429') {
          setIsError(true);
        } else {
          setIsError(true);
        }
      });
      let timeout;
      window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(setScreenSize, 200);
      });
      setScreenSize();
    }

    history.listen((location) => {
      if (mounted) {
        toast.remove();
        rg4js('trackEvent', { type: 'pageView', path: location.pathname });
        setTimeout(() => {
          setScreenSize();
        }, 200);
      }
    });
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const isUser = currentUser?.roles?.includes(ROLE.USER);
  const isAdmin = currentUser?.roles?.includes(ROLE.ADMIN);
  const isLoading = (currentUser && !isLoggedIn) || !googleMapsLoaded;
  const userActive =
    currentUser?.profile?.status === ProfileStatus.ACTIVE ||
    currentUser?.profile?.status === ProfileStatus.PAUSED;
  const userOnboarding =
    currentUser?.profile?.status === ProfileStatus.ONBOARDING ||
    currentUser?.profile?.status === ProfileStatus.PENDING ||
    currentUser?.profile?.status === ProfileStatus.MIGRATING;
  const userMoveIn = currentUser?.profile?.status === ProfileStatus.MOVED_IN;
  const userInProcess = currentUser?.profile?.status === ProfileStatus.IN_PROCESS;
  const userDenied = currentUser?.profile?.status === ProfileStatus.DENIED;
  const isMobile = isMobileOrTablet();
  return (
    <>
      {userActive && <ModalWrapper />}

      <Toaster
        position={isMobile ? 'top-center' : 'top-right'}
        toastOptions={{
          duration: 3000,
          className: classNames('p-4 hover:bg-gray-50 border select-none'),
        }}
      />
      {isLoading && <Loading />}
      {isError && <InternalServerError />}
      <Router history={history}>
        <AccessibleNavigationAnnouncer />

        {!isLoading && (
          <>
            <Switch>
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/account/email" component={ChangeEmail} />
              <Route exact path="/account/email/verify" component={ChangeEmailVerify} />
            </Switch>

            {isLoggedIn && isUser && (
              <>
                <main
                  className={`flex items-start ${
                    userActive ? 'h-screen-mobile pb-20 lg:pb-0' : 'min-h-screen'
                  }`}
                >
                  {userActive && <Walkthrough walkthrough={!currentUser?.profile?.walkthrough} />}
                  {userActive && <UserNavigation />}
                  <div className="w-full h-full content-background md:space-y-4">
                    {userOnboarding && (
                      <Switch>
                        <Route path="/onboarding" component={Onboarding} />
                        <Redirect to="/onboarding?r=app" />
                      </Switch>
                    )}
                    {userInProcess && (
                      <Switch>
                        <Route path="/in-process" component={InProcessMode} />
                        <Redirect to="/in-process?r=app" />
                      </Switch>
                    )}
                    {userMoveIn && (
                      <Switch>
                        <Route path="/move-in" component={MoveInMode} />
                        <Redirect to="/move-in?r=app" />
                      </Switch>
                    )}
                    {userActive && (
                      <Switch>
                        <Route exact path="/search" component={Search} />
                        <Route exact path="/favorites" component={Favorites} />
                        <Route exact path="/profile" component={Profile} />
                        <Route
                          exact
                          path="/profile/abcdefghijklmnopqrstuvwxyz"
                          render={() => <Profile isTour={true} />}
                        />
                        <Route exact path="/profile/:slug" component={Profile} />
                        <Route exact path="/connections/messages/:slug" component={Connections} />
                        <Route exact path="/connections/messages" component={Connections} />
                        <Route exact path="/connections/requests" component={Connections} />
                        <Route exact path="/connections" component={Connections} />
                        <Route path="/settings" component={Settings} />
                        <Redirect to="/connections/messages?r=app" />
                      </Switch>
                    )}
                    {userDenied && <Redirect to="/logout?r=401" />}
                  </div>
                </main>
              </>
            )}

            {isLoggedIn && isAdmin && <Admin />}

            {!isLoggedIn && (
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/magic-link" component={PasswordlessConfirmation} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/signup/:type?" component={Signup} />
                <Route exact path="/login" component={Login} />
                <Redirect to="/login?r=app" />
              </Switch>
            )}
          </>
        )}
        {/* Admin routes */}
      </Router>
    </>
  );
};

export default connector(App);
