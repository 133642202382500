import OnboardingLayout from 'components/Layout/OnboardingLayout';
import { history } from 'helpers/history';
import React from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useLocation } from 'react-router';

enum EmailType {
  'gmail.com' = 'GMAIL',
  'yahoo.com' = 'YAHOO',
  'ymail.com' = 'YMAIL',
  'aol.com' = 'AOL',
  'hotmail.com' = 'HOTMAIL',
  'outlook.com' = 'OUTLOOK',
  'comcast.net' = 'COMCAST',
  'msn.com' = 'MSN',
}

const PasswordlessConfirmation = () => {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email')?.toLowerCase();

  if (!email) {
    history.replace('/login');
  }

  const emailString = email?.substring(email.indexOf('@') + 1) || '';
  const emailType = EmailType[emailString];

  return (
    <>
      <OnboardingLayout
        childrenPadding="0"
        title="Please check your email inbox"
        subtitle={
          <>
            Please check your email (<b className="underline">{email}</b>) for a link to login to
            your account!
          </>
        }
      >
        {(() => {
          switch (emailType) {
            case 'GMAIL':
              return (
                <a
                  href="https://gmail.com"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open Gmail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'YAHOO':
              return (
                <a
                  href="https://login.yahoo.com/?.src=ym&pspid=1197806870&activity=header-signin&.lang=en-US&.intl=us&.done=https%3A%2F%2Fmail.yahoo.com%2Fd"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open Yahoo Mail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'YMAIL':
              return (
                <a
                  href="https://login.yahoo.com/?.src=ym&pspid=1197806870&activity=header-signin&.lang=en-US&.intl=us&.done=https%3A%2F%2Fmail.yahoo.com%2Fd%3Fpspid%3D2023538075%26activity%3Dybar-mail"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open YMail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'AOL':
              return (
                <a
                  href="https://mail.aol.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open AOL Mail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'HOTMAIL':
              return (
                <a
                  href="https://outlook.live.com/owa/?nlp=1"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open Hotmail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'OUTLOOK':
              return (
                <a
                  href="https://outlook.live.com/owa/?nlp=1"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open Outlook Mail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'MSN':
              return (
                <a
                  href="https://outlook.live.com/owa/?nlp=1"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open MSN Mail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
            case 'COMCAST':
              return (
                <a
                  href="https://xfinityconnect.email.comcast.net/"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-primary-white w-full"
                >
                  Open Comcast Mail <HiExternalLink className="inline mb-0.5 ml-1" />
                </a>
              );
          }
        })()}

        <button
          onClick={() => history.replace('/login')}
          className="button button-secondary-white w-full"
        >
          <IoArrowBackOutline className="mr-2" /> Back to login screen
        </button>
        <div className="flex flex-col">
          <div className="bottom-0 right-0 mt-8 opacity-50 sm:absolute sm:w-1/2">
            <img src={require('../../assets/story.svg').default} alt="" />
          </div>
        </div>
      </OnboardingLayout>
    </>
  );
};

export default PasswordlessConfirmation;
