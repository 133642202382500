import { getMyFavorites } from 'actions/user';
import AccountPausedOverlay from 'components/Common/AccountPausedOverlay';
import MobileLogo from 'components/Common/MobileLogo';
import Spinner from 'components/Common/Spinner';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFavorites } from 'selectors';
import { AppDispatch } from 'store';
import Header from '../Common/Header';
import HelpButton from '../Common/HelpButton';
import FavoriteCard from './FavoriteCard';

const NoFavoritesYet = () => {
  return (
    <div className="flex items-center justify-center w-full h-full text-gray-400 select-none">
      <div className="p-4 text-center md:w-8/12">
        <p className="mb-4">
          Add someone as a favorite by pressing the <br />
          <b>Favorite</b>{' '}
          <FaStar size={24} className="inline-flex align-bottom text-yellow-400 animate-wiggle" />{' '}
          button on their profile.
        </p>
        <Link to="/search" className="button button-primary">
          Start searching
        </Link>
      </div>
    </div>
  );
};

const Favorites = () => {
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const favorites = useSelector(getFavorites);

  useEffect(() => {
    setLoading(true);
    dispatch(getMyFavorites())
      .catch(() => {
        toast.error('There was an issue loading favorites');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Favorites | Rumi</title>
      </Helmet>
      <div className="relative flex flex-col h-full">
        <AccountPausedOverlay />
        <Header className="header header-small relative justify-between">
          <MobileLogo />
          <h2>Favorites</h2>
          <div className="flex flex-col">
            <HelpButton />
          </div>
        </Header>
        <div className="flex flex-1 flex-row overflow-hidden">
          {loading ? (
            <Spinner />
          ) : favorites.length ? (
            <div className="w-full overflow-y-auto">
              <div className="grid gap-3 grid-cols-2 items-center justify-center mx-auto p-3 max-w-2xl sm:gap-6 sm:p-6 md:grid-cols-2 lg:grid-cols-3 lg:max-w-5xl">
                {favorites.map((favorite: any) => (
                  <FavoriteCard key={favorite.slug} user={favorite} />
                ))}
              </div>
            </div>
          ) : (
            <NoFavoritesYet />
          )}
        </div>
      </div>
    </>
  );
};

export default Favorites;
