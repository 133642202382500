import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
  UsersIcon,
  NoSymbolIcon,
  LinkIcon,
  BellIcon,
  ChatBubbleOvalLeftIcon,
  XMarkIcon,
  Bars3Icon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { history } from 'helpers/history';
import { ROLE } from 'models/roles';
import React, { Fragment, useState } from 'react';
import { BiNotification } from 'react-icons/bi';
import { FiUserCheck, FiUserX } from 'react-icons/fi';
import { IoAnalytics } from 'react-icons/io5';
import { RiDashboard2Line, RiPieChartLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';

const userNavigation = [
  { name: 'Your Profile', href: '/admin/settings' },
  { name: 'Settings', href: '/admin/settings' },
  { name: 'Sign out', href: '/logout' },
];

const navigation: any = [
  {
    name: 'Dashboard',
    href: '/admin/dashboard',
    icon: RiDashboard2Line,
  },
  {
    name: 'Users',
    href: '/admin/users',
    icon: UsersIcon,
    children: [
      {
        name: 'All Users',
        href: '/admin/users',
        icon: FiUserCheck,
      },
      {
        name: 'Denied Users',
        href: '/admin/users/denied',
        icon: FiUserX,
      },
      {
        name: 'Blocked Users',
        href: '/admin/users/blocked',
        icon: NoSymbolIcon,
      },
    ],
  },
  {
    name: 'Connections',
    href: '/admin/connections',
    icon: LinkIcon,
  },
  {
    name: 'Analytics',
    href: '/admin/analytics',
    icon: IoAnalytics,
  },
  {
    name: 'Reports',
    href: '/admin/reports',
    icon: RiPieChartLine,
  },
  {
    name: 'Marketing',
    href: '/admin/marketing',
    icon: BellIcon,
    role: ROLE.ADMIN_MARKETING,
    children: [
      {
        name: 'Push Notifications',
        href: '/admin/marketing/push',
        icon: BiNotification,
      },
      {
        name: 'Text Notifications',
        href: '/admin/marketing/text',
        icon: ChatBubbleOvalLeftIcon,
      },
    ],
  },
];

const AdminLayout = ({ children }) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [pathname, setPathname] = useState(history.location.pathname);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  history.listen((location) => {
    setPathname(location.pathname);
  });

  const closeSidebar = () => setTimeout(() => setSidebarOpen(false), 0);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {/* @ts-ignore */}
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-gray-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={closeSidebar}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <Link to="/admin/dashboard">
                      <img
                        className="w-auto h-8"
                        src={require('../../../../assets/rumi-light.svg').default}
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                  <nav className="px-2 mt-5 space-y-1">
                    {navigation.map((item) => {
                      if (!item.role || currentUser.roles?.includes(item.role)) {
                        return !item.children ? (
                          <div key={item.name}>
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? 'bg-gray-800 text-white'
                                  : 'text-white hover:bg-gray-600 hover:bg-opacity-75',
                                'group flex items-center pl-2 py-2 text-sm font-medium rounded-md',
                              )}
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </div>
                        ) : (
                          <Disclosure
                            defaultOpen={pathname.indexOf(item.href) > -1}
                            as="div"
                            key={item.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    'text-white hover:bg-gray-600 hover:bg-opacity-75',
                                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                                  )}
                                >
                                  <item.icon
                                    className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                                    aria-hidden="true"
                                  />
                                  <span className="flex-1">{item.name}</span>
                                  <svg
                                    className={classNames(
                                      open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                      'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150',
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                  >
                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                  </svg>
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                  {item.children.map((subItem) => (
                                    <Link
                                      key={subItem.name}
                                      to={subItem.href}
                                      className={classNames(
                                        subItem.href === pathname
                                          ? 'bg-gray-800 text-white'
                                          : 'text-white hover:bg-gray-600 hover:bg-opacity-75',

                                        'flex items-center w-full py-2 pr-2 text-sm font-medium text-white rounded-md group pl-6',
                                      )}
                                    >
                                      <subItem.icon
                                        className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                                        aria-hidden="true"
                                      />
                                      {subItem.name}
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        );
                      }
                    })}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 p-4 border-t border-gray-800">
                  <Link to="/admin/settings" className="flex-shrink-0 block group">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block w-10 h-10 rounded-full"
                          src={currentUser.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{currentUser.name}</p>
                        <p className="text-sm font-medium text-gray-200 group-hover:text-white">
                          Settings
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* @ts-ignore */}
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden md:flex md:w-56 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-1 min-h-0 bg-gray-700">
          <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link to="/admin/dashboard">
                <img
                  className="w-auto h-8"
                  src={require('../../../../assets/rumi-light.svg').default}
                  alt="Workflow"
                />
              </Link>
            </div>
            <nav className="flex-1 px-2 mt-5 space-y-1">
              {navigation.map((item) => {
                if (!item.role || currentUser.roles.includes(item.role)) {
                  return !item.children ? (
                    <div key={item.name}>
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href === pathname
                            ? 'bg-gray-800 text-white'
                            : 'text-white hover:bg-gray-600 hover:bg-opacity-75',
                          'group flex items-center pl-2 py-2 text-sm font-medium rounded-md',
                        )}
                      >
                        <item.icon
                          className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </div>
                  ) : (
                    <Disclosure
                      defaultOpen={pathname.indexOf(item.href) > -1}
                      as="div"
                      key={item.name}
                      className="space-y-1"
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              'text-white hover:bg-gray-600 hover:bg-opacity-75',
                              'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                            )}
                          >
                            <item.icon
                              className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                              aria-hidden="true"
                            />
                            <span className="flex-1">{item.name}</span>
                            <svg
                              className={classNames(
                                open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150',
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                className={classNames(
                                  subItem.href === pathname
                                    ? 'bg-gray-800 text-white'
                                    : 'text-white hover:bg-gray-600 hover:bg-opacity-75',

                                  'flex items-center w-full py-2 pr-2 text-sm font-medium text-white rounded-md group pl-6',
                                )}
                              >
                                <subItem.icon
                                  className="flex-shrink-0 w-6 h-6 mr-3 text-gray-300"
                                  aria-hidden="true"
                                />
                                {subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  );
                }
              })}
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-4 border-t border-gray-800">
            <Link to="/admin/settings" className="flex-shrink-0 block w-full group">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block rounded-full h-9 w-9"
                    src={currentUser.image}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">{currentUser.name}</p>
                  <p className="text-xs font-medium text-gray-200 group-hover:text-white">
                    Settings
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 max-h-screen overflow-auto md:pl-56">
        <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 md:hidden">
          <button
            type="button"
            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
          <div className="flex justify-between flex-1 px-4">
            <div className="flex flex-1">
              {/* <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="w-5 h-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
            </div>
            <div className="flex items-center ml-4 md:ml-6">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    <span className="sr-only">Open user menu</span>
                    <img className="w-8 h-8 rounded-full" src={currentUser.image} alt="" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-sm text-gray-700',
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default AdminLayout;
