import React from 'react';

type NotificationSummaryDetailsUser = {
  id: string;
  image: string;
  name: string;
};

type NotificationSummaryDetails = {
  messages: NotificationSummaryDetailsUser[];
  waves: NotificationSummaryDetailsUser[];
  connections: NotificationSummaryDetailsUser[];
};

type NotificationSummary = {
  onConfirm: () => {};
  onCancel: () => {};
  cancelButtonRef;
  details: NotificationSummaryDetails;
};

const NotificationSummaryModal = ({
  onConfirm,
  onCancel,
  cancelButtonRef,
  details,
}: NotificationSummary) => {
  const waves = details.waves;
  const connections = details.connections;
  const messages = details.messages;

  return (
    <>
      <div className="font-semibold text-4xl">Activity</div>
      {!!waves.length && (
        <>
          <div className="font-semibold mt-6 mb-3 text-xl">New Wave Requests 👋</div>
          <div className="flex -space-x-2">
            {waves?.map((wave: NotificationSummaryDetailsUser) => (
              <div
                key={wave.id}
                className="flex items-center w-16 h-16 bg-white border rounded-full shadow overflow-hidden"
              >
                <img
                  className="w-full"
                  src={wave.image || require('../../assets/default_avatar.png').default}
                  alt={wave.name + ' profile photo'}
                />
              </div>
            ))}
          </div>
          <div className="mt-3">
            {waves?.length > 1 ? (
              <>
                {waves[0].name} and {waves.length - 1} other{waves.length - 1 > 1 && 's'} waved at
                you!
              </>
            ) : (
              <>{waves[0].name} waved at you!</>
            )}
          </div>
        </>
      )}

      {!!connections.length && (
        <>
          <div className="font-semibold mt-6 mb-3 text-xl">New Connections 🙌</div>
          <div className="flex -space-x-2">
            {connections?.map((connection: NotificationSummaryDetailsUser) => (
              <div
                key={connection.id}
                className="flex items-center w-16 h-16 bg-white border rounded-full shadow overflow-hidden"
              >
                <img
                  className="w-full"
                  src={connection.image || require('../../assets/default_avatar.png').default}
                  alt={connection.name + ' profile photo'}
                />
              </div>
            ))}
          </div>
          <div className="mt-3">
            {connections?.length > 1 ? (
              <>
                {connections[0].name} and {connections.length - 1} other
                {connections.length - 1 > 1 && 's'} connected with you!
              </>
            ) : (
              <>You connected with {connections[0].name}!</>
            )}
          </div>
        </>
      )}

      {!!messages.length && (
        <>
          <div className="font-semibold mt-6 mb-3 text-xl">New Messages 💬</div>
          <div className="flex -space-x-2">
            {messages?.map((message: NotificationSummaryDetailsUser) => (
              <div
                key={message.id}
                className="flex items-center w-16 h-16 bg-white border rounded-full shadow overflow-hidden"
              >
                <img
                  className="w-full"
                  src={message.image || require('../../assets/default_avatar.png').default}
                  alt={message.name + ' profile photo'}
                />
              </div>
            ))}
          </div>
          <div className="mt-3">
            {messages.length > 1 ? (
              <>
                {messages[0].name} and {messages.length - 1} other
                {messages.length - 1 > 1 && 's'} sent you a message!
              </>
            ) : (
              <>{messages[0].name} sent you a message!</>
            )}
          </div>
        </>
      )}

      <div className="mt-5 sm:grid sm:gap-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:mt-6">
        <button
          type="button"
          className="button button-primary inline-flex justify-center w-full sm:col-start-2 sm:text-sm"
          onClick={() => onConfirm()}
        >
          View
        </button>
        <button
          type="button"
          className="button button-secondary inline-flex justify-center w-full sm:col-start-1 sm:text-sm"
          onClick={() => onCancel()}
          ref={cancelButtonRef}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default NotificationSummaryModal;
