import {
  ANALYTIC_CALENDAR,
  ANALYTIC_MESSAGES,
  ANALYTIC_USERS,
  ANALYTIC_WAVES,
} from './../actions/types';

const initialState = {
  waves: [],
  users: [],
  messages: [],
  calendar: [],
};

const analytics = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTIC_USERS: {
      return {
        ...state,
        users: payload,
      };
    }
    case ANALYTIC_MESSAGES: {
      return {
        ...state,
        messages: payload,
      };
    }
    case ANALYTIC_WAVES: {
      return {
        ...state,
        waves: payload,
      };
    }
    case ANALYTIC_CALENDAR: {
      return {
        ...state,
        calendar: payload,
      };
    }
    default:
      return state;
  }
};

export default analytics;
