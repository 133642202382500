import React, { FunctionComponent } from 'react';

type SettingsLayoutProps = {
  title: String;
};

const SettingsLayout: FunctionComponent<SettingsLayoutProps> = ({ title, children }) => {
  return (
    <div className="mx-6 my-10 w-full">
      <div className="flex flex-row justify-between mb-10">
        <p className="text-gray-700 text-3xl font-semibold">{title}</p>
      </div>
      <div className="pb-10">{children}</div>
    </div>
  );
};

export default SettingsLayout;
