import LoadingButton from 'components/Common/LoadingButton';
import React from 'react';

type ModalLayoutProps = {
  children: React.ReactNode;
  icon?;
  title: string;
  onConfirm?: () => void;
  onSecondary?: () => void;
  cancelLabel?: string;
  loading: boolean;
  confirmLabel: string;
};

const ModalLayout: React.FC<ModalLayoutProps> = ({
  children,
  icon,
  title,
  onConfirm,
  onSecondary,
  cancelLabel,
  confirmLabel,
  loading,
}) => {
  return (
    <div className="inline-block align-bottom w-full text-left bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:align-middle sm:my-8 sm:max-w-lg">
      <div className="p-6 w-full bg-white sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="flex flex-shrink-0 items-center justify-center mx-auto w-12 h-12 bg-blue-100 rounded-full sm:mx-0 sm:w-10 sm:h-10">
            {icon('flex items-center justify-center w-6 h-6')}
          </div>
          <div className="mt-3 w-full sm:ml-4 sm:mt-0">
            <h3
              className="mb-4 mt-2 text-center text-gray-900 text-xl font-medium leading-6 sm:text-left"
              id="modal-title"
            >
              {title}
            </h3>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse px-6 py-3 bg-gray-50 sm:flex-row-reverse">
        {onSecondary && (
          <button
            type="button"
            onClick={onSecondary}
            disabled={loading}
            className="button button-secondary inline-flex justify-center w-full sm:ml-3 sm:w-auto sm:text-sm"
          >
            {cancelLabel}
          </button>
        )}
        {onConfirm && (
          <LoadingButton
            type="button"
            onClick={onConfirm}
            isLoading={loading}
            className="button button-primary inline-flex justify-center w-full sm:ml-3 sm:w-auto sm:text-sm"
          >
            {confirmLabel}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default ModalLayout;
