import React from 'react';
import { useHistory } from 'react-router-dom';
import Book from './Icons/Book';
import Home from './Icons/Home';
import ProgressLine from './Icons/ProgressLine';
import Time from './Icons/Time';

type StepProps = {
  label: string;
  IconComponent: React.FC<{ color: string; opacity: number }>;
  isActive: boolean;
  isPassed: boolean;
};

const Step = ({ label, IconComponent, isActive, isPassed }: StepProps) => {
  const classNames = isActive
    ? 'flex flex-col justify-around items-center rounded-lg bg-gray-50 bg-opacity-10 w-24 h-16 py-1'
    : '';

  return (
    <div className={classNames}>
      <IconComponent color="white" opacity={isPassed ? 1 : 0.25} />
      {isActive && <p className="text-sm font-semibold text-white">{label}</p>}
    </div>
  );
};

const progressMap = {
  'case-manager': 0,
  'alternative-contact': 0,
  profile: 0,
  housing: 1,
  care: 2,
  thanks: 3,
};

const OnboardingStepper = () => {
  const { location } = useHistory<History>();
  const onboardingStep = location.pathname.split('/')[2];

  const progress = progressMap[onboardingStep];

  return (
    <>
      {!(progress === 3) && (
        <div className="flex items-center justify-between max-w-xs pb-6">
          <Step
            label="Profile"
            IconComponent={Book}
            isActive={progress === 0}
            isPassed={progress >= 0}
          />
          <ProgressLine isPassed={progress > 0} />
          <Step
            label="Housing"
            IconComponent={Home}
            isActive={progress === 1}
            isPassed={progress >= 1}
          />
          <ProgressLine isPassed={progress > 1} />
          <Step
            label="Care"
            IconComponent={Time}
            isActive={progress === 2}
            isPassed={progress >= 2}
          />
        </div>
      )}
    </>
  );
};

export default OnboardingStepper;
