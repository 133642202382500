import Card from 'components/Common/Card';
import LoadingButton from 'components/Common/LoadingButton';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { alreadyMadeRumi } from '../../actions/auth';
import { ProfileType } from '../../models/profileType';
import { AppDispatch } from '../../store';
import FormField from '../Common/FormField';
import OnboardingLayout from '../Layout/OnboardingLayout';

type FormData = {
  firstName: string;
  lastName: string;
  birthday: string;
  email: string;
  type: string;
  alreadyMade: boolean;
  phone: string;
};

const AlreadyMadeMatchRumi = ({ history }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [emailInUse, setEmailInUse] = useState(null as any);
  const dispatch: AppDispatch = useDispatch();
  const [genericError, setGenericError] = useState('');

  const handleCreateAccount = async ({
    firstName,
    lastName,
    birthday,
    phone,
    email,
    type = ProfileType.RUMI,
    alreadyMade = true,
  }: FormData) => {
    if (isLoading) {
      return;
    }
    setGenericError('');
    setEmailInUse(null);

    const birthDate = new Date(birthday);
    if (!verifyBirthdate(birthDate)) {
      setError('birthday', {
        type: 'manual',
        message: 'You must be at least 17 years old',
      });
      return;
    }

    const phoneNumber = phone.replace(/[-() ]/g, '');
    setIsLoading(true);

    try {
      await dispatch(
        alreadyMadeRumi(firstName, lastName, email, birthDate, phoneNumber, type, alreadyMade),
      );
      history.replace('/onboarding');
    } catch (error) {
      const errorMessage =
        (error && error.data && error.data.message) || error.message || error.toString();
      if (error.status === 409) {
        setEmailInUse(email);
        setError('email', {
          type: 'manual',
          message: 'Please enter a different email address.',
        });
      } else {
        setGenericError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyBirthdate = (birthDate: Date): boolean => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 17);
    return birthDate < date;
  };

  const form = (
    <div className="space-y-6 sm:px-4 md:max-w-md">
      <div>
        <h3 className="mb-2 text-xl font-semibold">Rumi (Individual with a Disability):</h3>
        <p>
          Please specify contact information for the individual with a disability you provide care
          to.
        </p>
      </div>
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <div className="flex space-x-2">
          <div className="w-full mb-4">
            <FormField
              label="First name"
              type="text"
              {...register('firstName', {
                required: "Please enter Rumi's first name",
              })}
              autoComplete="given-name"
              placeholder="First"
              errors={errors}
              data-hj-allow
            />
          </div>
          <div className="w-full mb-4">
            <FormField
              label="Last name"
              type="text"
              {...register('lastName', {
                required: "Please enter Rumi's last name",
              })}
              autoComplete="family-name"
              placeholder="Last"
              errors={errors}
              data-hj-allow
            />
          </div>
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue=""
            name="birthday"
            rules={{
              required: 'Please enter Rumi’s date of birth',
            }}
            render={({ field }) => (
              <MaskedInput
                mask={[
                  /[0-1]/,
                  /[0-9]/,
                  '/',
                  /[0-3]/,
                  /[0-9]/,
                  '/',
                  /[1-2]/,
                  /[09]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                {...field}
                render={(ref, props) => (
                  <FormField
                    {...register('birthday', {
                      required: "Please enter Rumi's date of birth",
                    })}
                    autoComplete="bday"
                    label="Birthday"
                    type="text"
                    name="birthday"
                    placeholder="mm/dd/yyyy"
                    errors={errors}
                    data-hj-allow
                    ref={(input) => ref(input)}
                    {...props}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue=""
            name="phone"
            rules={{
              required: 'Please enter your phone number',
              pattern: {
                value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                message: 'Please enter a valid phone number',
              },
            }}
            render={({ field }) => (
              <MaskedInput
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                {...field}
                render={(ref, props) => (
                  <FormField
                    {...register('phone', {
                      required: "Please enter Rumi's phone number",
                      pattern: {
                        value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                        message: 'Please enter a valid phone number',
                      },
                    })}
                    label="Phone number"
                    type="tel"
                    autoComplete="tel"
                    name="phone"
                    placeholder="(xxx) xxx-xxxx"
                    errors={errors}
                    data-hj-allow
                    ref={(input) => ref(input)}
                    {...props}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            label="Email"
            type="email"
            {...register('email', {
              required: "Please enter Rumi's email address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address',
              },
            })}
            autoComplete="email"
            placeholder="example@email.com"
            errors={errors}
            data-hj-allow
          />
        </div>
        {emailInUse && (
          <StatusMessage
            type={StatusType.WARN}
            className="mt-2"
            message={<p className="text-md">That email already exists.</p>}
          />
        )}
        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <div className="flex justify-around">
          <LoadingButton isLoading={isLoading} className="w-full mt-4" variant="primary">
            Next
          </LoadingButton>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Rumi Sign Up | Rumi</title>
      </Helmet>
      <OnboardingLayout
        title="Friends & Family Sign Up"
        subtitle="Please enter contact information for the person receiving care."
      >
        <div className="mb-4 space-y-8">
          <Card expanded={false}>{form}</Card>
        </div>
      </OnboardingLayout>
    </>
  );
};

export default AlreadyMadeMatchRumi;
