import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import HelpButton from '../Common/HelpButton';
import OnboardingStepper from '../Common/OnboardingStepper';

type OnboardingLayoutProps = {
  title?: any;
  subtitle?: any;
  childrenPadding?: string;
};

const OnboardingLayout: FunctionComponent<OnboardingLayoutProps> = ({
  title,
  subtitle,
  children,
  childrenPadding,
}) => {
  const { location } = useHistory<History>();
  const isOnboarding = location.pathname.split('/')[1] === 'onboarding';

  return (
    <>
      <Helmet>{location.pathname !== '/signup' && <title>Onboarding | Rumi</title>}</Helmet>
      <div className="z-10 px-6 py-4 h-screen bg-gradient-to-b from-blue-400 to-blue-700 overflow-y-auto sm:px-16 sm:py-12 lg:px-32">
        <div className="flex h-16 items-center justify-between mb-8 sm:mb-16">
          {location.pathname === '/signup' ? (
            <Link to="/">
              <img
                src={require('../../assets/rumi-all-white.svg').default}
                className="w-32"
                alt="Rumi logo"
              />
            </Link>
          ) : (
            <img
              src={require('../../assets/rumi-all-white.svg').default}
              className="w-32"
              alt="Rumi logo"
            />
          )}
          <div className="flex flex-col">
            <HelpButton />
          </div>
        </div>
        <div className={`mb-${childrenPadding || '40'} w-full md:w-3/4 lg:max-w-2xl`}>
          {isOnboarding && <OnboardingStepper />}
          {title && (
            <h3 className="mb-4 text-white text-3xl font-bold sm:mb-6 sm:text-4xl">{title}</h3>
          )}
          {subtitle && <p className="mb-6 text-white sm:mb-12 sm:text-xl">{subtitle}</p>}
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default OnboardingLayout;
