import { history } from 'helpers/history';
import React from 'react';
import { GiHealthNormal } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import OnboardingLayout from '../Layout/OnboardingLayout';

const CareLanding = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const type = currentUser.profile.type;

  const title = type === 'CAREGIVER' ? "Let's talk about your schedule" : "Let's talk about care";
  let subtitle = (
    <>
      {type === 'CAREGIVER' ? (
        <>Help us understand how many hours of support you can provide per week.</>
      ) : (
        <>
          Helping us understand your care needs will ensure we can find the right Supportive
          Roommate (caregiver) for you.
        </>
      )}
      <br />
      <br />
      Rumi is about creating lasting life-sharing settings to help you and your future roommate
      thrive.
    </>
  );

  return (
    <OnboardingLayout
      title={
        <>
          {title} <GiHealthNormal size={34} className="inline mb-1 ml-2 text-white" />
        </>
      }
      subtitle={subtitle}
      childrenPadding="0"
    >
      <div className="flex flex-col">
        <button
          className="button button-primary-white relative z-10 w-full text-lg"
          onClick={() => history.push('/onboarding/care/hours')}
        >
          Set care preferences
        </button>
        <div className="relative z-0 bottom-0 right-0 mb-24 mt-8 opacity-50 sm:absolute sm:w-1/2">
          <img src={require('../../assets/care.svg').default} alt="" />
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default CareLanding;
