export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const ALREADY_MADE_MATCH_REGISTER_SUCCESS = 'ALREADY_MADE_MATCH_REGISTER_SUCCESS';
export const ALREADY_MADE_MATCH_REGISTER_FAIL = 'ALREADY_MADE_MATCH_REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const PAUSE_ACCOUNT = 'PAUSE_ACCOUNT';
export const UNPAUSE_ACCOUNT = 'UNPAUSE_ACCOUNT';

export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAIL = 'ADD_FAVORITE_FAIL';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAIL = 'DELETE_FAVORITE_FAIL';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAIL = 'UPDATE_NOTIFICATIONS_FAIL';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATED_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATED_PASSWORD_FAIL';

export const CHECK_PASSWORD_SUCCESS = 'CHECK_PASSWORD_SUCCESS';
export const CHECK_PASSWORD_FAIL = 'CHECK_PASSWORD_FAIL';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_EMAIL_CHANGE_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';

export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAIL = 'RESEND_VERIFICATION_FAIL';

export const SET_MESSAGE = 'SET_MESSAGE';

export const SET_WAVE_SENT_SUCCESS = 'SET_WAVE_SENT_SUCCESS';
export const SET_WAVE_SENT_FAIL = 'SET_WAVE_SENT_FAIL';
export const SET_IGNORE_WAVE_SUCCESS = 'SET_IGNORE_WAVE_SUCCESS';
export const SET_IGNORE_WAVE_FAIL = 'SET_IGNORE_WAVE_FAIL';
export const SET_WAVE_REQUESTS_SUCCESS = 'SET_WAVE_REQUESTS_SUCCESS';
export const SET_WAVE_REQUESTS_FAIL = 'SET_WAVE_REQUESTS_FAIL';
export const UNMATCH_CONNECTION = 'UNMATCH_CONNECTION';

export const HOUSING_LIVING_SUCCESS = 'HOUSING_LIVING_SUCCESS';
export const HOUSING_LIVING_FAIL = 'HOUSING_LIVING_FAIL';
export const HOUSING_PREFERENCES_SUCCESS = 'HOUSING_PREFERENCES_SUCCESS';
export const HOUSING_PREFERENCES_FAIL = 'HOUSING_PREFERENCES_FAIL';
export const CARE_HOURS_SUCCESS = 'CARE_HOURS_SUCCESS';
export const CARE_HOURS_FAIL = 'CARE_HOURS_FAIL';
export const CARE_TASKS_SUCCESS = 'CARE_TASKS_SUCCESS';
export const CARE_TASKS_FAIL = 'CARE_TASKS_FAIL';
export const ROOMMATE_GENDER_SUCCESS = 'ROOMMATE_GENDER_SUCCESS';
export const ROOMMATE_GENDER_FAIL = 'ROOMMATE_GENDER_FAIL';
export const ROOMMATE_PETS_SUCCESS = 'ROOMMATE_PETS_SUCCESS';
export const ROOMMATE_PETS_FAIL = 'ROOMMATE_PETS_FAIL';
export const STORY_BIO_SUCCESS = 'STORY_BIO_SUCCESS';
export const STORY_BIO_FAIL = 'STORY_BIO_FAIL';
export const STORY_SMOKING_DRINKING_SUCCESS = 'STORY_SMOKING_DRINKING_SUCCESS';
export const STORY_SMOKING_DRINKING_FAIL = 'STORY_SMOKING_DRINKING_FAIL';
export const STORY_GENDER_SUCCESS = 'STORY_GENDER_SUCCESS';
export const STORY_GENDER_FAIL = 'STORY_GENDER_FAIL';
export const STORY_REFERENCE_SUCCESS = 'STORY_REFERENCE_SUCCESS';
export const STORY_REFERENCE_FAIL = 'STORY_REFERENCE_FAIL';
export const MY_PETS_SUCCESS = 'MY_PETS_SUCCESS';
export const MY_PETS_FAIL = 'MY_PETS_FAIL';

export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';

export const RECEIVED_WAVE = 'RECEIVED_WAVE';
export const SET_CONNECTION = 'SET_CONNECTION';

export const REPORT_USER_SUCCESS = 'REPORT_USER_SUCCESS';
export const REPORT_USER_FAIL = 'REPORT_USER_FAIL';

export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';

export const START_WALKTHROUGH_TOUR = 'START_WALKTHROUGH_TOUR';
export const FINISH_WALKTHROUGH_TOUR = 'FINISH_WALKTHROUGH_TOUR';

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';

export const GET_FAVORITES = 'GET_FAVORITES';

export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const SET_REPORTS = 'SET_REPORTS';
export const ADD_EVENT = 'ADD_EVENT';
export const SET_USERS = 'SET_USERS';
export const SET_USER_EVENTS = 'SET_USER_EVENTS';

export const UPDATE_CASE_MANAGER_SUCCESS = 'UPDATE_CASE_MANAGER_SUCCESS';
export const UPDATE_CASE_MANAGER_FAIL = 'UPDATE_CASE_MANAGER_FAIL';
export const UPDATE_ALTERNATIVE_CONTACT_SUCCESS = 'UPDATE_ALTERNATIVE_CONTACT_SUCCESS';
export const UPDATE_ALTERNATIVE_CONTACT_FAIL = 'UPDATE_ALTERNATIVE_CONTACT_FAIL';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const ALREADY_MADE_RUMI_REGISTER_SUCCESS = 'ALREADY_MADE_RUMI_REGISTER_SUCCESS';
export const ALREADY_MADE_RUMI_REGISTER_FAIL = 'ALREADY_MADE_RUMI_REGISTER_FAIL';

export const NEW_ADMIN_MESSAGE = 'NEW_ADMIN_MESSAGE';
export const NEW_ADMIN_MESSAGE_CONNECTION = 'NEW_ADMIN_MESSAGE_CONNECTION';
export const FETCH_ADMIN_MESSAGES_SUCCESS = 'FETCH_ADMIN_MESSAGES_SUCCESS';
export const ADMIN_DELETE_MESSAGE = 'ADMIN_DELETE_MESSAGE';

export const ADMIN_NOTIFICATIONS = 'ADMIN_NOTIFICATIONS';
export const ADMIN_POTENTIAL_NOTIFICATION_USERS = 'ADMIN_POTENTIAL_NOTIFICATION_USERS';

export const ANALYTIC_WAVES = 'ANALYTIC_WAVES';
export const ANALYTIC_USERS = 'ANALYTIC_USERS';
export const ANALYTIC_MESSAGES = 'ANALYTIC_MESSAGES';
export const ANALYTIC_CALENDAR = 'ANALYTIC_CALENDAR';
