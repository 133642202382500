import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import authHeader, { authToken, refreshToken } from './auth-header';
import store from 'store';
import { LOGOUT } from 'actions/types';
import { messageOnlyToast } from 'helpers/toasts';
import { getDeviceId } from 'helpers/utils';

const axiosConfig = {
  baseURL: '/api',
};

let api = axios.create(axiosConfig);

export const baseApi = axios.create(axiosConfig);

[axios, api, baseApi].forEach((a: any) =>
  a.interceptors.request.use((config: any) => {
    config.headers['X-DEVICE_ID'] = getDeviceId();
    config.timeout = 60000;
    return config;
  }),
);

const refreshAuthLogic = (failedRequest: any) =>
  !refreshToken()
    ? Promise.reject(failedRequest)
    : axios
        .post(
          '/api/auth/refresh',
          {
            refreshToken: refreshToken(),
          },
          { headers: authHeader() },
        )
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data));
          failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.token}`;
          return Promise.resolve();
        })
        .catch((error) => {
          localStorage.removeItem('user');
          store.dispatch({ type: LOGOUT });
          messageOnlyToast('Your session has expired. Please login again.');
          return Promise.reject(error);
        });

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${authToken()}`;
  config.timeout = 60000;
  return config;
});

createAuthRefreshInterceptor(api, refreshAuthLogic);

export default api;
