import { v4 as uuidv4 } from 'uuid';

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const isMobileOrTablet = (): boolean => {
  return window.matchMedia('only screen and (max-width: 768px)').matches;
};

export const resizeEvent = (): void => {
  var resizeEvent = new Event('resize');
  window.dispatchEvent(resizeEvent);
};

export const getDeviceId = () => {
  let id = localStorage.getItem('deviceId');
  if (!id) {
    id = uuidv4();
    localStorage.setItem('deviceId', id);
  }
  return id;
};

export function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return str;
};

export const analyticsMath = (arr) => {
  const day = new Date().toISOString().split('T')[0];
  const weekAgo = new Date(Date.now() - 7 * 1000 * 60 * 60 * 24).toISOString().split('T')[0];
  const monthAgo = new Date(Date.now() - 30 * 1000 * 60 * 60 * 24).toISOString().split('T')[0];
  return {
    day: arr.filter((a) => new Date(a).toISOString().split('T')[0] === day).length,
    week: arr.filter((a) => new Date(a).toISOString().split('T')[0] > weekAgo).length,
    month: arr.filter((a) => new Date(a).toISOString().split('T')[0] > monthAgo).length,
  };
};
