import Modal from 'components/Common/Modal';
import React from 'react';
import { BiQuestionMark } from 'react-icons/bi';

const SupportModal = ({ show, onHide, children }) => {
  return (
    <>
      {children}
      <Modal
        show={show}
        hideAction={() => onHide(false)}
        onSecondary={() => onHide(false)}
        title={`Need help?`}
        icon={(className) => <BiQuestionMark className={className} />}
        cancelLabel={'Close'}
        lockOpen={false}
      >
        <div className="flex flex-col divide-gray-300 divide-y">
          <p className="px-5 py-3">If you need assistance, please call or email the Rumi team.</p>
          <div className="px-5 py-3 cursor-default">
            Hours: <span className="font-bold">Mon-Fri 8am-5pm</span>
          </div>
          <a className="px-5 py-3" href="tel:6516955805">
            Phone: <span className="hover:underline font-bold">651-695-5805</span>
          </a>
          <a className="px-5 py-3" href="mailto:info@meetmyrumi.com">
            Email: <span className="hover:underline font-bold">info@meetmyrumi.com</span>
          </a>
          <div
            className="px-5 py-3 cursor-pointer"
            onClick={() => window.open('https://meetmyrumi.com/')}
          >
            Website: <span className="hover:underline font-bold">meetmyrumi.com</span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SupportModal;
