import { history } from 'helpers/history';
import React from 'react';
import { GiPartyPopper } from 'react-icons/gi';
import OnboardingLayout from '../Layout/OnboardingLayout';

const StoryLanding = () => {
  return (
    <OnboardingLayout
      title={
        <>
          Welcome to Rumi <GiPartyPopper size={34} className="inline mb-1 ml-2 text-white" />
        </>
      }
      subtitle="We have some questions we’ll need answered to make sure we help you find the ideal roommate."
      childrenPadding="0"
    >
      <div className="flex flex-col">
        <button
          className="button button-primary-white relative z-10 w-full text-lg"
          onClick={() => history.push('/onboarding/profile/gender')}
        >
          Set up your profile
        </button>
        <div className="relative z-0 bottom-0 right-0 mb-24 mt-8 opacity-50 sm:absolute sm:w-1/2">
          <img src={require('../../assets/welcome.svg').default} alt="" />
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default StoryLanding;
