import { firstUnfinishedStep, steps } from 'helpers/wizardCompletion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { RootState } from 'reducers';
import { AppDispatch } from 'store';

const OnboardingIndex = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();

  const step = firstUnfinishedStep(currentUser, dispatch);

  return <Redirect to={step ? step.url : steps[0].url} />;
};

export default withRouter(OnboardingIndex);
