import { Dialog } from '@headlessui/react';
import { hideModal } from 'actions/modals';
import React from 'react';
import { BsSlash } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';

const IgnoreWaveModal = ({ cancelButtonRef, details, onConfirm }) => {
  const dispatch: AppDispatch = useDispatch();

  const name = details.firstName || details.name;

  const onCancel = () => {
    dispatch(hideModal());
  };

  return (
    <>
      <div>
        <div className="flex flex-wrap justify-center">
          <div className="relative flex items-center w-20 h-20 overflow-hidden bg-white border rounded-full shadow">
            <img
              className="w-full"
              src={details.image || require('../../assets/default_avatar.png').default}
              alt={details.name + ' profile photo'}
            />
            <div className="absolute z-10 flex items-center justify-center transform -inset-6">
              <BsSlash className="w-full h-full text-blue-500" />
            </div>
          </div>
        </div>
        <div className="my-3 text-center">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            Pass on {name}'s wave?
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-gray-500">{name} won't see you as a potential roommate again.</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:grid sm:gap-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:mt-6">
        <button
          type="button"
          className="inline-flex justify-center w-full button button-danger sm:col-start-2 sm:text-sm"
          onClick={onConfirm}
        >
          <FaTimes className="w-4 h-4 mr-1" /> Pass
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full button button-secondary sm:col-start-1 sm:text-sm"
          onClick={onCancel}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default IgnoreWaveModal;
