import { unpauseAccount } from 'actions/user';
import React, { useState } from 'react';
import { IoIosPause } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AppDispatch } from 'store';
import ModalLayout from '../Layout/ModalLayout';

const AccountPausedOverlay = () => {
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);
  const accountPaused = user.profile?.status === 'PAUSED';
  const dispatch: AppDispatch = useDispatch();

  if (!accountPaused) return <></>;

  const handleUnpauseAccount = async () => {
    setLoading(true);
    try {
      await dispatch(unpauseAccount());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-25">
      <div className="px-4">
        <ModalLayout
          icon={(className) => <IoIosPause className={className} />}
          title="Your account is paused"
          onConfirm={handleUnpauseAccount}
          confirmLabel="Unpause my account"
          loading={loading}
        >
          <p>
            Your account is paused. Unpause your account to start searching and messaging your
            connections again.
          </p>
        </ModalLayout>
      </div>
    </div>
  );
};

export default AccountPausedOverlay;
