import { getMyConnections } from 'actions/connection';
import { hideModal, notificationSummaryModal } from 'actions/modals';
import { getMyFavorites, readNotifications } from 'actions/user';
import React, { useEffect, useState } from 'react';
import {
  RiChatSmile2Fill,
  RiChatSmile2Line,
  RiSearchFill,
  RiSearchLine,
  RiSettings5Fill,
  RiSettings5Line,
  RiStarFill,
  RiStarLine,
  RiUserFill,
  RiUserLine,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPendingIncomingWaves, getUnreadConnections, getUnreadMessages } from 'selectors';
import { AppDispatch } from 'store';
import { ProfileStatus } from 'types';
import { history } from '../../helpers/history';

const getPathArray = (pathname) => {
  return pathname.split('/');
};

const UserNavigation = () => {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [pathname, setPathname] = useState(getPathArray(history.location.pathname));
  const [shownNotificationSummary, setShownNotificationSummary] = useState(false);
  const unreadConnections: any[] = useSelector(getUnreadConnections);
  const unreadMessages: any[] = useSelector(getUnreadMessages);
  const unreadWaves = useSelector(getPendingIncomingWaves);

  const totalNotifications =
    unreadConnections?.length + unreadWaves?.length + unreadMessages?.length;

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyConnections());
    dispatch(getMyFavorites());
    // don't show notification summary after 5 seconds
    setTimeout(() => setShownNotificationSummary(true), 5000);
  }, [dispatch]);

  useEffect(() => {
    if (totalNotifications && !shownNotificationSummary) {
      notificationSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNotifications]);

  const notificationSummary = () => {
    const newMessages = unreadMessages?.map((message: any) => {
      const otherUser =
        message.initiatorId === currentUser.id ? message.receiver : message.initiator;
      return {
        id: otherUser.id,
        name: otherUser.firstName,
        image: otherUser.image,
      };
    });
    const newConnections = unreadConnections?.map((connection: any) => {
      const otherUser =
        connection.initiatorId === currentUser.id ? connection.receiver : connection.initiator;
      return {
        id: otherUser.id,
        name: otherUser.firstName,
        image: otherUser.image,
      };
    });
    const newWaves = unreadWaves?.map((wave: any) => {
      return {
        id: wave.initiator.id,
        name: wave.initiator.firstName,
        image: wave.initiator.image,
      };
    });
    dispatch(
      notificationSummaryModal(
        { messages: newMessages, connections: newConnections, waves: newWaves },
        () => {
          history.push('/connections/messages');
          dispatch(readNotifications());
          dispatch(hideModal());
          setShownNotificationSummary(true);
        },
        () => {
          dispatch(readNotifications());
          dispatch(hideModal());
          setShownNotificationSummary(true);
        },
      ),
    );
  };

  const navLinks: any = [
    {
      label: 'Connections',
      to: 'connections',
      id: 'connections',
      idMobile: 'connectionsMobile',
      icon: (size, className) => <RiChatSmile2Line size={size} className={className} />,
      activeIcon: (size, className) => <RiChatSmile2Fill size={size} className={className} />,
      badge: !!totalNotifications ? totalNotifications : 0,
    },
    {
      label: 'Search',
      to: 'search',
      id: 'search',
      idMobile: 'searchMobile',
      icon: (size, className) => <RiSearchLine size={size} className={className} />,
      activeIcon: (size, className) => <RiSearchFill size={size} className={className} />,
    },
    {
      label: 'Favorites',
      to: 'favorites',
      id: 'favorites',
      idMobile: 'favoritesMobile',
      icon: (size, className) => <RiStarLine size={size} className={className} />,
      activeIcon: (size, className) => <RiStarFill size={size} className={className} />,
    },
    {
      label: 'Profile',
      to: 'settings',
      id: 'settings',
      idMobile: 'profileMobile',
      icon: (size, className) => <RiUserLine size={size} className={className} />,
      activeIcon: (size, className) => <RiUserFill size={size} className={className} />,
      mobile: true,
    },
  ];

  const navigateToPage = (link) => {
    history.push(link);
  };

  const isSearch = (link) => {
    // return (
    //   link === 'search' &&
    //   pathname[1] === 'profile' &&
    //   pathname[2] &&
    //   currentUser.id !== +pathname[2]
    // );
    // let's figure out a good way to know where
    // the user currently is when they are multiple routes deep
    // ex: open a user profile from search should show search tab active
    // ex: open a user profile from Messages tab, should show connections tab
    // etc.
    return false;
  };

  useEffect((): any => {
    let mounted = true;
    setPathname(getPathArray(history.location.pathname));

    history.listen((location) => {
      if (mounted) {
        setPathname(getPathArray(location.pathname));
      }
    });
    return () => (mounted = false);
  }, []);

  return (
    <div className="h-full bg-white select-none">
      <aside className="flex-col hidden w-64 h-full bg-white border-r lg:flex">
        <div
          onClick={() => window.open('https://meetmyrumi.com/')}
          className="h-24 cursor-pointer focus:outline-none active:opacity-75"
        >
          <div className="flex justify-start h-24 align-left px-14">
            <img
              className="object-contain"
              src={require('../../assets/rumi.png').default}
              alt="Rumi logo"
            />
          </div>
        </div>
        <nav
          aria-label="Main"
          className="flex-1 px-4 py-2 space-y-2 overflow-y-auto border-t hover:overflow-y-auto"
        >
          <div className="flex justify-center pt-2">
            <Link
              to="/profile"
              className="flex items-center w-32 h-32 overflow-hidden border-2 border-gray-300 rounded-full shadow-md focus:outline-none active:opacity-75"
            >
              <img
                className="w-full"
                src={currentUser.image || require('../../assets/default_avatar.png').default}
                alt={currentUser.name + ' profile photo'}
              />
            </Link>
          </div>
          <div className="flex justify-center px-2">
            <Link
              to="/profile"
              id="profile"
              className={
                'rounded-lg button button-subtle flex flex-col border-2 border-gray-100 px-8' +
                ((pathname[1] === 'profile' && !pathname[2]) || currentUser.id === +pathname[2]
                  ? ' bg-gray-100 text-blue-500 border-blue-500 font-semibold'
                  : ' hover:bg-gray-100 text-gray-900 hover:text-gray-800')
              }
            >
              <h3 className="text-xl font-medium leading-6 text-center">{currentUser.firstName}</h3>
              <div className="text-sm font-semibold text-center text-gray-500">View my profile</div>
            </Link>
          </div>
          {currentUser.profile.status === ProfileStatus.PAUSED && (
            <div className="pb-4 text-sm font-semibold text-center">
              <span className="px-4 py-2 text-white bg-blue-500 rounded">
                Your account is paused
              </span>
            </div>
          )}

          {navLinks.map(
            (link: any) =>
              !link.mobile && (
                <button
                  key={link.label}
                  id={link.id}
                  className={
                    'button button-subtle border-gray-100 text-gray-600 w-full' +
                    (pathname[1] === link.to || isSearch(link.to)
                      ? ' bg-gray-100 text-blue-500 border-blue-500'
                      : ' hover:bg-gray-100 hover:text-gray-800')
                  }
                  onClick={() => navigateToPage(`/${link.to}`)}
                >
                  {pathname[1] === link.to ? link.activeIcon(24) : link.icon(24)}

                  <span className="flex flex-1 ml-3 text-md">{link.label}</span>
                  {!!link.badge && (
                    <div className="relative flex p-3 bg-red-500 rounded-full">
                      <span className="flex items-center justify-center">
                        <span className="absolute text-xs text-white">{link.badge}</span>
                      </span>
                    </div>
                  )}
                </button>
              ),
          )}
        </nav>

        <div className="flex-shrink-0 px-4 py-2 space-y-2 border-t">
          <button
            className={`button button-subtle text-gray-600 w-full ${
              pathname[1].indexOf('settings') > -1
                ? ' bg-gray-100 text-blue-500 border-blue-500'
                : ' hover:bg-gray-100 hover:text-gray-800'
            }`}
            onClick={() => history.push('/settings/account')}
          >
            {pathname[1] === 'settings' ? (
              <RiSettings5Fill size={24} className="my-auto mr-1" />
            ) : (
              <RiSettings5Line size={24} className="my-auto mr-1" />
            )}
            Settings
          </button>
        </div>
      </aside>

      <section
        id="bottom-navigation"
        className="absolute inset-x-0 bottom-0 z-10 block bg-white border-t-2 shadow-2xl lg:hidden"
      >
        <div id="tabs" className="flex justify-between">
          {navLinks.map((link: any) => (
            <button
              key={link.label}
              id={link.idMobile}
              className={
                'w-full focus:outline-none border-l inline-block text-center pt-3 pb-5 px-1 ' +
                (pathname[1] === link.to
                  ? 'text-gray-800 bg-gray-200'
                  : 'text-gray-600 hover:bg-gray-50')
              }
              onClick={() => navigateToPage(`/${link.to}`)}
            >
              {!link.badge &&
                (pathname[1] === link.to
                  ? link.activeIcon(28, 'mx-auto')
                  : link.icon(28, 'mx-auto'))}
              {!!link.badge && (
                <span className="w-6 h-6 p-1 text-xs text-white bg-red-500 rounded-full">
                  <span className="p-2">{link.badge}</span>
                </span>
              )}
              <span className="block text-xs sm:text-sm">{link.label}</span>
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

export default UserNavigation;
