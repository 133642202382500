import React from 'react';
import classNames from 'classnames';

const ConnectionPlaceholder = ({ className }) => {
  return (
    <div className={classNames(className, 'user flex flex-col w-full')}>
      <div className="flex">
        <div className="mr-2 w-12 h-12">
          <div
            data-placeholder
            className="relative w-12 h-12 bg-gray-200 rounded-full overflow-hidden"
          ></div>
        </div>
        <div className="flex flex-col justify-between p-0.5 overflow-hidden">
          <div data-placeholder className="relative w-24 h-4 bg-gray-200 overflow-hidden"></div>
          <div data-placeholder className="relative w-48 h-4 bg-gray-200 overflow-hidden"></div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionPlaceholder;
