import React from 'react';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import { HandleItem } from 'react-compound-slider/dist/types/types';
import { Handle, Track } from './SliderComponents';

const sliderStyle: React.CSSProperties = {
  padding: '12px 0',
  margin: '0 auto 16px',
  position: 'relative',
  width: '100%',
};

const railStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: 14,
  borderRadius: 7,
  cursor: 'pointer',
  backgroundColor: '#d9e0e9',
};

type PropTypes = {
  domain: number[];
  values: number[];
  step: number;
  left: boolean;
  right: boolean;
  onSliderChange: (values: readonly number[]) => void;
  onSliderUpdate: (values: readonly number[]) => void;
  onMouseUp?: (values: readonly number[]) => void;
  sliderMode: (curr: HandleItem[], next: HandleItem[]) => HandleItem[];
};

const SliderInput = ({
  domain,
  values = [],
  step,
  left,
  right,
  onSliderChange,
  onSliderUpdate,
  onMouseUp = () => {},
  sliderMode,
}: PropTypes) => {
  return (
    <>
      <Slider
        mode={(curr: HandleItem[], next: HandleItem[]) => sliderMode(curr, next)}
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={(values: readonly number[]) => onSliderChange(values)}
        onSlideEnd={(values: readonly number[]) => onMouseUp(values)}
        onUpdate={(values: readonly number[]) => onSliderUpdate(values)}
        values={values}
      >
        <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </>
          )}
        </Handles>
        <Tracks left={left} right={right}>
          {({ tracks, getTrackProps }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </>
          )}
        </Tracks>
      </Slider>
    </>
  );
};

export default SliderInput;
