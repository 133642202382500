import { hideModal, ignoreWaveModal } from 'actions/modals';
import classNames from 'classnames';
import { format } from 'date-fns';
import { waveToast } from 'helpers/toasts';
import React from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { BsArrowLeft } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPendingIncomingWaves, getPendingOutgoingWaves } from 'selectors';
import { getMyConnections, ignoreWave, sendWave } from '../../actions/connection';
import { history } from '../../helpers/history';
import { AppDispatch } from '../../store';
import ConnectionPlaceholder from './ConnectionPlaceholder';
import './Connections.scss';

const NoRequestsYet = () => {
  return (
    <div className="w-full py-16 text-center text-gray-400 select-none">
      <div className="block py-4">New wave requests will appear here</div>
      <div className="space-x-2">
        <button
          onClick={() => history.replace('/connections/messages')}
          className="flex items-center justify-center p-2 mx-auto link"
        >
          <BsArrowLeft className="mr-1" /> Messages
        </button>
      </div>
    </div>
  );
};

const RequestList = ({ isLoading }) => {
  const dispatch: AppDispatch = useDispatch();

  const { id }: any = useParams();
  const requests = useSelector(getPendingIncomingWaves || []);
  const pending = useSelector(getPendingOutgoingWaves || []);

  const acceptReq = (initiator) => {
    dispatch(sendWave(initiator.id)).then(async () => {
      toast.success(`You connected with ${initiator.firstName}!`);
      await dispatch(getMyConnections());
    });
  };

  const ignoreReq = (initiator: any) => {
    dispatch(
      ignoreWaveModal(initiator, () => {
        dispatch(ignoreWave(initiator.id)).then(() => {
          waveToast(`You passed on ${initiator.firstName}'s wave`);
          dispatch(getMyConnections());
          dispatch(hideModal());
        });
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Waves | Rumi</title>
      </Helmet>
      <div className="flex-1 overflow-auto">
        {!isLoading && !requests?.length && <NoRequestsYet />}
        {isLoading &&
          [1, 2].map((i) => (
            <ConnectionPlaceholder key={i} className={`opacity-${100 - i * 20}`} />
          ))}
        {!!requests?.length && <h2 className="flex flex-1 p-2 border-b">Wave Requests</h2>}
        {requests.map((req: any) => {
          const date = new Date(req?.initiatorLastEvent);
          return (
            <div
              key={req.id}
              className={classNames(+id === req.initiator ? 'active' : '', `user flex-col`)}
            >
              <div
                className="flex flex-row w-full"
                onClick={() => history.push(`/profile/${req.initiator?.slug}`)}
              >
                <div className="flex flex-wrap justify-center mr-2">
                  <div className="w-12 h-12 overflow-hidden bg-white border rounded-full shadow">
                    <img
                      className="object-contain w-full mx-auto"
                      src={
                        req?.initiator.image || require('../../assets/default_avatar.png').default
                      }
                      alt={`${req.initiator.name} profile`}
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex flex-col justify-center flex-1 ml-2 truncate">
                    <div className="font-semibold">
                      {req.initiator?.firstName} waved at you{' '}
                      <span role="img" className="animate-wave" aria-label="wave emoji">
                        👋
                      </span>
                    </div>
                    <div className="overflow-hidden text-gray-500 overflow-ellipsis">
                      Tap to view their profile
                    </div>
                  </div>

                  <div className="text-xs text-gray-500">{format(date, 'M/d')}</div>
                </div>
              </div>
              {req.lastMessage && (
                <div
                  className="p-2 mt-3 bg-gray-100 rounded-md"
                  onClick={() => history.push(`/profile/${req.initiator?.slug}`)}
                >
                  <span className="text-xs font-semibold uppercase">Message</span>
                  <div className="text-xs font-medium text-gray-600">{req.lastMessage.content}</div>
                </div>
              )}

              <div className="flex flex-row justify-center mt-3 space-x-2">
                <button
                  className="w-full button button-primary"
                  onClick={() => acceptReq(req.initiator)}
                >
                  Wave back
                </button>
                <button
                  className="whitespace-nowrap button button-danger"
                  onClick={() => ignoreReq(req.initiator)}
                >
                  <FaTimes className="mr-1" /> Pass
                </button>
              </div>
            </div>
          );
        })}

        {!!pending?.length && <h2 className="flex flex-1 p-2 border-b">Waves Sent</h2>}
        {pending.map((req: any) => {
          const date = new Date(req?.initiatorLastEvent);
          return (
            <div
              key={req.id}
              className={classNames(+id === req.receiver ? 'active' : '', `user flex-col`)}
            >
              <div
                className="flex flex-row w-full"
                onClick={() => history.push(`/profile/${req.receiver?.slug}`)}
              >
                <div className="flex flex-wrap justify-center mr-2">
                  <div className="w-12 h-12 overflow-hidden bg-white border rounded-full shadow">
                    <img
                      className="object-contain w-full mx-auto"
                      src={
                        req?.receiver.image || require('../../assets/default_avatar.png').default
                      }
                      alt={`${req.receiver.name} profile`}
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex flex-col justify-center flex-1 ml-2 truncate">
                    <div className="font-semibold">
                      {req.receiver?.firstName}{' '}
                      <span role="img" className="animate-wave" aria-label="wave emoji">
                        👋
                      </span>
                    </div>
                    <div className="overflow-hidden text-gray-500 overflow-ellipsis">
                      Tap to view their profile
                    </div>
                  </div>

                  <div className="text-xs text-gray-500">{format(date, 'M/d')}</div>
                </div>
              </div>
              {req.lastMessage && (
                <div
                  className="p-2 mt-3 bg-gray-100 rounded-md"
                  onClick={() => history.push(`/profile/${req.receiver?.slug}`)}
                >
                  <span className="text-xs font-semibold uppercase">Message</span>
                  <div className="text-xs font-medium text-gray-600">{req.lastMessage.content}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RequestList;
