import ImageUploader from 'components/Common/ImageUploader';
import LoadingButton from 'components/Common/LoadingButton';
import Modal from 'components/Common/Modal';
import { getOnboardingLocalStorage } from 'helpers/getOnboardingState';
import React, { useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { FaCamera } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Card from '../Common/Card';
import OnboardingLayout from '../Layout/OnboardingLayout';

const ProfilePhoto = ({ history }) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [image, setImage] = useState<any>(currentUser.image);
  const [imageModal, setImageModal] = useState(false);

  const onFinish = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (image) {
      setTimeout(() => {
        history.push('/onboarding');
        setIsLoading(false);
      }, 800);
    } else {
      setImageModal(true);
      setIsLoading(false);
    }
  };

  const skipProfilePhoto = () => {
    let onboarding = getOnboardingLocalStorage();
    onboarding.picSkipped = true;
    localStorage.setItem('onboarding', JSON.stringify(onboarding));
    history.push('/onboarding');
  };

  return (
    <>
      <OnboardingLayout
        title={
          <>
            Upload profile picture <FaCamera size={34} className="inline mb-1 ml-2 text-white" />
          </>
        }
        subtitle="Add a photo that shows off your best roommate smile 😁"
      >
        <div className="w-full">
          <div>
            <Card className="p-0 max-w-sm text-white bg-blue-400">
              <ImageUploader
                image={image}
                onCancel={() => {
                  setIsStarted(false);
                }}
                onFinish={(img) => {
                  setImage(img);
                  setIsStarted(false);
                }}
                onStart={() => {
                  setIsStarted(true);
                }}
              />
            </Card>
          </div>
          {!isStarted && (
            <LoadingButton
              isLoading={isLoading}
              className="hidden mt-4 w-full text-lg md:flex"
              onClick={onFinish}
              variant="primary-white"
            >
              {image ? <>Looks good, continue</> : <>Skip for now</>}
            </LoadingButton>
          )}
        </div>
      </OnboardingLayout>
      <div className="fixed bottom-0 px-2 w-full bg-white md:hidden">
        {!isStarted && (
          <LoadingButton
            isLoading={isLoading}
            className="w-full text-lg"
            onClick={onFinish}
            variant="primary"
          >
            {image ? <>Looks good, continue</> : <>Skip for now</>}
          </LoadingButton>
        )}
      </div>

      <Modal
        show={imageModal}
        hideAction={() => setImageModal(false)}
        onSecondary={() => setImageModal(false)}
        onConfirm={() => skipProfilePhoto()}
        loading={isLoading}
        icon={(className) => <AiFillWarning className={className} />}
        title={`Are you sure you want to continue without a profile photo?`}
        lockOpen={true}
        confirmLabel={'Continue'}
        cancelLabel={'I want to add a photo!'}
      >
        <div className="w-full">
          <p className="text-gray-600">
            Users who have profile photos are <b>11x</b> more likely to receive a wave.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ProfilePhoto;
