export function authToken() {
  const user = JSON.parse(localStorage.getItem('user')!);
  return user?.token;
}

export function refreshToken() {
  const user = JSON.parse(localStorage.getItem('user')!);
  return user?.refreshToken;
}

export default function authHeader() {
  const token = authToken();
  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}
