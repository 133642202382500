import Modal from 'components/Common/Modal';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import { history } from 'helpers/history';
import React, { useEffect, useState } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeEmailVerify } from '../../actions/auth';
import { RootState } from '../../reducers';
import { AppDispatch } from '../../store';

const ChangeEmailVerify = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(currentUser?.email);

  const search = useLocation().search;
  const emailToken = new URLSearchParams(search).get('token');

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (emailToken) {
      dispatch(changeEmailVerify(emailToken))
        .then((res) => {
          if (currentUser?.newEmail) {
            setEmail(currentUser.newEmail);
          }
          setSuccessful(true);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, emailToken]);

  return (
    <>
      <div className="fixed z-10 inset-0 bg-black"></div>
      <Modal
        show={true}
        hideAction={() => false}
        title={'Verify email'}
        onSecondary={() =>
          history.replace(
            currentUser ? '/' : email ? '/login?email=' + encodeURIComponent(email) : '/login',
          )
        }
        cancelLabel={'Okay'}
        loading={loading}
        icon={(className) => <HiOutlineMail className={className} />}
      >
        {loading ? (
          <>One moment...</>
        ) : (
          <>
            {successful ? (
              <>
                Your email changed successfully! <br />
                Click <b>Okay</b> to login again.
              </>
            ) : (
              <StatusMessage
                type={StatusType.ERROR}
                message={'Sorry, that link expired. Please try again.'}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ChangeEmailVerify;
