import classNames from 'classnames';
import AccountPausedOverlay from 'components/Common/AccountPausedOverlay';
import MobileLogo from 'components/Common/MobileLogo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
  getAcceptedConnections,
  getPendingIncomingWaves,
  getUnreadConnections,
  getUnreadMessages,
} from 'selectors';
import { getMyConnections } from '../../actions/connection';
import { history } from '../../helpers/history';
import { AppDispatch } from '../../store';
import Header from '../Common/Header';
import HelpButton from '../Common/HelpButton';
import './Connections.scss';
import Message from './Message';
import MessageList from './MessageList';
import RequestList from './RequestList';

const NoMessageSelected = () => {
  return (
    <div className="w-full py-16 text-center text-gray-400 select-none">
      <div className="inline-flex py-4">Select a connection to start a chat</div>
    </div>
  );
};

const NoRequestSelected = () => {
  return (
    <div className="w-full py-16 text-center text-gray-400 select-none">
      <div className="inline-flex py-4">Select user to view their profile</div>
    </div>
  );
};

const Connections = () => {
  const connections = useSelector(getAcceptedConnections);
  const requests = useSelector(getPendingIncomingWaves);
  const { slug }: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const unreadConnections = useSelector(getUnreadConnections);
  const unreadMessages: any[] = useSelector(getUnreadMessages);
  const totalMessages = unreadMessages?.length + unreadConnections?.length;

  useEffect(() => {
    if (!requests?.length && !connections?.length) {
      setLoading(true);
      dispatch(getMyConnections())
        .catch(() => {
          //error handling
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navLinks = [
    {
      label: 'Messages',
      id: 'messages',
      to: '/connections/messages',
      badge: !!totalMessages,
    },
    {
      label: 'Waves',
      id: 'waveRequests',
      to: '/connections/requests',
      badge: !!requests?.length,
    },
  ];

  return (
    <>
      <div className="relative flex flex-col h-full overflow-hidden connections">
        <AccountPausedOverlay />
        <Header className="relative justify-between header header-small">
          <MobileLogo />
          <h2>Connections</h2>
          <div className="flex flex-col">
            <HelpButton />
          </div>
        </Header>
        <div className="flex flex-1 overflow-hidden">
          <div
            className={classNames(
              slug && 'hidden sm:flex',
              'flex flex-col w-full h-full bg-white border-r sm:w-80',
            )}
          >
            <div className="select-none header">
              <nav className="flex flex-row items-center bg-white border-b">
                {navLinks.map((navLink) => (
                  <button
                    id={navLink.id}
                    key={navLink.label}
                    onClick={() => history.push(navLink.to)}
                    className={`tab ${
                      history.location.pathname.indexOf(navLink.to) > -1 ? 'active' : ''
                    }`}
                  >
                    {navLink.label}
                    <span className="relative flex-grow text-right">
                      {navLink.badge && (
                        <>
                          <span className="absolute w-3 h-3 bg-red-500 rounded-full"></span>
                          <span className="absolute top-0 left-0 w-3 h-3 bg-red-500 rounded-full animate-pings"></span>
                        </>
                      )}
                    </span>
                  </button>
                ))}
              </nav>
            </div>
            <Switch>
              <Route
                path="/connections/messages/:slug"
                exact
                render={() => <MessageList isLoading={isLoading} />}
              />
              <Route
                path="/connections/messages"
                exact
                render={() => <MessageList isLoading={isLoading} />}
              />
              <Route
                path="/connections/requests"
                exact
                render={() => <RequestList isLoading={isLoading} />}
              />
              <Redirect to="/connections/messages" />
            </Switch>
          </div>
          <div
            className={classNames('flex-1 items-center justify-center', !slug && 'hidden sm:flex')}
          >
            <Switch>
              <Route exact path="/connections/messages/:slug" component={Message} />
              <Route exact path="/connections/messages" component={NoMessageSelected} />
              <Route exact path="/connections/requests" component={NoRequestSelected} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connections;
