import { analyticsMath } from 'helpers/utils';
import { orderBy } from 'lodash';
import { RootState } from 'reducers';
import { createSelector } from 'reselect';
import { ROLE } from '../models/roles';
import { ConnectionStatus } from './../models/connectionStatus';
import { ProfileStatus } from './../types/index';

export const getLoggedInUser = (state: RootState) => state.auth.user;

export const modalIsOpen = (state: RootState) => state.modals.isOpen;

export const getFlattenedFavorites = (state: RootState) =>
  Object.values(state.users.favorites).flat();

export const getFlattenedConnections = (state: RootState) =>
  Object.values(state.connections.connections).flat();

export const getAdminFlattenedConnections = (state: RootState) =>
  Object.values(state.admin.connections).flat();

export const getAdminFlattenedReports = (state: RootState) =>
  Object.values(state.admin.reports).flat();

export const fetchAllAdminReports = createSelector(
  [getAdminFlattenedReports],
  (reports) => reports,
);

export const fetchAllConnections = createSelector(
  [getAdminFlattenedConnections],
  (connections): any =>
    connections.filter(
      (connection: any) =>
        connection.status === ConnectionStatus.ALREADY_MADE ||
        connection.status === ConnectionStatus.ACCEPTED ||
        connection.status === ConnectionStatus.PENDING ||
        connection.status === ConnectionStatus.IGNORED ||
        connection.status === ConnectionStatus.UNMATCHED,
    ),
);

export const getDashboardConnections = createSelector(
  [getAdminFlattenedConnections],
  (connections): any =>
    connections
      .filter(
        (connection: any) =>
          connection.status === ConnectionStatus.ACCEPTED ||
          connection.status === ConnectionStatus.PENDING ||
          connection.status === ConnectionStatus.IGNORED ||
          connection.status === ConnectionStatus.UNMATCHED,
      )
      .sort((a: any, b: any) => {
        return a.dateInitiated > b.dateInitiated ? 1 : -1;
      }),
);

export const fetchAllPendingConnections = createSelector(
  [getAdminFlattenedConnections],
  (connections) =>
    connections.filter((connection: any) => connection.status === ConnectionStatus.PENDING),
);

export const fetchAllAcceptedAndUnmatchedConnections = createSelector(
  [getAdminFlattenedConnections],
  (connections) =>
    connections.filter(
      (connection: any) =>
        connection.status === ConnectionStatus.ACCEPTED ||
        connection.status === ConnectionStatus.UNMATCHED,
    ),
);

export const getAcceptedConnections = createSelector([getFlattenedConnections], (connections) =>
  connections
    .filter((connection: any) => connection.status === ConnectionStatus.ACCEPTED)
    .sort((a: any, b: any) => {
      const newestEventA =
        a.initiatorLastEvent > a.receiverLastEvent
          ? new Date(a.initiatorLastEvent).getTime()
          : new Date(a.receiverLastEvent).getTime();
      const newestEventB =
        b.initiatorLastEvent > b.receiverLastEvent
          ? new Date(b.initiatorLastEvent).getTime()
          : new Date(b.receiverLastEvent).getTime();

      return newestEventB - newestEventA;
    }),
);

export const getRumiConnections = createSelector([getFlattenedConnections], (connections) =>
  connections
    .filter((connection: any) => connection.status === ConnectionStatus.ADMIN)
    .sort((a: any, b: any) => {
      const newestEventA =
        a.initiatorLastEvent > a.receiverLastEvent
          ? new Date(a.initiatorLastEvent).getTime()
          : new Date(a.receiverLastEvent).getTime();
      const newestEventB =
        b.initiatorLastEvent > b.receiverLastEvent
          ? new Date(b.initiatorLastEvent).getTime()
          : new Date(b.receiverLastEvent).getTime();

      return newestEventB - newestEventA;
    }),
);

export const getPendingConnections = createSelector([getFlattenedConnections], (connections) =>
  connections.filter((connection: any) => connection.status === ConnectionStatus.PENDING),
);

export const getPendingIncomingWaves = createSelector(
  [getFlattenedConnections, getLoggedInUser],
  (connections, user) =>
    connections.filter(
      (connection: any) =>
        connection.status === ConnectionStatus.PENDING &&
        connection.receiver.id === user.id &&
        (connection.initiator.status === ProfileStatus.ACTIVE ||
          connection.initiator.status === ProfileStatus.MIGRATING),
    ),
);

export const getPendingOutgoingWaves = createSelector(
  [getFlattenedConnections, getLoggedInUser],
  (connections, user) =>
    connections.filter(
      (connection: any) =>
        connection.status === ConnectionStatus.PENDING &&
        connection.initiator.id === user.id &&
        (connection.receiver.status === ProfileStatus.ACTIVE ||
          connection.receiver.status === ProfileStatus.MIGRATING),
    ),
);

export const getFlattenedMessages = (state: RootState) =>
  Object.values(state.message.messages).flat();

export const getFlattenedAdminMessages = (state: RootState) =>
  Object.values(state.admin.messages).flat();

export const getMessagesForConnection = createSelector(
  [getFlattenedMessages, (state: any, connectionId: number) => connectionId],
  (messages, connectionId) => {
    return messages.filter((message: any) => message.connectionId === connectionId);
  },
);

export const getMessagesForAdminConnection = createSelector(
  [getFlattenedAdminMessages, (state: any, connectionId: number) => connectionId],
  (messages, connectionId) => {
    return messages.filter((message: any) => message.connectionId === connectionId);
  },
);

export const getActiveConnections = createSelector([getFlattenedConnections], (connections: any) =>
  connections.filter((conn: any) => conn.status === ConnectionStatus.ACCEPTED),
);

export const getActiveAndAdminConnections = createSelector(
  [getFlattenedConnections],
  (connections: any) =>
    connections.filter(
      (conn: any) =>
        conn.status === ConnectionStatus.ACCEPTED || conn.status === ConnectionStatus.ADMIN,
    ),
);

export const getConnectionBySlug = createSelector(
  [getActiveAndAdminConnections, (state: any, connectionSlug: string) => connectionSlug],
  (connections, connectionSlug) =>
    connections.find((connection: any) => connection.slug === connectionSlug),
);

export const getConnectionById = createSelector(
  [getActiveAndAdminConnections, (state: any, connectionId: number) => connectionId],
  (connections, connectionId) =>
    connections.find((connection: any) => connection.id === connectionId),
);

export const getAdminConnection = createSelector(
  [
    getActiveConnections,
    (state: any, adminId: number, userId: number) => {
      return [adminId, userId];
    },
  ],
  (connections, adminId, userId) =>
    connections.find(
      (connection: any) => connection.initiatorId === adminId && connection.receiverId === userId,
    ),
);

export const getUnreadConnections = createSelector(
  [getFlattenedConnections, getLoggedInUser],
  (connections, user) => {
    if (!user) {
      return [];
    }
    return connections.filter((connection: any) => {
      if (connection.initiatorId === user.id) {
        return !connection.initiatorReadAt && connection.status === ConnectionStatus.ACCEPTED;
      } else if (connection.receiverId === user.id) {
        return !connection.receiverReadAt && connection.status === ConnectionStatus.ACCEPTED;
      } else {
        return false;
      }
    });
  },
);

export const getUnreadMessages = createSelector(
  [getFlattenedConnections, getLoggedInUser],
  (connections, user) => {
    if (!user) {
      return [];
    }
    return connections.filter((connection: any) => {
      if (connection.initiatorId === user.id) {
        return (
          connection.initiatorReadAt && connection.initiatorReadAt < connection.receiverLastEvent
        );
      } else if (connection.receiverId === user.id) {
        return (
          connection.receiverReadAt && connection.receiverReadAt < connection.initiatorLastEvent
        );
      } else {
        return false;
      }
    });
  },
);

export const getFavorites = (state: RootState) => Object.values(state.users.favorites).flat();

export const hasOwnPlace = (state: RootState) => !!state.auth.user?.profile?.location?.housing;

export const getFlattenedEvents = (state: RootState) => Object.values(state.admin.events).flat();

export const getEventsByUserId = createSelector(
  [getFlattenedEvents, (_state: RootState, userId: number) => userId],
  (events, userId) =>
    orderBy(
      events.filter((event: any) => event.userId === userId),
      ['timestamp'],
      ['desc'],
    ),
);

export const getFlattenedUsers = (state: RootState) => Object.values(state.admin.users).flat();

export const getRegularUsers = createSelector([getFlattenedUsers], (users: any) =>
  users.filter((u: any) => u.roles?.includes(ROLE.USER)),
);

export const getPendingRegularUsers = createSelector([getFlattenedUsers], (users: any) =>
  users.filter(
    (user: any) => user.roles.includes(ROLE.USER) && user.status === ProfileStatus.PENDING,
  ),
);

export const getActiveRegularUsers = createSelector([getFlattenedUsers], (users: any) =>
  users.filter(
    (user: any) => user.roles.includes(ROLE.USER) && user.status === ProfileStatus.ACTIVE,
  ),
);

export const getPendingRegularUsersCTA = createSelector([getFlattenedUsers], (users: any) =>
  users.filter(
    (user: any) =>
      user.roles.includes(ROLE.USER) &&
      user.status === ProfileStatus.PENDING &&
      new Date().getTime() - 1000 * 60 * 60 * 24 <= new Date(user.registrationDate).getTime(),
  ),
);

export const getRegularUsersInLastWeek = createSelector([getFlattenedUsers], (users: any) =>
  users.filter(
    (user: any) =>
      user.roles.includes(ROLE.USER) &&
      new Date().getTime() - 1000 * 60 * 60 * 24 * 7 <= new Date(user.registrationDate).getTime(),
  ),
);

export const getRegularUsersInLastDay = createSelector([getFlattenedUsers], (users: any) =>
  users.filter(
    (user: any) =>
      user.roles.includes(ROLE.USER) &&
      new Date().getTime() - 1000 * 60 * 60 * 24 <= new Date(user.registrationDate).getTime(),
  ),
);

export const getAdminUsers = createSelector([getFlattenedUsers], (users: any) =>
  users.filter((u: any) => u.roles?.includes(ROLE.ADMIN)),
);

export const getAdminNotifications = (state: RootState) => state.admin.notifications;
export const getPotentialNotificationUsers = (state: RootState) =>
  state.admin.potentialNotificationUsers;

export const getAdminUsersToFilter = createSelector([getAdminUsers], (users: any) => {
  const administrators = users.map((user) => {
    return { label: user.name, value: user.id };
  });

  administrators.unshift({ label: 'Any', value: -1 });

  return administrators;
});

export const getCurrentUser = (state: RootState) => state.auth.user;

export const getIsAdmin = createSelector([getCurrentUser], (user) =>
  user.roles?.includes(ROLE.ADMIN),
);

export const isProduction = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === 'production';
};

export const isStaging = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === 'test';
};

export const getAnalyticUsers = (state: RootState) => analyticsMath(state.analytics.users);

export const getAnalyticMessages = (state: RootState) => analyticsMath(state.analytics.messages);

export const getAnalyticWaves = (state: RootState) => analyticsMath(state.analytics.waves);

export const getAnalyticCalendar = (state: RootState) => state.analytics.calendar;
