import { logout } from 'actions/auth';
import Modal from 'components/Common/Modal';
import { history } from 'helpers/history';
import React from 'react';
import { GrLogout } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

const SignOutModal = ({ show, onHide, children }) => {
  const dispatch: AppDispatch = useDispatch();

  const logOut = () => {
    dispatch(logout()).then(() => {
      history.replace('/login');
    });
  };

  return (
    <>
      {children}
      <Modal
        show={show}
        hideAction={() => onHide(false)}
        onSecondary={() => onHide(false)}
        onConfirm={logOut}
        icon={(className) => <GrLogout className={className} />}
        title={'Sign out'}
        confirmLabel={'Yes, sign out'}
        cancelLabel={'Cancel'}
      >
        <div className="space-y-4">
          <p className="text-gray-600">Are you sure you want to sign out?</p>
        </div>
      </Modal>
    </>
  );
};

export default SignOutModal;
