import * as React from 'react';
import { SliderItem, GetHandleProps, GetTrackProps } from 'react-compound-slider';

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface IHandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

export const Handle: React.FC<IHandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{
      left: `${percent}%`,
      position: 'absolute',
      marginLeft: `${percent && percent < 20 ? -7 : -24 * (percent / 100)}px`,
      marginTop: '-7px',
      zIndex: 2,
      width: 28,
      height: 28,
      cursor: 'pointer',
      borderRadius: '50%',
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
      background: 'linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%)',
      backgroundColor: 'rgb(0, 88, 204)',
    }}
    {...getHandleProps(id)}
  />
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

export const Track: React.FC<ITrackProps> = ({ source, target, getTrackProps }) => (
  <div
    style={{
      position: 'absolute',
      height: 14,
      zIndex: 1,
      background: 'linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%)',
      backgroundColor: 'rgb(0, 88, 204)',
      borderRadius: 7,
      cursor: 'pointer',
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
);
