import React from 'react';
import { FieldErrors } from 'react-hook-form';
import classNames from 'classnames';

export type FormFieldProps = {
  name: string;
  label?: string;
  errors?: FieldErrors<any>;
  className?: string;
  [x: string]: any;
};

const FormField = React.forwardRef<any, FormFieldProps>(
  ({ name, label, errors, className, ...rest }, ref) => {
    const inputClassName = classNames(
      className,
      errors && errors[name] && 'border-red-500',
      'placeholder-gray-400 text-md focus:placeholder-gray-500 block mt-1 px-3 py-2 w-full bg-white focus:bg-white border-2 border-gray-300 focus:border-gray-600 rounded-lg focus:outline-none shadow-md',
    );
    return (
      <>
        {label && (
          <label htmlFor={name} className="text-gray-600">
            {label}
          </label>
        )}
        <input className={inputClassName} name={name} id={name} {...rest} ref={ref} />
        {errors && errors[name] && <div className="text-red-400">{errors[name].message}</div>}
      </>
    );
  },
);

export default FormField;
