import { storyGender } from 'actions/onboarding';
import { pauseAccount, unpauseAccount } from 'actions/user';
import FormField from 'components/Common/FormField';
import Modal from 'components/Common/Modal';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import SettingsLayout from 'components/Layout/SettingsLayout';
import { format, parseISO } from 'date-fns';
import { messageOnlyToast } from 'helpers/toasts';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineMail } from 'react-icons/ai';
import { IoIosPause } from 'react-icons/io';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import api from 'services/api.service';
import { Gender } from 'types';
import { authMe, changeEmail, updatePassword } from '../../actions/auth';
import { RootState } from '../../reducers';
import { AppDispatch } from '../../store';
import SignOutModal from './SignOutModal';

const genderOptions = [
  { value: Gender.MALE, label: 'Male' },
  { value: Gender.FEMALE, label: 'Female' },
  { value: Gender.NONBINARY, label: 'Non-Binary' },
];

type ChangePasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

const AccountSettings = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);

  const {
    register: changeEmailRegister,
    handleSubmit: handleSubmitChangeEmail,
    formState: { errors: changeEmailErrors },
  } = useForm<{
    newEmail: string;
  }>();

  const {
    register: pauseRegister,
    handleSubmit: handleSubmitPause,
    formState: { errors: pauseErrors },
  } = useForm<{
    pauseReason: string;
  }>();

  const {
    register: changePasswordRegister,
    handleSubmit: handleSubmitChangePassword,
    formState: { errors: changePasswordErrors },
  } = useForm<ChangePasswordForm>();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = useState('');
  const [changeEmailMessage, setChangeEmailMessage] = useState('');
  const dispatch: AppDispatch = useDispatch();

  const reactSelectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      marginTop: 4,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? '#6B7280' : '#9CA3AF',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      fontSize: 16,
      background: 'white',
      outline: 'none',
      borderWidth: 2,
      borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
      borderRadius: 8,
      boxShadow: '0 4px 2px -2px rgba(0,0,0,0.1)',
      '&:hover': {},
      padding: 2,
      marginTop: 1,
      marginBottom: 10,
    }),
  };

  const onCancelChangeEmail = async () => {
    setLoading(true);
    await api
      .delete('/auth/change-email', { data: { email: currentUser.newEmail } })
      .then(async () => {
        toast.success('Successfully canceled your email change request');
        await dispatch(authMe());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeEmail = async ({ newEmail }: { newEmail: string }) => {
    setChangeEmailMessage('');
    setLoading(true);

    try {
      const emailResultSuccessMessage = await dispatch(changeEmail(newEmail));
      await dispatch(authMe());
      setShowChangeEmailModal(false);
      toast.success(emailResultSuccessMessage);
    } catch (e: any) {
      setChangeEmailMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async ({ newPassword, confirmPassword }: ChangePasswordForm) => {
    setChangePasswordMessage('');
    if (newPassword !== confirmPassword) {
      return setChangePasswordMessage('Passwords do not match');
    }

    setLoading(true);

    try {
      await dispatch(updatePassword(newPassword));
      setShowChangePasswordModal(false);
      toast.success('You successfully changed your password!');
    } catch (error: any) {
      setChangePasswordMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePause = async ({ pauseReason }: { pauseReason: string }) => {
    setLoading(true);
    try {
      await dispatch(pauseAccount(pauseReason));
      setShowPauseModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnpauseAccount = async () => {
    setLoading(true);
    try {
      await dispatch(unpauseAccount());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGender = async (e) => {
    setLoading(true);
    try {
      await dispatch(storyGender(e.value));

      toast.success('Your account settings have been saved.');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your changes have not been saved. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsLayout title="My Account">
      <>
        <div className="flex flex-col content-start max-w-2xl space-y-10 select-none justify-items-auto">
          <p>
            If you need to change any information below, please call us at{' '}
            <b className="whitespace-nowrap">651-695-5805</b>.
          </p>
          <div className="space-y-2">
            <p className="text-lg font-semibold text-gray-700">PERSONAL INFO</p>
            <div className="flex space-x-2">
              <div className="w-full">
                <FormField
                  label="First name"
                  type="text"
                  name="firstName"
                  placeholder={currentUser.firstName}
                  disabled
                  data-hj-allow
                />
              </div>
              <div className="w-full">
                <FormField
                  label="Last name"
                  type="text"
                  name="lastName"
                  placeholder={currentUser.lastName}
                  disabled
                  data-hj-allow
                />
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="w-full">
                <FormField
                  label="Birthday"
                  name="birthDate"
                  value={
                    currentUser.birthDate && format(parseISO(currentUser.birthDate), 'MM/dd/yyyy')
                  }
                  className="text-gray-400"
                  disabled
                  data-hj-allow
                />
              </div>
              <div className="w-full">
                <label className="text-gray-600">Gender</label>
                <Select
                  defaultValue={genderOptions.find((ref) =>
                    isEqual(ref.value, currentUser.profile?.gender),
                  )}
                  isDisabled={loading}
                  isSearchable={false}
                  options={genderOptions}
                  name="gender"
                  styles={reactSelectStyles}
                  onChange={(e) => {
                    handleGender(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <p className="mb-2 text-lg font-semibold text-gray-700">CONTACT INFO</p>
            <div className="space-y-2">
              <div>
                <MaskedInput
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className="text-gray-400"
                  value={currentUser.phone}
                  render={(ref, props) => (
                    <FormField
                      ref={(input) => ref(input)}
                      label="Phone number"
                      name="phone"
                      disabled
                      data-hj-allow
                      {...props}
                    />
                  )}
                />
              </div>
              <div>
                <FormField
                  label="Email address"
                  type="email"
                  name="email"
                  placeholder={currentUser.email}
                  disabled
                  data-hj-allow
                />
                {currentUser.newEmail && (
                  <div className="mt-2">
                    <p className="text-red-500">
                      Pending email change:{' '}
                      <span className="mr-1 font-semibold">{currentUser.newEmail}</span>{' '}
                      <button
                        onClick={onCancelChangeEmail}
                        className="button button-subtle button-small"
                      >
                        Cancel
                      </button>
                    </p>
                    <p>Check your inbox to verify your new email address.</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <p className="mb-2 text-lg font-semibold text-gray-700">ACCOUNT SETTINGS</p>
            <div className="space-y-2">
              <button
                className="w-full mb-0 bg-white button button-secondary"
                onClick={() => setShowChangeEmailModal(true)}
              >
                Change email
              </button>
              <button
                className="w-full button button-secondary"
                onClick={() => setShowChangePasswordModal(true)}
              >
                Change password
              </button>
              {currentUser.profile.status === 'PAUSED' ? (
                <button className="w-full button button-secondary" onClick={handleUnpauseAccount}>
                  Unpause account
                </button>
              ) : (
                <button
                  className="w-full button button-secondary"
                  onClick={() => setShowPauseModal(true)}
                >
                  Pause account
                </button>
              )}
              <SignOutModal show={showLogoutModal} onHide={setShowLogoutModal}>
                <button
                  className="w-full button button-primary"
                  onClick={() => setShowLogoutModal(true)}
                >
                  Sign out
                </button>
              </SignOutModal>
            </div>
          </div>
        </div>

        <Modal
          show={showChangePasswordModal}
          hideAction={() => setShowChangePasswordModal(false)}
          onSecondary={() => setShowChangePasswordModal(false)}
          loading={loading}
          onConfirm={() => handleSubmitChangePassword(handleChangePassword)()}
          title={'Change password'}
          confirmLabel="Save password"
          icon={(className) => <RiLockPasswordLine className={className} />}
          thin
        >
          <div className="space-y-4">
            <p className="text-gray-600">Change your password using the form below.</p>
            <div className="w-full">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitChangePassword(handleChangePassword)();
                }}
                noValidate
              >
                <div>
                  <FormField
                    label="Password"
                    type="password"
                    {...changePasswordRegister('newPassword', {
                      required: 'Please enter a new password',
                      minLength: {
                        value: 6,
                        message: 'Please enter at least 6 characters',
                      },
                    })}
                    errors={changePasswordErrors}
                    data-hj-allow
                  />
                </div>
                <div className="mt-4">
                  <FormField
                    label="Confirm Password"
                    type="password"
                    {...changePasswordRegister('confirmPassword', {
                      required: 'Please confirm your new password',
                      minLength: {
                        value: 6,
                        message: 'Please enter at least 6 characters',
                      },
                    })}
                    errors={changePasswordErrors}
                    data-hj-allow
                  />
                </div>
                <button type="submit"></button>
              </form>
            </div>
            {changePasswordMessage && (
              <StatusMessage type={StatusType.ERROR} message={changePasswordMessage} />
            )}
          </div>
        </Modal>
        <Modal
          show={showChangeEmailModal}
          hideAction={() => setShowChangeEmailModal(false)}
          onSecondary={() => setShowChangeEmailModal(false)}
          loading={loading}
          onConfirm={() => handleSubmitChangeEmail(handleChangeEmail)()}
          icon={(className) => <AiOutlineMail className={className} />}
          confirmLabel={'Save email'}
          title={'Change email'}
        >
          <div className="space-y-4">
            {currentUser.newEmail ? (
              <div className="mt-2">
                <p className="text-red-500">
                  Pending email change:
                  <br />
                  <span className="mr-1 font-semibold">{currentUser.newEmail}</span>{' '}
                  <button
                    onClick={onCancelChangeEmail}
                    className="button button-subtle button-small"
                  >
                    Cancel
                  </button>
                </p>
                <p>Check your inbox to verify your new email address.</p>
              </div>
            ) : (
              <>
                <p className="text-gray-600">Change your email address using the form below.</p>
                <div className="w-full">
                  <form noValidate onSubmit={handleSubmitChangeEmail(handleChangeEmail)}>
                    <FormField
                      label="Email"
                      type="email"
                      {...changeEmailRegister('newEmail', {
                        required: 'Please enter your new email address',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Please enter a valid email address',
                        },
                      })}
                      disabled={loading}
                      placeholder={currentUser.email}
                      errors={changeEmailErrors}
                      data-hj-allow
                    />
                  </form>
                </div>
                {changeEmailMessage && (
                  <StatusMessage type={StatusType.ERROR} message={changeEmailMessage} />
                )}
              </>
            )}
          </div>
        </Modal>
        <Modal
          show={showPauseModal}
          hideAction={() => setShowPauseModal(false)}
          onSecondary={() => setShowPauseModal(false)}
          onConfirm={() => handleSubmitPause(handlePause)()}
          icon={(className) => <IoIosPause className={className} />}
          title={'Pause account'}
          loading={loading}
          confirmLabel={'Yes, pause my account'}
          cancelLabel={'No, cancel'}
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              Are you sure you want to pause your account? You will not be visible by other users on
              the site until you unpause your account.
            </p>
            <div className="flex flex-col">
              <div className="w-full">
                <form noValidate onSubmit={handleSubmitPause(handlePause)}>
                  <label className="text-gray-600" htmlFor="pauseReason">
                    Reason for pausing your account:
                    <textarea
                      className="flex flex-1 w-full px-4 py-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:border-transparent focus:outline-none focus:ring-blue-500 focus:ring-2"
                      {...pauseRegister('pauseReason', {
                        required: 'Please enter the reason for pausing your account',
                      })}
                      rows={3}
                      data-hj-allow
                    ></textarea>
                    {pauseErrors && pauseErrors['pauseReason'] && (
                      <div className="text-red-400">{pauseErrors['pauseReason'].message}</div>
                    )}
                  </label>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </SettingsLayout>
  );
};

export default AccountSettings;
