import { careHours } from 'actions/onboarding';
import Modal from 'components/Common/Modal';
import PillsInput from 'components/Common/PillsInput';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { CareHours } from 'types';

const careHoursOptions = [
  { label: 'Less than 10 hours', value: CareHours.LESS_THAN_10_HOURS },
  { label: '10-20 hours', value: CareHours.BETWEEN_10_AND_20_HOURS },
  { label: '20-30 hours', value: CareHours.BETWEEN_20_AND_30_HOURS },
  { label: '30-40 hours', value: CareHours.BETWEEN_30_AND_40_HOURS },
  { label: '40+ hours', value: CareHours.MORE_THAN_40 },
  { label: "I'm not sure", value: CareHours.NOT_SURE },
  { label: 'No preference', value: CareHours.NO_PREFERENCE },
];

const ProfileAboutMeCareHours = ({ profile }) => {
  const [saving, setSaving] = useState(false);
  const [showCareHoursModal, setShowCareHoursModal] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [selectedHours, setSelectedHours] = useState<CareHours[]>([]);
  const dispatch: AppDispatch = useDispatch();

  const title =
    profile.type === 'CAREGIVER'
      ? 'How many hours of support can you provide per week?'
      : 'How much support do you get during the week?';

  let options = careHoursOptions.filter((o) => {
    if (profile.type === 'CAREGIVER') {
      return true;
    } else {
      return o.value !== CareHours.NO_PREFERENCE;
    }
  });

  useEffect(() => {
    resetDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const resetDefault = () => {
    const references: any[] = careHoursOptions.map((ref) => ref.value);
    const selectedReferences = references.filter((ref) => profile?.careInfo?.hours?.includes(ref));

    setSelectedHours(selectedReferences);
  };

  const cancel = () => {
    resetDefault();
    setShowCareHoursModal(false);
  };

  const onChangeOptions = (options: string[]) => {
    setSelectedHours(options as CareHours[]);
  };

  const submitHomeReqs = async () => {
    const hours = selectedHours[0];
    setGenericError('');

    try {
      await dispatch(careHours(hours));
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setSaving(false);
      resetDefault();
      setShowCareHoursModal(false);
    }
  };

  return (
    <>
      <button
        className="button button-flat absolute right-3 top-1"
        onClick={() => setShowCareHoursModal(true)}
      >
        Edit
      </button>

      <Modal
        show={showCareHoursModal}
        hideAction={() => setShowCareHoursModal(false)}
        onSecondary={() => cancel()}
        onConfirm={() => submitHomeReqs()}
        title={`Care hours`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <p className="text-xl">{title}</p>
            <div className="w-full">
              <PillsInput
                values={selectedHours}
                onChange={onChangeOptions}
                options={options}
                selectOne={true}
                light={true}
              />

              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMeCareHours;
