import { getMyConnections, ignoreWave, sendWave } from 'actions/connection';
import { hideModal, ignoreWaveModal, waveMessageModal } from 'actions/modals';
import { addFavoriteUser, deleteFavoriteUser, reportUser } from 'actions/user';
import ImageUploader from 'components/Common/ImageUploader';
import Modal from 'components/Common/Modal';
import Spinner from 'components/Common/Spinner';
import SupportModal from 'components/Settings/SupportModal';
import { messageOnlyToast, waveToast } from 'helpers/toasts';
import {
  usePotentialConnection,
  usePotentialConnectionStatus,
} from 'helpers/usePotentialConnectionStatus';
import { usePotentialFavorite } from 'helpers/usePotentialFavorite';
import { resizeEvent } from 'helpers/utils';
import { ConnectionStatus, WaveStatus } from 'models/connectionStatus';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineFileImage } from 'react-icons/ai';
import { BiLeftArrowAlt, BiPencil, BiUser } from 'react-icons/bi';
import { BsDot, BsHouse, BsHouseFill } from 'react-icons/bs';
import { FaCheck, FaRegSmile, FaTimes } from 'react-icons/fa';
import { IoIosHelpCircleOutline, IoMdHand } from 'react-icons/io';
import { MdReport } from 'react-icons/md';
import { TiStarFullOutline, TiStarOutline } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { getCurrentUser, getIsAdmin } from 'selectors';
import { AppDispatch } from 'store';
import { GenderProfile } from 'types';
import { history } from '../../helpers/history';
import userService from '../../services/user.service';
import Header from '../Common/Header';
import './Profile.scss';
import ProfileAboutMe from './ProfileAboutMe';

const ProfileNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full space-y-4 text-gray-500 select-none">
      <div>Sorry, we couldn't find that user.</div>
      <div>
        <Link className="font-bold text-blue-600 underline" to="/connections">
          View Connections
        </Link>{' '}
        or{' '}
        <Link className="font-bold text-green-500 underline" to="/search">
          Search
        </Link>
      </div>
    </div>
  );
};

const Profile = ({ isTour = false }) => {
  // const [verifyEmailSent, setVerifyEmailSent] = useState(false);
  const { slug }: any = useParams();
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [profile, setProfile] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showWaveModal, setShowWaveModal] = useState(false);
  const currentUser: any = useSelector(getCurrentUser);
  const isAdmin = useSelector(getIsAdmin);
  const dispatch: AppDispatch = useDispatch();

  const potentialConnection = usePotentialConnection(profile);
  const potentialConnectionStatus = usePotentialConnectionStatus(profile);
  const potentialFavorite = usePotentialFavorite(profile);

  const isMyProfile = !isTour && (!slug || currentUser.slug === slug);
  const {
    register: reportUserRegister,
    handleSubmit: handleSubmitReportUser,
    formState: { errors: reportUserErrors },
  } = useForm<{
    reportReason: string;
  }>();

  // const handleVerifyEmail = () => {
  //   dispatch(resendVerification(currentUser.email));
  //   setVerifyEmailSent(true);
  // };

  const tourProfile = () => {
    setProfile({
      age: 25,
      bio: 'Hello! My name is John and I love to cook. Feel free to message me if you are interested in becoming my roommate!',
      careInfo: { hours: 'NO_PREFERENCE', tasks: Array(1) },
      city: 'Minneapolis',
      drinking: true,
      gender: 'MALE',
      hasHousing: false,
      id: 0,
      location: {
        lat: '44.9479791',
        lng: '-93.2935778',
        radius: 50,
        city: 'Minneapolis',
        zip: '55408',
      },
      name: 'John',
      pets: ['NONE'],
      preferences: { gender: 'NO_PREFERENCE' },
      slug: 'abcdefghijklmnopqrstuvwxyz',
      smoking: false,
      verified: true,
    });
    setLoading(false);
    resizeEvent();
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const profileId = slug || currentUser.slug;
      try {
        const res = await userService.getUserProfileById(profileId);
        if (!isMyProfile && !isAdmin) {
          userService.viewProfile(res.data.id);
        }
        setProfile(res.data);
      } finally {
        setLoading(false);
        resizeEvent();
      }
    };
    if (!isTour) {
      fetchProfile();
    } else {
      tourProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, currentUser]);

  const waveAtUser = () => {
    dispatch(sendWave(profile.id)).then((res) => {
      setShowWaveModal(false);
      if (res && !res.connected) {
        dispatch(
          waveMessageModal({
            name: profile.name,
            id: profile.id,
            connectionId: res.connectionId,
          }),
        );
      }
      toast.remove();
      waveToast(`You waved at ${profile.name}!`);
    });
  };

  const ignoreWaveFromUser = () => {
    dispatch(
      ignoreWaveModal(profile, () => {
        dispatch(ignoreWave(profile.id)).then(() => {
          waveToast(`You passed on ${profile.name}'s wave`);
          dispatch(getMyConnections());
          dispatch(hideModal());
        });
      }),
    );
  };

  const favoriteUser = async () => {
    await dispatch(addFavoriteUser(profile)).then(
      (response) => {
        toast.success(response);
      },
      (error) => {
        if (error.statusCode === 409) {
          messageOnlyToast(error.message);
        } else {
          messageOnlyToast('Please refresh and try again.');
        }
      },
    );
  };

  const unfavoriteUser = async () => {
    await dispatch(deleteFavoriteUser(profile.id)).then(
      (response) => {
        toast.success(`${profile.name} is removed from your favorites`);
      },
      (error) => {
        messageOnlyToast('Please refresh and try again.');
      },
    );
  };

  const handleReportUser = ({ reportReason }: { reportReason: string }) => {
    dispatch(reportUser(reportReason, profile.id))
      .then(() => {
        setShowReportModal(false);
        messageOnlyToast(`You reported ${profile.name}`);
      })
      .catch((error) => {
        setShowReportModal(false);
        messageOnlyToast(error);
      });
  };

  const waveButton = (id) => {
    return (
      <button
        id={id}
        className="w-full text-lg button button-wave"
        onClick={() => {
          if (isMyProfile) {
            toast.remove();
            waveToast(`You waved at yourself!`);
          } else {
            setShowWaveModal(true);
          }
        }}
      >
        <span role="img" className="mr-3 -ml-3 text-3xl animate-wave" aria-label="wave emoji">
          👋
        </span>
        Wave
      </button>
    );
  };
  const waveBackButton = () => {
    return (
      <>
        <button
          className="w-full text-lg transition-all button button-wave bg-gradient-to-br from-blue-500 to-blue-900 hover:from-blue-900 hover:to-blue-500"
          onClick={() => {
            setShowWaveModal(true);
          }}
        >
          <span role="img" className="mr-2 text-3xl animate-wave" aria-label="wave emoji">
            👋
          </span>
          Wave back
        </button>

        {potentialConnectionStatus !== ConnectionStatus.IGNORED && (
          <button
            className="w-full button button-danger whitespace-nowrap"
            onClick={() => {
              ignoreWaveFromUser();
            }}
          >
            <FaTimes className="mr-1" /> Pass
          </button>
        )}
      </>
    );
  };
  const alreadyWavedButton = () => {
    return (
      <button
        className="w-full text-lg bg-yellow-500 button button-wave hover:bg-yellow-600"
        onClick={() => {
          toast.remove();
          waveToast(`You waved at ${profile.name}! You’ll be notified when they wave back.`);
        }}
      >
        <span role="img" className="mr-2 animate-wave" aria-label="thumbs up emoji">
          👍
        </span>
        Waved
      </button>
    );
  };
  const connectedButton = () => {
    return (
      <>
        <p className="flex items-center justify-center w-full font-semibold text-center text-green-600">
          <FaCheck size={16} className="mb-0.5 mr-1" /> Connected with {profile.name}!
        </p>
        <button
          className="w-full text-lg bg-green-500 button button-wave whitespace-nowrap hover:bg-green-600"
          onClick={() => history.push('/connections/messages/' + potentialConnection.slug)}
        >
          <span role="img" className="mr-2 animate-wave" aria-label="message emoji">
            💬
          </span>
          Send message
        </button>
      </>
    );
  };

  const reportButton = () => {
    return (
      <div className="flex justify-center w-full">
        <button
          className="text-sm text-gray-800 hover:underline whitespace-nowrap"
          onClick={() => setShowReportModal(true)}
        >
          Report this user
        </button>
      </div>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (!profile) {
    return <ProfileNotFound />;
  }

  return (
    <>
      <Helmet>
        {profile && <title>{isMyProfile ? 'My' : `${profile?.name}'s`} Profile | Rumi</title>}
      </Helmet>
      <div className="flex flex-col h-full overflow-y-auto profile">
        <Header className="header header-profile h-96 sm:h-64">
          <div className="container relative flex p-4 mx-auto h-96 sm:h-64 lg:px-8">
            <div className="start-col">
              <button
                className="absolute z-10 flex items-center justify-center p-3 font-semibold bg-blue-600 border-2 border-white rounded-full md:p-2"
                onClick={() => history.goBack()}
              >
                <BiLeftArrowAlt size={28} />
              </button>
            </div>
            <div className="center-col hidden flex-col justify-end leading-8 space-y-0.5 sm:flex">
              <div className="flex items-center space-x-2">
                <h1>{profile?.name}</h1>
                {profile.verified && (
                  <span className="flex flex-row items-center">
                    <span className="flex flex-row items-center py-1 pl-3 pr-4 text-sm text-white bg-blue-400 rounded-full">
                      <FaCheck className="mr-2" />
                      Verified
                    </span>
                  </span>
                )}
              </div>
              <span className="flex items-center space-x-1">
                <FaRegSmile size={20} className="inline ml-0.5 mr-0.5 text-white" />
                <span>{GenderProfile[profile.gender]}</span> <BsDot size={20} />
                <span>{profile.age} years old</span>
              </span>

              <span className="flex items-center">
                {profile.hasHousing ? (
                  <>
                    <BsHouseFill className="ml-0.5 mr-1.5" size={20} />
                    Has an extra bedroom available {profile.city && `in ${profile.city}`}
                  </>
                ) : (
                  <>
                    <BsHouse className="ml-0.5 mr-1.5" size={20} />
                    Looking for housing {profile.city && `near ${profile.city}`}
                  </>
                )}
              </span>
            </div>
            <div className="end-col"></div>

            {/* Mobile view of profile picture and name */}
            <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full sm:hidden">
              <SwipeableViews className="h-full" slideClassName="h-full">
                <div className="flex items-center justify-center h-full overflow-y-hidden bg-white">
                  <img
                    className="object-contain w-full"
                    src={profile.image || require('../../assets/default_avatar.png').default}
                    alt="profile"
                  />
                  {isMyProfile && (
                    <button
                      onClick={() => setShowImageModal(true)}
                      className="absolute z-10 flex items-center px-4 py-2 font-semibold text-white bg-blue-600 border-2 border-white rounded-full bottom-8 right-4 focus:outline-none focus:ring-2"
                    >
                      Edit <BiPencil className="ml-2" size={20} />
                    </button>
                  )}
                </div>
              </SwipeableViews>
            </div>
          </div>
        </Header>
        <div className="relative select-none sm:hidden">
          <div className="z-10 px-6 py-4 -mt-4 space-y-3 bg-white border-t-2 rounded-r-3xl rounded-t-3xl">
            <div className="flex items-center space-x-2">
              <h1>{profile?.name}</h1>
              {profile.verified && (
                <span className="flex flex-row items-center">
                  <span className="flex flex-row items-center py-1 pl-3 pr-4 text-sm text-white bg-blue-400 rounded-full">
                    <FaCheck className="mr-2" />
                    Verified
                  </span>
                </span>
              )}
            </div>
            <span className="flex items-center space-x-1">
              <FaRegSmile size={20} className="inline ml-0.5 mr-0.5" />
              <span>{GenderProfile[profile.gender]}</span>
              <BsDot size={20} />
              <span>{profile.age} years old</span>
            </span>

            <span className="flex items-center">
              {profile.hasHousing ? (
                <>
                  <BsHouseFill className="ml-0.5 mr-1.5" size={20} />
                  Has an extra bedroom available in {profile.city}
                </>
              ) : (
                <>
                  <BsHouse className="ml-0.5 mr-1.5" size={20} />
                  Looking for housing in {profile.city}
                </>
              )}
            </span>

            <div>
              {(() => {
                switch (potentialConnectionStatus) {
                  case WaveStatus.NONE:
                    return waveButton('waveButtonMobile');
                  case WaveStatus.SENT:
                    return alreadyWavedButton();
                  case WaveStatus.RECEIVED:
                    return waveBackButton();
                  case ConnectionStatus.ACCEPTED:
                    return connectedButton();
                  case ConnectionStatus.IGNORED:
                    return (
                      <>
                        {potentialConnection?.receiver?.id === currentUser.id ? (
                          <>
                            <div className="text-center text-red-600">Ignored wave</div>
                            {waveBackButton()}
                          </>
                        ) : (
                          alreadyWavedButton()
                        )}
                      </>
                    );
                }
              })()}

              {isMyProfile ? (
                <>
                  <button
                    className="w-full button button-secondary whitespace-nowrap"
                    onClick={() => history.push('/search')}
                  >
                    Continue searching
                  </button>
                  <button
                    className="w-full button button-secondary whitespace-nowrap"
                    onClick={() => history.push('/settings/preferences')}
                  >
                    Edit preferences
                  </button>
                </>
              ) : (
                <>
                  {potentialFavorite ? (
                    <button
                      className="w-full text-gray-400 button button-subtle hover:text-gray-500 focus:text-gray-500 whitespace-nowrap"
                      onClick={() => unfavoriteUser()}
                    >
                      <span className="animate-wave" role="img" aria-label="star emoji">
                        <TiStarFullOutline className="mr-1 text-yellow-400" size={20} />
                      </span>
                      Favorited
                    </button>
                  ) : (
                    <button
                      className="w-full button button-secondary whitespace-nowrap"
                      onClick={() => favoriteUser()}
                      id="favoriteButtonMobile"
                    >
                      <span className="animate-wave" role="img" aria-label="star emoji">
                        <TiStarOutline className="mr-1" size={20} />
                      </span>
                      Favorite
                    </button>
                  )}
                  {reportButton()}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="border-t border-b">
          <div className="container flex mx-auto profile-nav lg:px-8">
            <div className="hidden start-col sm:block"></div>
            <div className="flex flex-row center-col">
              <div className="flex flex-row whitespace-nowrap">
                <button className={`tab active inline-flex items-center justify-center`}>
                  <BiUser className="mr-1.5" size={20} />
                  About me
                </button>
                {/* <button
              onClick={() => history.push('/profile/stories')}
              className={`tab ${
                history.location.pathname === '/profile/stories' ? 'active ' : ''
              }inline-flex items-center justify-center px-4`}
            >
              <BiBookHeart className="mr-1.5" size={20} />
              Stories
            </button> */}
              </div>
            </div>
            <div className="flex items-start justify-end end-col"></div>
          </div>
        </div>
        <div className="container flex flex-1 pb-8 mx-auto sm:px-4 lg:px-8">
          <div className="z-10 hidden -mt-48 space-y-3 start-col sm:block md:-mt-56">
            <div className="flex flex-wrap justify-center">
              <div className="relative w-full">
                <img
                  src={profile?.image || require('../../assets/default_avatar.png').default}
                  alt="..."
                  className="w-full h-auto align-middle bg-white border-none shadow-lg rounded-xl"
                />
                {isMyProfile && (
                  <button
                    onClick={() => setShowImageModal(true)}
                    className="absolute z-10 flex items-center px-4 py-2 font-semibold text-white bg-blue-600 border-2 border-white rounded-full bottom-3 right-3 focus:outline-none focus:ring-2"
                  >
                    Edit <BiPencil className="ml-2" size={20} />
                  </button>
                )}
              </div>
            </div>
            {/* {!isMyProfile && (
            <CompatibilityChecks
              locationCompatible={CompatibilityLevel.UNKNOWN}
              careCompatible={CompatibilityLevel.UNKNOWN}
              preferencesCompatible={CompatibilityLevel.UNKNOWN}
            />
          )} */}
            {(() => {
              switch (potentialConnectionStatus) {
                case WaveStatus.NONE:
                  return waveButton('waveButton');
                case WaveStatus.SENT:
                  return alreadyWavedButton();
                case WaveStatus.RECEIVED:
                  return waveBackButton();
                case ConnectionStatus.ACCEPTED:
                  return connectedButton();
                case ConnectionStatus.IGNORED:
                  return (
                    <>
                      {potentialConnection?.receiver?.id === currentUser.id ? (
                        <>
                          <div className="text-center text-red-600">Ignored wave</div>
                          {waveBackButton()}
                        </>
                      ) : (
                        alreadyWavedButton()
                      )}
                    </>
                  );
              }
            })()}
            {isMyProfile ? (
              <>
                <button
                  className="w-full button button-secondary whitespace-nowrap"
                  onClick={() => history.push('/search')}
                >
                  Continue searching
                </button>
                <button
                  className="w-full button button-secondary whitespace-nowrap"
                  onClick={() => history.push('/settings/preferences')}
                >
                  Edit preferences
                </button>
              </>
            ) : (
              <>
                {potentialFavorite ? (
                  <button
                    className="w-full text-gray-400 button button-subtle hover:text-gray-500 focus:text-gray-500 whitespace-nowrap"
                    onClick={unfavoriteUser}
                  >
                    <span className="animate-wave" role="img" aria-label="star emoji">
                      <TiStarFullOutline className="mr-1 text-yellow-400" size={20} />
                    </span>
                    Favorited
                  </button>
                ) : (
                  <button
                    className="w-full button button-secondary whitespace-nowrap"
                    onClick={favoriteUser}
                    id="favoriteButton"
                  >
                    <span className="animate-wave" role="img" aria-label="star emoji">
                      <TiStarOutline className="mr-1" size={20} />
                    </span>
                    Favorite
                  </button>
                )}
                {reportButton()}
              </>
            )}
          </div>
          <div className="mt-4 center-col">
            <Switch>
              <Route
                exact
                path="/profile"
                render={() => (
                  <>
                    <ProfileAboutMe isMyProfile={isMyProfile} profile={profile} />
                    {/* <ProfileStories /> // use when we add the stories feature */}
                  </>
                )}
              />
              <Route
                exact
                path="/profile/:id"
                render={() => (
                  <>
                    <ProfileAboutMe isMyProfile={isMyProfile} profile={profile} />
                    {/* <ProfileStories /> // use when we add the stories feature */}
                  </>
                )}
              />
            </Switch>
          </div>
          <div className="hidden end-col xl:block">
            <div className="max-w-full my-4 bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
              <div className="flex flex-col justify-between p-4 space-y-4 leading-normal">
                <div className="flex items-center justify-between text-xl font-bold">
                  Need help?
                </div>
                <p className="text-gray-700">
                  Support is standing by to help you find a roommate and get to living your best
                  life.
                </p>

                <SupportModal show={showSupportModal} onHide={setShowSupportModal}>
                  <button
                    className="flex flex-row items-center w-full button button-secondary"
                    onClick={() => setShowSupportModal(true)}
                  >
                    <IoIosHelpCircleOutline size={24} className="mr-1" />
                    Support
                  </button>
                </SupportModal>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showWaveModal}
        onConfirm={() => waveAtUser()}
        hideAction={() => setShowWaveModal(false)}
        onSecondary={() => setShowWaveModal(false)}
        loading={loading}
        icon={(className) => <IoMdHand className={className} />}
        lockOpen={true}
        title={
          potentialConnectionStatus === WaveStatus.NONE
            ? `Wave at ${profile.name}`
            : `Wave back at ${profile.name}`
        }
        confirmLabel={potentialConnectionStatus === WaveStatus.NONE ? 'Send wave' : 'Wave back'}
      >
        <div className="w-full">
          <p>
            {potentialConnectionStatus === WaveStatus.NONE
              ? `Sending a wave 👋 is a hello! If ${profile.name} waves back at you, you’ll be able to start a conversation.`
              : `By waving 👋 back at ${profile.name}, you'll be connected and can start chatting.`}
          </p>
        </div>
      </Modal>

      <Modal
        show={showImageModal}
        hideAction={() => setShowImageModal(false)}
        onSecondary={() => setShowImageModal(false)}
        loading={loading}
        title={'Upload profile picture'}
        icon={(className) => <AiOutlineFileImage className={className} />}
        lockOpen={true}
      >
        <ImageUploader
          onFinish={() => {
            setShowImageModal(false);
          }}
        />
      </Modal>

      <Modal
        show={showReportModal}
        hideAction={() => setShowReportModal(false)}
        onSecondary={() => setShowReportModal(false)}
        onConfirm={() => handleSubmitReportUser(handleReportUser)()}
        loading={loading}
        title={`Report ${profile.name}`}
        confirmLabel={'Send report'}
        icon={(className) => <MdReport className={className} />}
      >
        <div className="w-full">
          <p className="mb-4">
            If you have any issues with this person, please tell us what happened.
          </p>
          <p className="mb-4">
            If you are already connected, you will unmatch with them and you will not be able to see
            or talk to them again.
          </p>
          <form noValidate onSubmit={handleSubmitReportUser(handleReportUser)}>
            <label className="text-gray-600" htmlFor="reportReason">
              Reason for reporting:
              <textarea
                className="block w-full px-3 py-2 placeholder-gray-400 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                {...reportUserRegister('reportReason', {
                  required: `Please tell us your reason for reporting ${profile.name}.`,
                })}
                id="reportReason"
                rows={6}
                data-hj-allow
              />
              {reportUserErrors && reportUserErrors['reportReason'] && (
                <div className="text-red-400">{reportUserErrors['reportReason'].message}</div>
              )}
            </label>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
