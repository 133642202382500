import React from 'react';
import classNames from 'classnames';

export enum StatusType {
  ERROR = 'error',
  WARN = 'warn',
  SUCCESS = 'success',
}

export type StatusMessageProps = {
  type: StatusType;
  message: any;
  className?: string;
};

const StatusMessage = ({ message, type, className }: StatusMessageProps) => {
  const classes = classNames(className, 'p-2 text-white bg-red-500 rounded', {
    'bg-red-500': type === StatusType.ERROR,
    'bg-yellow-500': type === StatusType.WARN,
    'bg-green-500': type === StatusType.SUCCESS,
  });
  return <div className={classes}>{message}</div>;
};

export default StatusMessage;
