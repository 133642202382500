import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

export type CardProps = {
  className?: string;
  padding?: number;
  expanded?: boolean;
};

const Card: FunctionComponent<CardProps> = ({ children, className, padding, expanded }) => {
  const cardClass = classNames(
    expanded
      ? 'absolute bottom-0 overflow-y-auto rounded-none md:rounded-xl left-0 right-0 top-0'
      : 'rounded-xl mb-6',
    `md:relative w-full shadow-lg bg-white px-5 py-4 ${padding ? `sm:p-${padding}` : 'sm:p-8'}`,
    className,
  );

  return <div className={cardClass}>{children}</div>;
};

export default Card;
