import React, { useEffect, useState } from 'react';
import { RiLoader5Line } from 'react-icons/ri';

const Loading = () => {
  const [tooLong, setTooLong] = useState(false);
  useEffect((): any => {
    let mounted = true;
    setTimeout(() => mounted && setTooLong(true), 5000);
    return () => (mounted = false);
  }, []);

  return (
    <div className="bg-red h-screen-mobile flex flex-col items-center justify-center bg-transparent">
      <div className="flex flex-wrap justify-center select-none">
        <div className="w-52 h-20">
          <img width={200} src={require('../../assets/rumi.png').default} alt="Rumi logo" />
        </div>
      </div>
      <RiLoader5Line className="my-2 text-blue-500 animate-spin" size={40} />
      Loading...
      {tooLong && (
        <div className="mt-4">This is taking a little too long. Try refreshing the page.</div>
      )}
    </div>
  );
};

export default Loading;
