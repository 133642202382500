import { ProfileType } from 'models/profileType';
import React from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import OnboardingLayout from './Layout/OnboardingLayout';

const InProcessMode = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  return (
    <OnboardingLayout title={<>You found your new roommate! 🎉</>} childrenPadding="0">
      <>
        <div className="flex flex-col mt-16 text-white text-lg leading-8 space-y-8">
          <div>
            <div className="text-3xl mb-8">What's next?</div>
            <ol className="list-decimal list-inside space-y-8">
              <li>
                <b>Start looking for available housing options.</b>
                <div className="space-x-2">
                  You can use services like{' '}
                  <button
                    onClick={() => {
                      window.open('https://www.apartments.com/');
                    }}
                    className="underline cursor-pointer hover:text-gray-300 font-semibold"
                  >
                    Apartments.com
                    <HiExternalLink className="ml-1 inline" />
                  </button>
                  <span>or</span>
                  <button
                    onClick={() => {
                      window.open('https://www.apartmentfinder.com/');
                    }}
                    className="underline cursor-pointer hover:text-gray-300 font-semibold"
                  >
                    Apartment Finder
                    <HiExternalLink className="ml-1 inline" />
                  </button>{' '}
                  to find available housing.
                </div>
              </li>
              <li>
                <b>Set up your household agreement.</b>
                <div>Contact us to receive a sample agreement template.</div>
              </li>
              <li>
                <b>Work with your Rumi Connector to get your services approved.</b>
              </li>
              {currentUser.profile === ProfileType.SR && (
                <li>
                  <b>Only SR: Finish all training modules before intake.</b>
                </li>
              )}
              <li>
                <b>
                  Work with your Rumi Connector to finalize paperwork, pick a move-in date and sign
                  a lease.
                </b>
              </li>
              <li>
                <b>Move with your new roommate on move-in day!</b>
              </li>
            </ol>
          </div>
          <div className="text-2xl">
            <div className="text-3xl mt-8">
              Give us a call at <b>651-695-5805</b> if you have any questions or need help.
            </div>
          </div>
        </div>
      </>
    </OnboardingLayout>
  );
};

export default InProcessMode;
