import { finishTour } from 'actions/user';
import { history } from 'helpers/history';
import { isMobileOrTablet } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tour from 'reactour';
import { AppDispatch } from 'store';

const desktopSteps = [
  {
    content: (
      <>
        <p>Welcome to Rumi! 🎉</p>
        <p className="mt-2">Since you're new here, we're going to show you how things work.</p>
        <p className="mt-2">
          Press <b>NEXT</b> to start the tour.
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/profile');
    },
  },
  {
    selector: '#profile',
    content: 'Click here to view your profile.',
    action: (node) => {
      history.replace('/profile');
    },
  },

  {
    selector: '#editBioButton',
    content: (
      <>
        Click the <b>Edit</b> buttons to edit sections of your profile.
      </>
    ),
    action: (node) => {
      history.replace('/profile');
    },
  },
  {
    selector: '#search',
    content: 'Click here to start the search for your new roommate!',
    action: (node) => {
      history.replace('/search');
    },
  },
  {
    selector: '#searchFilters',
    content: (
      <>
        <p>Decide what you want in a roommate by editing your search preferences here.</p>
        <p className="mt-2">
          Go ahead, <b>try it</b>!
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/search');
    },
    stepInteraction: true,
  },
  {
    selector: '#firstSearchCard',
    content: 'Click on a user to view their profile.',
    action: (node) => {
      history.replace('/search');
    },
  },
  {
    selector: '#waveButton',
    content: (
      <>
        <p>
          If you find someone you would consider as a roommate, click the 👋 <b>Wave</b> button to
          send a wave!
        </p>

        <p className="mt-2">If they wave back, you can start a conversation.</p>
      </>
    ),
    action: (node) => {
      history.replace('/profile/abcdefghijklmnopqrstuvwxyz');
    },
  },
  {
    selector: '#favoriteButton',
    content: (
      <>
        <p>Not ready to wave yet? </p>
        <p className="mt-2">
          Click the ⭐️ <b>Favorite</b> button to save them for later!
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/profile/abcdefghijklmnopqrstuvwxyz');
    },
  },
  {
    selector: '#favorites',
    content: "Click here to view users you've favorited.",
    action: (node) => {
      history.replace('/favorites');
    },
  },

  {
    selector: '#connections',
    content: (
      <>
        <p>You can view your list of current connections here, and start a conversation!</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/messages');
    },
  },

  {
    selector: '#messages',
    content: (
      <>
        <p>Your list of connections and messages will live here.</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/messages');
    },
  },
  {
    selector: '#waveRequests',
    content: (
      <>
        <p>When someone new waves at you, you'll see them here.</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/requests');
    },
  },

  {
    selector: '#helpButton',
    content: (
      <>
        View this tour at any time by pressing <b>display help tour again</b> in the help menu.
      </>
    ),
    stepInteraction: true,
    action: (node) => {
      history.replace('/search');
    },
  },
];

const mobileSteps = [
  {
    content: (
      <>
        <p>Welcome to Rumi!</p>{' '}
        <p className="mt-2">
          Since you're new here, we're going to show you how to use the platform.
        </p>
        <p className="mt-2">
          Press <b>NEXT</b> to start the tour.
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/profile');
    },
  },
  {
    selector: '#profileMobile',
    content: 'Click here to view your profile and settings.',
    action: (node) => {
      history.replace('/settings');
    },
  },
  {
    selector: '#viewMyProfile',
    content: 'Click here to view your profile.',
    action: (node) => {
      history.replace('/settings');
    },
  },
  {
    selector: '#editBioButton',
    content: (
      <>
        Click the <b>Edit</b> buttons to edit sections of your profile.
      </>
    ),
    action: (node) => {
      history.replace('/profile');
    },
  },

  {
    selector: '#searchMobile',
    content: 'Click here to start the search for your new roommate!',
    action: (node) => {
      history.replace('/search');
    },
  },
  {
    selector: '#searchFiltersMobile',
    content: (
      <>
        <p>
          Refine your search for the perfect roommate by pressing <b>Change Preferences</b>.
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/search');
    },
  },

  {
    selector: '#firstSearchCard',
    content: 'Click on a user to view their profile.',
    action: (node) => {
      history.replace('/search');
    },
  },
  {
    selector: '#waveButtonMobile',
    content: (
      <>
        <p>
          If you find someone you would consider as a roommate, click the 👋 <b>Wave</b> button to
          send a wave!
        </p>

        <p className="mt-2">If they wave back, you can start a conversation.</p>
      </>
    ),
    action: (node) => {
      history.replace('/profile/abcdefghijklmnopqrstuvwxyz');
    },
  },
  {
    selector: '#favoriteButtonMobile',
    content: (
      <>
        <p>Not ready to wave yet? </p>
        <p className="mt-2">
          Click the ⭐️ <b>Favorite</b> button to save them for later!
        </p>
      </>
    ),
    action: (node) => {
      history.replace('/profile/abcdefghijklmnopqrstuvwxyz');
    },
  },
  {
    selector: '#favoritesMobile',
    content: "Click here to view users you've favorited.",
    action: (node) => {
      history.replace('/favorites');
    },
  },

  {
    selector: '#connectionsMobile',
    content: (
      <>
        <p>You can view your list of current connections here, and start a conversation!</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/messages');
    },
  },

  {
    selector: '#messages',
    content: (
      <>
        <p>Your list of connections and messages will live here.</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/messages');
    },
  },
  {
    selector: '#waveRequests',
    content: (
      <>
        <p>When someone new waves at you, you'll see them here.</p>
      </>
    ),
    action: (node) => {
      history.replace('/connections/requests');
    },
  },

  {
    selector: '#helpButton',
    content: (
      <>
        View this tour at any time by pressing <b>display help tour again</b> in the help menu.
      </>
    ),
    stepInteraction: true,
    action: (node) => {
      history.replace('/search');
    },
  },
];

const Walkthrough = ({ walkthrough }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [step, setStep] = useState(1);
  const isMobileDevice = isMobileOrTablet();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (walkthrough) {
      setIsTourOpen(true);
      setStep(0);
    }
  }, [walkthrough]);

  const onCompleteTour = () => {
    setIsTourOpen(false);
    dispatch(finishTour());
    history.replace('/search');
  };

  const lastStep = isMobileDevice ? mobileSteps.length - 1 : desktopSteps.length - 1;

  return (
    <>
      <Tour
        steps={isMobileDevice ? mobileSteps : desktopSteps}
        isOpen={isTourOpen}
        disableFocusLock={true}
        badgeContent={(curr, tot) => `${curr} / ${tot}`}
        onRequestClose={onCompleteTour}
        prevButton={step ? <button className="button button-secondary">BACK</button> : <></>}
        nextButton={<button className="button button-primary animate-scale">NEXT</button>}
        lastStepNextButton={
          <button className="button button-primary animate-scale">GET STARTED</button>
        }
        closeWithMask={false}
        showNavigationNumber={false}
        showNavigation={false}
        startAt={step}
        showCloseButton={false}
        disableInteraction={true}
        disableKeyboardNavigation={['esc']}
        goToStep={step}
        getCurrentStep={(curr) => setStep(curr)}
      >
        {step !== lastStep && (
          <button
            onClick={() => {
              setStep(lastStep);
              setIsTourOpen(false);
              setIsTourOpen(true);
            }}
            className="block mb-4 ml-auto text-gray-400 underline cursor-pointer"
          >
            Skip
          </button>
        )}
      </Tour>
    </>
  );
};

export default Walkthrough;
