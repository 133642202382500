import { deleteFavoriteUser } from 'actions/user';
import { history } from 'helpers/history';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillStar, AiOutlineStar, AiOutlineStop } from 'react-icons/ai';
import { BsHouse, BsHouseFill } from 'react-icons/bs';
import { FaCheck, FaRegSmile } from 'react-icons/fa';
import { MdCake, MdPets } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { ChoiceType, GenderProfile, Pet } from 'types';
import { AppDispatch } from '../../store';

const FavoriteCard = ({ user }) => {
  const dispatch: AppDispatch = useDispatch();
  const [hoverRemove, setHoverRemove] = useState(false);

  const openProfile = () => {
    history.push('/profile/' + user.slug);
  };

  const unFavoriteUser = (e) => {
    e.stopPropagation();
    dispatch(deleteFavoriteUser(user.id)).then(() => {
      toast.success(`Removed ${user.name} as a favorite`);
    });
  };

  return (
    <div
      className="w-full max-w-sm overflow-hidden transition-all duration-300 ease-in-out transform bg-white border rounded-lg shadow-lg cursor-pointer select-none hover:bg-gray-100"
      role="button"
      tabIndex={0}
      onClick={() => openProfile()}
    >
      <div className="relative w-full h-full">
        <img
          alt="avatar"
          className="object-contain w-full my-auto"
          src={user.image || require('../../assets/default_avatar.png').default}
        />
      </div>
      <div className="px-4 py-3">
        <div className="flex flex-wrap items-center">
          <h1 className="mr-2 text-2xl font-semibold hover:underline">{user.name}</h1>
          {user.verified && (
            <span className="flex flex-row items-center">
              <span className="flex flex-row items-center px-2 py-1 text-sm text-white bg-blue-400 rounded-full">
                <FaCheck className="sm:mr-2" />
                <span className="hidden sm:flex">Verified</span>
              </span>
            </span>
          )}
        </div>
        <div>
          <p>
            <MdCake className="inline mb-1 text-purple-600" /> {user.age} years old
          </p>
          <p className="flex items-center">
            <FaRegSmile className="inline mr-1 text-black" />
            {GenderProfile[user.gender]}
          </p>
          <p className="flex flex-wrap">
            {user.hasHousing ? (
              <span>
                <BsHouseFill className="inline text-yellow-500" /> Has an extra bedroom
              </span>
            ) : (
              <span>
                <BsHouse className="inline text-yellow-500" /> Looking for housing
              </span>
            )}
          </p>
        </div>
        <div>
          {user.pets?.length && user.pets[0] !== (Pet.NONE || ChoiceType.WANTS) ? (
            <div>
              <MdPets className="inline text-yellow-800" /> Has pet(s)
            </div>
          ) : (
            <div>
              <AiOutlineStop className="inline text-red-600" /> Does not have pets
            </div>
          )}
        </div>

        <div className="absolute flex items-center right-3 top-3">
          {hoverRemove && (
            <div className="px-3 py-2 mr-2 transition-all delay-300 transform bg-transparent bg-gray-200 rounded-md">
              <span className="text-sm whitespace-nowrap">Remove favorite</span>
            </div>
          )}
          <div
            className="flex items-center justify-center w-10 h-10 bg-white border-2 border-yellow-300 rounded-full"
            role="button"
            onClick={(e) => unFavoriteUser(e)}
            onMouseEnter={() => setHoverRemove(true)}
            onMouseLeave={() => setHoverRemove(false)}
          >
            {hoverRemove ? (
              <AiOutlineStar size={24} className="text-yellow-400" />
            ) : (
              <AiFillStar size={24} className="text-yellow-400" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteCard;
