import {
  roommateGender,
  roommatePets as roommatePet,
  storyDrinking,
  storySmoking,
} from 'actions/onboarding';
import Modal from 'components/Common/Modal';
import PillsInput from 'components/Common/PillsInput';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { ChoiceType, GenderPreference, PetsPreference } from 'types';

const genderOptions = [
  { label: 'Any', value: GenderPreference.NO_PREFERENCE },
  { label: 'Male', value: GenderPreference.MALE },
  { label: 'Female', value: GenderPreference.FEMALE },
  { label: 'Non-Binary', value: GenderPreference.NONBINARY },
];

const petOptions = [
  { label: 'Any', value: PetsPreference.NO_PREFERENCE },
  { label: 'No pets', value: PetsPreference.NONE },
  { label: 'No dogs', value: PetsPreference.DOG },
  { label: 'No cats', value: PetsPreference.CAT },
];

const choiceOptions = [
  { label: 'Yes', value: ChoiceType.YES },
  { label: 'No', value: ChoiceType.NO },
];

const ProfileAboutMePreferences = ({ profile }) => {
  const [saving, setSaving] = useState(false);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [genericError, setGenericError] = useState('');

  const [smokingSelected, setSmokingSelected] = useState<boolean>(false);
  const [drinkingSelected, setDrinkingSelected] = useState<boolean>(false);

  const [selectedOptionGender, setSelectedOptionGender] = useState<GenderPreference | null>(null);
  const [selectedOptionPet, setSelectedOptionPet] = useState<PetsPreference[]>([]);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (profile?.preferences?.gender || profile?.preferences?.pets) {
      resetDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const resetDefault = () => {
    // set smoking preferences
    setSmokingSelected(profile?.smoking);

    // set drinking preferences
    setDrinkingSelected(profile?.drinking);

    const genders: any[] = genderOptions.map((gender) => gender.value);
    const selectedGender = genders.find((g) => profile?.preferences?.gender === g);

    setSelectedOptionGender(selectedGender);

    const petsOptions: any[] = petOptions.map((o) => o.value);
    const selected = petsOptions.filter((g) => profile?.preferences?.pets?.includes(g));

    setSelectedOptionPet(selected);
  };

  const cancel = () => {
    resetDefault();
    setShowPreferencesModal(false);
  };

  const onChangeSmoking = (options: string[]) => {
    if (options[0] === ChoiceType.YES) {
      setSmokingSelected(false);
    } else if (options[0] === ChoiceType.NO) {
      setSmokingSelected(true);
    }
  };

  const onChangeDrinking = (options: string[]) => {
    if (options[0] === ChoiceType.YES) {
      setDrinkingSelected(false);
    } else if (options[0] === ChoiceType.NO) {
      setDrinkingSelected(true);
    }
  };

  const onChangeGender = (options: string[]) => {
    setSelectedOptionGender(options[0] as GenderPreference);
  };

  const onChangeRoommatePet = (options: string[]) => {
    setSelectedOptionPet(options as PetsPreference[]);
  };

  const submitSmoking = async () => {
    try {
      await dispatch(storySmoking(smokingSelected));
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    }
  };

  const submitDrinking = async () => {
    try {
      await dispatch(storyDrinking(drinkingSelected));
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    }
  };

  const submitPreferences = async () => {
    setSaving(true);

    const gender = selectedOptionGender;
    setGenericError('');

    try {
      submitSmoking();
      submitDrinking();
      if (gender) {
        await dispatch(roommateGender(gender));
      }
      if (selectedOptionPet) {
        await dispatch(roommatePet(selectedOptionPet));
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setSaving(false);
      resetDefault();
      setShowPreferencesModal(false);
    }
  };

  return (
    <>
      <button
        className="absolute button button-flat -top-16 right-16"
        onClick={() => setShowPreferencesModal(true)}
      >
        Edit
      </button>

      <Modal
        show={showPreferencesModal}
        hideAction={() => setShowPreferencesModal(false)}
        onSecondary={() => cancel()}
        onConfirm={() => submitPreferences()}
        title={`Roommate preferences`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <div className="w-full">
              <p className="font-medium">Smoke-free household?</p>
              <PillsInput
                onChange={onChangeSmoking}
                values={smokingSelected ? [ChoiceType.NO] : [ChoiceType.YES]}
                options={choiceOptions}
                selectOne={true}
                light={true}
              />

              <p className="font-medium">Alcohol-free household?</p>
              <PillsInput
                onChange={onChangeDrinking}
                values={drinkingSelected ? [ChoiceType.NO] : [ChoiceType.YES]}
                options={choiceOptions}
                selectOne={true}
                light={true}
              />

              <p className="font-medium">What pets are you okay living with?</p>
              <PillsInput
                onChange={onChangeRoommatePet}
                values={selectedOptionPet}
                options={petOptions}
                selectOne={true}
                light={true}
              />

              <p className="font-medium">Preferred roommate's gender?</p>
              <PillsInput
                onChange={onChangeGender}
                values={selectedOptionGender ? [selectedOptionGender] : []}
                options={genderOptions}
                selectOne={true}
                light={true}
              />

              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMePreferences;
