import React from 'react';
import { Link } from 'react-router-dom';

export const MobileLogo = () => {
  return (
    <Link id="mobileLogo" className="-ml-2 active:opacity-70 cursor-pointer lg:hidden" to="/">
      <img
        className="py-2 w-12 h-full"
        src={require('../../assets/rumi-mobile.png').default}
        alt="Rumi logo"
      />
    </Link>
  );
};

export default MobileLogo;
