import FormField from 'components/Common/FormField';
import LoadingButton from 'components/Common/LoadingButton';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import LandingLayout from 'components/Layout/LandingLayout';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import { AppDispatch } from '../../store';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [genericError, setGenericError] = useState('');
  const [successful, setSuccessful] = useState(false);

  const handleSubmit = async (e: any) => {
    if (successful) {
      return;
    }
    setSuccessful(false);
    setGenericError('');
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(forgotPassword(email));
      setSuccessful(true);
    } catch (error) {
      if (error?.response?.status === 404) {
        setGenericError("We couldn't find an account with that email address");
      } else {
        setGenericError('An unknown error occurred. Please refresh the page and try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password | Rumi</title>
      </Helmet>
      <LandingLayout>
        <div className="flex-grow w-full text-base">
          <h1 className="mb-4">Forgot password</h1>
          <form onSubmit={handleSubmit}>
            <div className="mx-auto w-full">
              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
              <div className="py-2">
                <FormField
                  label="Email"
                  type="email"
                  disabled={successful}
                  name="email"
                  placeholder="example@gmail.com"
                  required
                  onChange={onChangeEmail}
                  data-hj-allow
                />
              </div>
              {successful ? (
                <StatusMessage
                  type={StatusType.SUCCESS}
                  message="Check your email for a link to reset your password"
                />
              ) : (
                <LoadingButton isLoading={isLoading} className="mt-4 w-full" variant="primary">
                  Recover my account
                </LoadingButton>
              )}
              <div className="mt-4 pt-4 border-t border-gray-300">
                <Link to="/login" className="button button-secondary w-full">
                  ← Return to Login
                </Link>
              </div>
            </div>
          </form>
        </div>
      </LandingLayout>
    </>
  );
};

export default ForgotPassword;
