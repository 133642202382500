import Card from 'components/Common/Card';
import LoadingButton from 'components/Common/LoadingButton';
import PillsInput, { PillOptions } from 'components/Common/PillsInput';
import SliderInput from 'components/Common/Slider';
import Map from 'components/Settings/Map';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { AiFillHome, AiOutlineHome } from 'react-icons/ai';
import { GrMapLocation } from 'react-icons/gr';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { housingLiving } from '../../actions/onboarding';
import { parseGoogleAddress } from '../../helpers/parseGoogleAddress';
import { AppDispatch } from '../../store';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';
import './HousingLivingSituation.scss';

interface RootState {
  auth: any;
}

type Props = {
  history: any;
};

enum SITUATION_OPTIONS {
  LOOKING = 'LOOKING',
  HAVE_PLACE = 'HAVE_PLACE',
}

const locationOptions: PillOptions = [
  { label: 'I am looking for a new place', value: SITUATION_OPTIONS.LOOKING },
  { label: 'I have an extra bedroom', value: SITUATION_OPTIONS.HAVE_PLACE },
];

type MapConfig = {
  lat: number;
  lng: number;
  radius?: number;
  includeCircle: boolean;
  hideMarker: boolean;
};

const autoCompleteStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#6B7280' : '#9CA3AF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    background: 'white',
    outline: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
    borderRadius: 8,
    boxShadow: '0 4px 2px -2px rgba(0,0,0,0.1)',
    '&:hover': {},
    paddingLeft: 35,
    paddingRight: 2,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 1,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 999,
    position: 'relative',
  }),
};

const HousingLivingSituationNew = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const [location, setLocation] = useState(null as any);

  const [mapsLocation, setMapsLocation] = useState(null as any);
  const [lat, setLat] = useState<number>(+currentUser?.profile?.location?.lat);
  const [lng, setLng] = useState<number>(+currentUser?.profile?.location?.lng);
  const [radius, setRadius] = useState(+currentUser?.profile?.location?.radius || 50);
  const [address, setAddress] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [housingSituation, setCurrentSituation] = useState<any>(
    currentUser?.profile?.location?.housing
      ? SITUATION_OPTIONS.HAVE_PLACE
      : SITUATION_OPTIONS.LOOKING,
  );

  const type = currentUser.profile.type === 'CAREGIVER' ? 'Rumi' : 'Supportive Roommate';
  const hasOwnPlace = housingSituation === SITUATION_OPTIONS.HAVE_PLACE;

  useEffect(() => {
    setGenericError('');
    if (!location && currentUser.profile.location) {
      const loc = currentUser.profile.location;
      setMapsLocation({
        lat: loc.lat,
        lng: loc.lng,
        address: loc.address,
        city: loc.city,
        state: loc.state,
        zip: loc.zip,
      });
    }

    const geocode = async () => {
      if (location?.label) {
        const results = await geocodeByAddress(location.label);
        const result = results[0];
        const address = parseGoogleAddress(result.address_components);
        try {
          const latLng = await getLatLng(result);
          setAddress(address);
          setLat(latLng.lat);
          setLng(latLng.lng);
          setMapsLocation({
            lat: latLng.lat,
            lng: latLng.lng,
            city: address.city,
            state: address.region,
            zip: address.postal_code,
            address: (address.home + ' ' + address.street).trim(),
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    geocode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const mapConfig: MapConfig = {
    lat,
    lng,
    radius: hasOwnPlace ? 1 : radius,
    hideMarker: false,
    includeCircle: true,
  };

  const handleSubmit = async () => {
    setGenericError('');
    setIsLoading(true);

    let locationPayload: any = {
      lat,
      lng,
      address:
        address?.home || address?.street
          ? `${address?.home} ${address?.street}`
          : mapsLocation?.address,
      city: address?.city || mapsLocation?.city,
      state: address?.region || mapsLocation?.state,
      zip: address?.postal_code || mapsLocation?.zip,
      radius: +radius,
      housing: hasOwnPlace,
    };

    if (!lat || !lng) {
      setGenericError('Please enter a valid address.');
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(housingLiving(locationPayload));
      history.push('/onboarding');
    } catch (error) {
      setGenericError(
        error?.response?.status === 400
          ? 'Please answer all fields'
          : 'An unknown server error occurred',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onSituationChange = (val: string[]) => {
    setGenericError('');
    setCurrentSituation(val[0]);
  };

  return (
    <OnboardingLayout title="What is your living situation?">
      <Card expanded={false}>
        <p className="-mb-2 text-sm text-gray-500">Choose one:</p>
        <PillsInput
          onChange={(val) => onSituationChange(val)}
          values={housingSituation ? [housingSituation] : []}
          options={locationOptions}
          selectOne={true}
          light={true}
        />
        {housingSituation && (
          <>
            <div>
              {hasOwnPlace ? (
                <>
                  <AiFillHome size={24} className="inline mb-1 mr-2" /> I have a place to live with
                  an extra bedroom to rent to a {type}.
                </>
              ) : (
                <>
                  <AiOutlineHome size={24} className="inline mb-1 mr-2" /> I am looking for housing
                  to rent with a {type}.
                </>
              )}
            </div>
            <div className="flex flex-col pt-5">
              <div className="relative">
                <label htmlFor="location" className="text-lg font-bold">
                  {housingSituation === SITUATION_OPTIONS.LOOKING
                    ? 'Desired Location:'
                    : 'Home Address:'}
                </label>
                <GooglePlacesAutocomplete
                  debounce={400}
                  minLengthAutocomplete={4}
                  selectProps={{
                    id: 'location',
                    location: location,
                    onChange: setLocation,
                    styles: autoCompleteStyles,
                    placeholder: mapsLocation
                      ? `${mapsLocation.address ? mapsLocation.address + ', ' : ''}${
                          mapsLocation.city && mapsLocation.city + ', '
                        }
                        ${mapsLocation.state}`
                      : 'Enter an address',
                  }}
                  onLoadFailed={(error) => console.error('Could not inject Google script', error)}
                  data-hj-allow
                />
                <HiOutlineLocationMarker size={30} className="absolute left-2 top-9" />
                {hasOwnPlace ? (
                  <div className="mb-2 text-gray-400">
                    Only your approximate home location will only be seen by others.
                  </div>
                ) : (
                  <div className="mb-2 text-gray-400">
                    Your desired location will be seen by others.
                  </div>
                )}
              </div>

              <div className="relative flex-1">
                {!!mapConfig && !!lat && !!lng && <Map mapConfig={mapConfig} />}
              </div>
              {housingSituation === SITUATION_OPTIONS.LOOKING && !!lat && !!lng && (
                <div className="relative space-y-2">
                  <p className="mt-4 text-lg font-bold">Max Distance:</p>
                  <SliderInput
                    domain={[5, 50]}
                    step={5}
                    values={[radius]}
                    left={true}
                    right={false}
                    onSliderChange={(range) => range}
                    onSliderUpdate={(range) => setRadius(range[0])}
                    sliderMode={(curr, next) => {
                      return next;
                    }}
                  />
                  {+radius === 50 ? (
                    <div className="w-full text-md">
                      <b>Anywhere</b> near {mapsLocation?.city}, {mapsLocation?.state}
                    </div>
                  ) : (
                    <div className="w-full text-md">
                      <b>Within {radius} miles</b>{' '}
                      {mapsLocation && `of ${mapsLocation?.city}, ${mapsLocation?.state}`}
                    </div>
                  )}
                </div>
              )}

              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
              <LoadingButton
                isLoading={isLoading}
                onClick={handleSubmit}
                className="w-full mt-6 text-lg button button-primary"
              >
                Save and continue
              </LoadingButton>
            </div>
          </>
        )}
      </Card>
    </OnboardingLayout>
  );
};

export default HousingLivingSituationNew;
