import { Dialog } from '@headlessui/react';
import React from 'react';

const NewWaveModal = ({ onConfirm, onCancel, cancelButtonRef, details }) => {
  return (
    <>
      <div>
        <div className="flex flex-wrap justify-center">
          <div className="flex items-center w-20 h-20 bg-white border rounded-full shadow overflow-hidden">
            <img
              className="w-full"
              src={details.image || require('../../assets/default_avatar.png').default}
              alt={details.name + ' profile photo'}
            />
          </div>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-gray-900 text-lg font-medium leading-6">
            {details?.firstName} waved at you!
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-gray-500">
              View their profile and wave back to start a conversation.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:grid sm:gap-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:mt-6">
        <button
          type="button"
          className="button button-primary inline-flex justify-center w-full sm:col-start-2 sm:text-sm"
          onClick={() => onConfirm()}
        >
          View Profile
        </button>
        <button
          type="button"
          className="button button-secondary inline-flex justify-center w-full sm:col-start-1 sm:text-sm"
          onClick={() => onCancel()}
          ref={cancelButtonRef}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default NewWaveModal;
