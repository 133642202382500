import classNames from 'classnames';
import LoadingButton from 'components/Common/LoadingButton';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { authMe, register as registerAction } from '../../actions/auth';
import { ProfileType } from '../../models/profileType';
import { AppDispatch } from '../../store';
import ExpandableCard from '../Common/ExpandableCard';
import FormField from '../Common/FormField';
import OnboardingLayout from '../Layout/OnboardingLayout';

type FormData = {
  firstName: string;
  lastName: string;
  birthday: string;
  phone: string;
  email: string;
  password: string;
  type: string;
};

const Signup = ({ history }) => {
  const { type }: any = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [emailInUse, setEmailInUse] = useState(null as any);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [genericError, setGenericError] = useState('');
  const [profileType, setProfileType] = useState<ProfileType | null>();
  const [alreadyMade, setAlreadyMade] = useState<boolean>(false);

  if (!profileType) {
    switch (type) {
      case 'rumi':
        setProfileType(ProfileType.RUMI);
        setAlreadyMade(false);
        break;
      case 'supportive-roommate':
        setProfileType(ProfileType.SR);
        setAlreadyMade(false);
        break;
      case 'family-friends':
        setProfileType(ProfileType.SR);
        setAlreadyMade(true);
        break;
      default:
        if (type) {
          history.replace('/signup');
        }
    }
  }

  const handleCreateAccount = async ({
    firstName,
    lastName,
    birthday,
    phone,
    email,
    password,
    type = profileType as ProfileType,
  }: FormData) => {
    if (isLoading) {
      return;
    }
    setGenericError('');
    setEmailInUse(null);

    const birthDate = new Date(birthday);
    if (!verifyBirthdate(birthDate)) {
      setError('birthday', {
        type: 'manual',
        message: 'You must be at least 17 years old',
      });
      return;
    }

    const phoneNumber = phone.replace(/[-() ]/g, '');
    setIsLoading(true);

    try {
      await dispatch(
        registerAction(
          firstName,
          lastName,
          email,
          password,
          birthDate,
          phoneNumber,
          type,
          alreadyMade,
        ),
      );
      await dispatch(authMe());

      if (alreadyMade) {
        history.replace('/onboarding/friends-family');
      } else {
        history.replace('/onboarding');
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message || error?.message || error?.toString();
      if (error?.status === 409) {
        setEmailInUse(email);
      } else {
        setGenericError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyBirthdate = (birthDate: Date): boolean => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 17);
    return birthDate < date;
  };

  const form = (
    <div
      className={classNames(
        profileType === ProfileType.SR && alreadyMade ? 'pt-6' : 'pt-8',
        'sm:px-4 md:max-w-md space-y-8',
      )}
    >
      {profileType === ProfileType.SR && alreadyMade && (
        <div>
          <h3 className="mb-2 text-xl font-semibold">Supportive Roommate:</h3>
          <p>Please specify caregiver contact information</p>
        </div>
      )}
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <div className="flex space-x-2">
          <div className="w-full mb-4">
            <FormField
              label="First name"
              type="text"
              {...register('firstName', {
                required: 'Please enter your first name',
              })}
              autoComplete="given-name"
              placeholder="First"
              errors={errors}
              data-hj-allow
            />
          </div>
          <div className="w-full mb-4">
            <FormField
              label="Last name"
              type="text"
              {...register('lastName', {
                required: 'Please enter your last name',
              })}
              autoComplete="family-name"
              placeholder="Last"
              errors={errors}
              data-hj-allow
            />
          </div>
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue=""
            name="birthday"
            rules={{
              required: 'Please enter date of birth',
            }}
            render={({ field }) => (
              <MaskedInput
                mask={[
                  /[0-1]/,
                  /[0-9]/,
                  '/',
                  /[0-3]/,
                  /[0-9]/,
                  '/',
                  /[1-2]/,
                  /[09]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                {...field}
                render={(ref, props) => (
                  <FormField
                    {...register('birthday', {
                      required: 'Please enter your date of birth',
                    })}
                    autoComplete="bday"
                    label="Birthday"
                    type="text"
                    placeholder="mm/dd/yyyy"
                    errors={errors}
                    data-hj-allow
                    ref={(input) => ref(input)}
                    {...props}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue=""
            name="phone"
            rules={{
              required: 'Please enter your phone number',
              pattern: {
                value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                message: 'Please enter a valid phone number',
              },
            }}
            render={({ field }) => (
              <MaskedInput
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                {...field}
                render={(ref, props) => (
                  <FormField
                    label="Phone number"
                    type="tel"
                    autoComplete="tel"
                    placeholder="(xxx) xxx-xxxx"
                    errors={errors}
                    data-hj-allow
                    ref={(input) => ref(input)}
                    {...props}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            label="Email"
            type="email"
            {...register('email', {
              required: 'Please enter your email address',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address',
              },
            })}
            autoComplete="email"
            placeholder="example@email.com"
            errors={errors}
            data-hj-allow
          />
        </div>
        <div className="relative mb-4">
          <FormField
            label="Password (at least 6 characters)"
            type={showPassword ? 'text' : 'password'}
            {...register('password', {
              required: 'Please enter a password',
              minLength: {
                value: 6,
                message: 'Please enter at least 6 characters',
              },
            })}
            autoComplete="new-password"
            errors={errors}
          />
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute mt-1 cursor-pointer right-6 top-9"
          >
            {showPassword ? <BsEye size={20} /> : <BsEyeSlash size={20} />}
          </span>
        </div>
        {emailInUse && (
          <StatusMessage
            type={StatusType.WARN}
            className="mt-2"
            message={
              <p
                className="text-lg cursor-pointer hover:underline"
                onClick={() => history.push({ pathname: '/login', search: `?email=${emailInUse}` })}
              >
                Your account already exists, <b>click here to login or reset your password</b>.
              </p>
            }
          />
        )}
        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <div className="flex justify-around">
          <LoadingButton isLoading={isLoading} className="w-full mt-4" variant="primary">
            Sign up
          </LoadingButton>
        </div>

        <div className="text-xs text-gray-600 select-none">
          By signing up for a Rumi account, you consent to a background check and agree to our{' '}
          <a
            href="https://meetmyrumi.com/privacy-policy/"
            className="font-semibold hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>{' '}
          and{' '}
          <a
            href="https://meetmyrumi.com/terms-and-conditions/"
            className="font-semibold hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            terms of use.
          </a>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Sign Up | Rumi</title>
      </Helmet>
      <OnboardingLayout title="Create an account" subtitle="Let's get your account set up.">
        <>
          <div className="mb-4 text-sm text-gray-100">
            <b>Please note</b>: Rumi does not operate outside of Minnesota.
          </div>
          <div className="mb-4">
            {(!type || type === 'rumi') && (
              <ExpandableCard
                label={
                  <>
                    <img
                      src={require('../../assets/rumi-icon.png').default}
                      className="inline w-10 h-10 mb-1 mr-2"
                      alt="Rumi icon"
                    />{' '}
                    Rumi
                  </>
                }
                description='"I am an individual with a disability who would like more independence."'
                expanded={type === 'rumi'}
                onExpanded={(val) => {
                  history.push(val ? '/signup/rumi' : '/signup');
                  setProfileType(val ? ProfileType.RUMI : undefined);
                  setAlreadyMade(false);
                }}
              >
                {form}
              </ExpandableCard>
            )}
          </div>
          <div>
            {(!type || type === 'supportive-roommate') && (
              <ExpandableCard
                label={
                  <>
                    <img
                      src={require('../../assets/supportive-roommate-icon.png').default}
                      className="inline w-12 h-12 mb-1 mr-2"
                      alt="Supportive roommate icon"
                    />{' '}
                    Supportive Roommate
                  </>
                }
                description='"I am a caregiver who would like to move in with an individual with a disability."'
                expanded={type === 'supportive-roommate'}
                onExpanded={(val) => {
                  history.push(val ? '/signup/supportive-roommate' : '/signup');
                  setProfileType(val ? ProfileType.SR : undefined);
                  setAlreadyMade(false);
                }}
              >
                {form}
              </ExpandableCard>
            )}
          </div>
          {!type && <hr className="my-8 border" />}
          <div className="mb-4">
            {(!type || type === 'family-friends') && (
              <ExpandableCard
                label={
                  <>
                    <img
                      src={require('../../assets/rumi-icon.png').default}
                      className="inline w-10 h-10 mb-1 mr-2"
                      alt="Friend or family icon"
                    />
                    +
                    <img
                      src={require('../../assets/supportive-roommate-icon.png').default}
                      className="inline w-12 h-12 mb-1"
                      alt="Supportive roommate icon"
                    />{' '}
                    Family & Friends
                  </>
                }
                description='"I already know an individual with a disability that I would like to provide care for in the same home."'
                expanded={type === 'family-friends'}
                onExpanded={(val) => {
                  history.push(val ? '/signup/family-friends' : '/signup');
                  setProfileType(val ? ProfileType.SR : undefined);
                  setAlreadyMade(true);
                }}
              >
                {form}
              </ExpandableCard>
            )}
          </div>

          {!profileType && (
            <p className="text-white whitespace-nowrap">
              Already have an account?{' '}
              <Link to="/login" className="button button-small">
                Login
              </Link>
            </p>
          )}
        </>
      </OnboardingLayout>
    </>
  );
};

export default Signup;
