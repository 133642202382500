import {
  careHours,
  careTasks as careTasksAction,
  roommateGender,
  roommatePets,
} from 'actions/onboarding';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CareHours, CareTasks as CareTasksEnum, GenderPreference, PetsPreference } from 'types';
import SettingsLayout from '../Layout/SettingsLayout';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { messageOnlyToast } from 'helpers/toasts';
import { isEqual } from 'lodash';

const careOptions = [
  { label: 'Budgeting', value: CareTasksEnum.BUDGETING },
  { label: 'Companionship', value: CareTasksEnum.COMPANIONSHIP },
  { label: 'Coping skills', value: CareTasksEnum.COPING_SKILLS },
  { label: 'Household tasks', value: CareTasksEnum.CLEANING },
  { label: 'Medication', value: CareTasksEnum.MEDICATION },
  { label: 'Meal prep', value: CareTasksEnum.MEAL_PREP },
  { label: 'Mobility', value: CareTasksEnum.MOBILITY },
  { label: 'Personal care', value: CareTasksEnum.PERSONAL_CARE },
  { label: 'Problem solving', value: CareTasksEnum.PROBLEM_SOLVING },
  { label: 'Transportation', value: CareTasksEnum.TRANSPORTATION },
  { label: "I'm not sure", value: CareTasksEnum.NOT_SURE },
];

const careHoursOptions = [
  { value: CareHours.LESS_THAN_10_HOURS, label: 'Less than 10 hours' },
  { value: CareHours.BETWEEN_10_AND_20_HOURS, label: 'Between 10 and 20 hours' },
  { value: CareHours.BETWEEN_20_AND_30_HOURS, label: 'Between 20 and 30 hours' },
  { value: CareHours.BETWEEN_30_AND_40_HOURS, label: 'Between 30 and 40 hours' },
  { value: CareHours.MORE_THAN_40, label: 'More than 40 hours' },
  { value: CareHours.NOT_SURE, label: 'Not sure' },
  { value: CareHours.NO_PREFERENCE, label: 'No preference' },
];

const genderOptions = [
  { value: GenderPreference.MALE, label: 'Male' },
  { value: GenderPreference.FEMALE, label: 'Female' },
  { value: GenderPreference.NONBINARY, label: 'Non-Binary' },
  { value: GenderPreference.NO_PREFERENCE, label: 'No preference' },
];

const petsOptions = [
  { label: 'No preference', value: [PetsPreference.NO_PREFERENCE] },
  { label: 'No pets', value: [PetsPreference.NONE] },
  { label: 'No dogs', value: [PetsPreference.DOG] },
  { label: 'No cats', value: [PetsPreference.CAT] },
];

const reactSelectStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#6B7280' : '#9CA3AF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    background: 'white',
    outline: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
    borderRadius: 8,
    boxShadow: '0 4px 2px -2px rgba(0,0,0,0.1)',
    '&:hover': {},
    padding: 2,
    marginTop: 1,
    marginBottom: 10,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#3C81F6',
    border: 'none',
    borderRadius: 20,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  }),
};

const LivingPreferencesSettings = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [careTasks, setCareTasks] = useState<any[]>(
    // Options selected
    careOptions
      .filter((ref) => currentUser.profile.careInfo?.tasks?.indexOf(ref.value) > -1)
      .concat(
        // "Other" options, turning it into a { label: '', value: '' } block, then concat
        currentUser.profile.careInfo?.tasks
          ?.filter((val) => !careOptions?.map((co) => co.value).includes(val))
          .map((co) => {
            return { label: co, value: co };
          }),
      ),
  );
  const dispatch = useDispatch();

  const handleCareHours = async (e) => {
    setIsLoading(true);
    try {
      await dispatch(careHours(e.value));

      toast.success('Your preferences have been saved');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your preferences have not been saved. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGender = async (e) => {
    setIsLoading(true);
    try {
      await dispatch(roommateGender(e.value));

      toast.success('Roommate gender preferences have been saved.');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your preferences have not been saved. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePets = async (e) => {
    setIsLoading(true);
    try {
      await dispatch(roommatePets(e.value));

      toast.success('Roommate pets preferences have been saved.');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your preferences have not been saved. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitCareTasks = async (tasks) => {
    setCareTasks(tasks);
    setIsLoading(true);
    try {
      await dispatch(careTasksAction(tasks));

      toast.success('Care tasks preferences have been saved.');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your preferences have not been saved. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsLayout title="Living preferences">
        <div className="flex flex-col content-start max-w-xl mb-40 space-y-10 justify-items-auto">
          <div>
            <p className="mb-2 text-lg font-semibold text-gray-700">CARE</p>
            <div className="space-y-2">
              <label className="text-gray-700 opacity-80">Hours per week</label>
              <Select
                defaultValue={careHoursOptions.filter(
                  (ref) => currentUser.profile?.careInfo?.hours?.indexOf(ref.value) > -1,
                )}
                isDisabled={isLoading}
                options={careHoursOptions}
                isSearchable={false}
                name="careHours"
                styles={reactSelectStyles}
                onChange={(e) => {
                  handleCareHours(e);
                }}
              />

              <label className="text-gray-700 opacity-80">Tasks</label>
              <CreatableSelect
                options={careOptions}
                defaultValue={careTasks}
                isMulti
                name="careTasks"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                onChange={(e) => {
                  let tasks = e?.map(function (item) {
                    return item['value'];
                  });

                  submitCareTasks(tasks);
                }}
                styles={reactSelectStyles}
              />
            </div>
          </div>

          <div>
            <p className="mb-2 text-lg font-semibold text-gray-700">ROOMMATES</p>
            <div className="space-y-2">
              <label className="text-gray-700 opacity-80">Gender</label>
              <Select
                defaultValue={genderOptions.filter(
                  (ref) => currentUser.profile?.preferences?.gender?.indexOf(ref.value) > -1,
                )}
                isDisabled={isLoading}
                isSearchable={false}
                options={genderOptions}
                name="gender"
                styles={reactSelectStyles}
                onChange={(e) => {
                  handleGender(e);
                }}
              />

              <label className="text-gray-700 opacity-80">Pets</label>
              <Select
                defaultValue={petsOptions.find((ref) =>
                  isEqual(ref.value, currentUser.profile?.preferences?.pets),
                )}
                isDisabled={isLoading}
                isSearchable={false}
                options={petsOptions}
                name="pets"
                styles={reactSelectStyles}
                onChange={(e) => {
                  handlePets(e);
                }}
              />
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
};

export default LivingPreferencesSettings;
