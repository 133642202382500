import { authMe } from 'actions/auth';
import { findIndex, findLastIndex } from 'lodash';
import userService from 'services/user.service';
import { AppDispatch } from 'store';
import { ProfileType } from './../models/profileType';
import { ContactType } from './../types/index';
import { getOnboardingLocalStorage } from './getOnboardingState';

interface Step {
  url: string;
  isComplete: (user: any) => any;
}

const newStep = (url: string, isComplete: (user: any) => any): Step => ({ url, isComplete });

export const steps: Step[] = [
  newStep('/onboarding/case-manager', (user: any) =>
    user.profile?.type === ProfileType.RUMI || user.profile?.alreadyMade
      ? !!user.contacts?.filter((contact) => contact.type === ContactType.CASE_MANAGER).length ||
        getOnboardingLocalStorage().caseSkipped
      : true,
  ),
  newStep('/onboarding/alternative-contact', (user: any) =>
    user.profile?.type === ProfileType.RUMI
      ? !!user.contacts?.filter((contact) => contact.type === ContactType.ALTERNATIVE).length ||
        getOnboardingLocalStorage().altSkipped
      : true,
  ),
  newStep(
    '/onboarding/profile',
    (user: any) =>
      (user.profile?.gender && user.profile?.preferences?.gender) || user.profile?.alreadyMade,
  ),
  // newStep(
  //   '/onboarding/profile/type',
  //   (user: any) => (user: any) => user.profile?.type,
  // ),
  newStep(
    '/onboarding/profile/gender',
    (user: any) =>
      (user.profile?.gender && user.profile?.preferences?.gender) || user.profile?.alreadyMade,
  ),
  newStep(
    '/onboarding/profile/pets',
    (user: any) =>
      (user.profile?.pets?.length && user.profile?.preferences?.pets) || user.profile?.alreadyMade,
  ),
  newStep('/onboarding/profile/bio', (user: any) => user.profile?.bio || user.profile?.alreadyMade),
  newStep(
    '/onboarding/profile/picture',
    (user: any) =>
      user.image || getOnboardingLocalStorage().picSkipped || user.profile?.alreadyMade,
  ),
  newStep(
    '/onboarding/housing/living-situation',
    (user: any) => user.profile?.location?.lat || user.profile?.alreadyMade,
  ),
  newStep(
    '/onboarding/housing/preferences',
    (user: any) => user.profile?.preferences?.housing || user.profile?.alreadyMade,
  ),
  newStep('/onboarding/care', (user: any) => user.profile?.careInfo?.hours),
  newStep('/onboarding/care/hours', (user: any) => user.profile?.careInfo?.hours),
  newStep('/onboarding/care/reference', (user: any) => user.profile?.references?.length),
  newStep('/onboarding/thanks', (user: any) => false),
];

export const lastStepCompleted = (user: any, steps: Step[]) => {
  return findLastIndex(steps, (step) => {
    return step.isComplete(user);
  });
};

export const firstStepIncomplete = (user: any, steps: Step[]) => {
  return findIndex(steps, (step) => {
    return !step.isComplete(user);
  });
};

export const allStepsCompleted = (user: any) => steps.every((step) => step.isComplete(user));

export const lastUnfinishedStep = (user: any) => {
  const lastCompleted = lastStepCompleted(user, steps);
  const nextStepIndex = lastCompleted === steps.length - 1 ? lastCompleted : lastCompleted + 1;
  return steps[nextStepIndex];
};

export const firstUnfinishedStep = (user: any, dispatch: AppDispatch) => {
  const firstIncompleteStep = firstStepIncomplete(user, steps);
  const step = firstIncompleteStep === -1 ? steps.length - 1 : firstIncompleteStep;

  if (step === steps.length - 1) {
    userService.finishOnboarding().then(() => {
      dispatch(authMe());
    });
  }

  return steps[step];
};
