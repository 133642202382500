import LoadingButton from 'components/Common/LoadingButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Gender, GenderPreference } from 'types';
import { roommateGender as roommateGenderAction, storyGender } from '../../actions/onboarding';
import { AppDispatch } from '../../store';
import PillsInput, { PillOptions } from '../Common/PillsInput';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';

type Props = {
  history: any;
};

const genderOptions: PillOptions = [
  { label: 'Male', value: Gender.MALE },
  { label: 'Female', value: Gender.FEMALE },
  { label: 'Non-Binary', value: Gender.NONBINARY },
];

const roommateGenderOptions = [
  { label: 'No preference', value: GenderPreference.NO_PREFERENCE },
  { label: 'Male', value: GenderPreference.MALE },
  { label: 'Female', value: GenderPreference.FEMALE },
  { label: 'Non-Binary', value: GenderPreference.NONBINARY },
];

const StoryGender = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [selectedOption, setSelectedOption] = useState<Gender | null>(null);
  const [roommateSelectedOption, setRoommateSelectedOption] = useState<GenderPreference | null>(
    null,
  );
  const [genericError, setGenericError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const genders: any[] = genderOptions.map((gender) => gender.value);
    const selectedGender = genders.find((g) => currentUser?.profile?.gender === g);

    setSelectedOption(selectedGender);

    if (
      !selectedGender &&
      currentUser?.profile?.gender &&
      ![Gender.MALE, Gender.FEMALE].includes(currentUser?.profile?.gender)
    ) {
      setSelectedOption(Gender.NONBINARY);
    }
  }, [currentUser]);

  useEffect(() => {
    const roommateGenders: any[] = roommateGenderOptions.map((gender) => gender.value);
    const selectedGender = roommateGenders.find(
      (g) => currentUser?.profile?.preferences?.gender === g,
    );

    setRoommateSelectedOption(selectedGender);
  }, [currentUser]);

  const onChangeRoommateOptions = (options: string[]) => {
    setRoommateSelectedOption(options[0] as GenderPreference);
  };

  const onChangeOption = (option: string[]) => {
    setSelectedOption(option[0] as Gender);
  };

  const submitGender = async () => {
    let gender: string = selectedOption as Gender;
    const roommateGender = roommateSelectedOption;
    setGenericError('');
    setIsLoading(true);

    if (!roommateGender) {
      return;
    }

    try {
      await dispatch(storyGender(gender));
      await dispatch(roommateGenderAction(roommateGender));
      history.push('/onboarding');
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const values = selectedOption ? [selectedOption] : [];

  return (
    <>
      <OnboardingLayout
        title="Tell us about yourself"
        subtitle="We use gender to help find the best possible matches."
      >
        <p className="-mt-6 text-white">Gender identity:</p>
        <PillsInput
          onChange={onChangeOption}
          values={values}
          options={genderOptions}
          selectOne={true}
        />

        <p className="mt-10 text-3xl font-bold text-white sm:mb-6 sm:text-4xl">
          Do you have a gender preference for a roommate?
        </p>
        <PillsInput
          onChange={onChangeRoommateOptions}
          values={roommateSelectedOption ? [roommateSelectedOption] : []}
          options={roommateGenderOptions}
          selectOne={true}
        />

        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <LoadingButton
          isLoading={isLoading}
          disabled={isEmpty(roommateSelectedOption) || isEmpty(selectedOption) || isLoading}
          onClick={submitGender}
          variant="primary-white"
          className="hidden w-full mt-6 text-lg md:flex"
        >
          Save and continue
        </LoadingButton>
      </OnboardingLayout>
      <div className="fixed bottom-0 w-full px-2 bg-white md:hidden">
        <LoadingButton
          isLoading={isLoading}
          disabled={isEmpty(roommateSelectedOption) || isEmpty(selectedOption) || isLoading}
          onClick={submitGender}
          variant="primary"
          className="w-full text-lg"
        >
          Save and continue
        </LoadingButton>
      </div>
    </>
  );
};

export default StoryGender;
