import HelpButton from 'components/Common/HelpButton';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const bg = require('../../assets/collage.jpg').default;

const LandingLayout: FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="h-screen bg-white">
      <div className="flex flex-col w-full h-full overflow-y-auto md:flex-row">
        <div className="relative flex-col hidden overflow-hidden md:flex xl:w-2/3 lg:w-3/5 md:w-1/2">
          <div
            className="absolute inset-0 text-center text-white transform"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {/* <img src={require('../../assets/login-collage.png').default} /> */}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center h-full my-auto text-center">
            <div className="relative z-10 max-w-xs p-4 mx-auto mb-16 border border-gray-200 shadow-xl lg:max-w-sm xl:max-w-md lg:p-8 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 rounded-xl">
              <img
                src={require('../../assets/rumi-white.png').default}
                className="px-20 mx-auto"
                alt="Rumi logo"
              />
              <p className="font-medium text-white xl:text-lg md:mt-8">
                Transforming disability services,
                <br /> one connection at a time.
              </p>
            </div>
            <div className="absolute w-full h-full bg-gray-800 bg-gradient-to-b from-blue-800 to-blue-900 opacity-30"></div>
          </div>
        </div>
        <div className="relative z-10 flex flex-col flex-1 px-6 pt-4 pb-24 sm:px-16 md:px-4 lg:px-8 md:pb-0 bg-gradient-to-b from-blue-400 to-blue-700 xl:w-1/3 lg:w-2/5 md:w-1/2 md:h-screen">
          <div className="flex items-center justify-between h-16 md:hidden">
            <Link to="/">
              <img
                src={require('../../assets/rumi-all-white.svg').default}
                className="w-32"
                alt="Rumi logo"
              />
            </Link>
            <div className="flex flex-col">
              <HelpButton />
            </div>
          </div>
          <div className="flex flex-col justify-center flex-1 mb-24 md:mb-0">
            <div className="flex flex-col max-w-sm py-8 mx-auto md:hidden">
              <p className="mt-4 font-medium text-center text-white text-md sm:text-lg">
                Transforming disability services,
                <br /> one connection at a time.
              </p>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="flex-grow w-full max-w-sm p-6 mx-auto bg-white border shadow-2xl sm:p-8 rounded-xl">
                {children}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex flex-wrap items-center justify-center py-4 text-xs font-medium text-gray-300">
            <a
              href="https://meetmyrumi.com"
              target="_blank"
              className="m-2 hover:underline whitespace-nowrap"
              rel="noreferrer"
            >
              Rumi
            </a>
            •
            <a
              href="https://meetmyrumi.com/contact-us/"
              target="_blank"
              className="m-2 hover:underline whitespace-nowrap"
              rel="noreferrer"
            >
              Contact
            </a>
            •
            <a
              href="https://meetmyrumi.com/faq/"
              target="_blank"
              className="m-2 hover:underline whitespace-nowrap"
              rel="noreferrer"
            >
              FAQ
            </a>
            •
            <a
              href="https://meetmyrumi.com/privacy-policy/"
              target="_blank"
              className="m-2 hover:underline whitespace-nowrap"
              rel="noreferrer"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingLayout;
