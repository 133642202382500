import React, { useEffect, useState } from 'react';
import OnboardingLayout from '../Layout/OnboardingLayout';
import PillsInput, { PillOptions } from '../Common/PillsInput';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { housingPreferences, storyDrinking, storySmoking } from '../../actions/onboarding';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import { HousingRequirements } from 'types';
import FormField from 'components/Common/FormField';
import { RootState } from 'reducers';
import LoadingButton from 'components/Common/LoadingButton';

type Props = {
  history: any;
};

const requirementOptions: PillOptions = [
  { label: 'Alcohol-free', value: HousingRequirements.ALCOHOL_FREE },
  { label: 'Close to public transit', value: HousingRequirements.CLOSE_TO_PUBLIC_TRANSIT },
  { label: '1st floor bedroom', value: HousingRequirements.FIRST_FLOOR_BEDROOM },
  { label: 'No stairs', value: HousingRequirements.NO_STAIRS },
  { label: 'Roll in shower', value: HousingRequirements.ROLL_IN_SHOWER },
  { label: 'Smoke-free', value: HousingRequirements.SMOKE_FREE },
  { label: 'Wheelchair accessible', value: HousingRequirements.WHEELCHAIR_ACCESSIBLE },
  { label: 'Other', value: HousingRequirements.OTHER },
];

const HousingPreferences = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [selectedOptions, setSelectedOptions] = useState<HousingRequirements[]>([]);
  const [requirementsOther, setRequirementsOther] = useState<string>();
  const [smoking, setSmoking] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [drinking, setDrinking] = useState<boolean>(true);
  const [genericError, setGenericError] = useState('');
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const references: any[] = requirementOptions.map((ref) => ref.value);
    const selectedHousingPrefs = references.filter((ref) =>
      currentUser?.profile?.preferences?.housing?.includes(ref),
    );
    const otherHousingPrefs = currentUser?.profile?.preferences?.housing?.find(
      (ref) => !references.includes(ref),
    );

    if (otherHousingPrefs) {
      selectedHousingPrefs.push(HousingRequirements.OTHER);
      setRequirementsOther(otherHousingPrefs);
    }

    if (currentUser.profile.smoking === false) {
      selectedHousingPrefs.push(HousingRequirements.SMOKE_FREE);
    }

    if (currentUser.profile.drinking === false) {
      selectedHousingPrefs.push(HousingRequirements.ALCOHOL_FREE);
    }

    setSelectedOptions(selectedHousingPrefs);
  }, [currentUser]);

  const onChangeOptions = (options: string[]) => {
    setSelectedOptions(options as HousingRequirements[]);

    const smokingReq = options.filter((e) => e === 'SMOKE_FREE');
    const drinkingReq = options.filter((e) => e === 'ALCOHOL_FREE');

    if (smokingReq[0] === 'SMOKE_FREE') {
      setSmoking(false);
    } else {
      setSmoking(true);
    }

    if (drinkingReq[0] === 'ALCOHOL_FREE') {
      setDrinking(false);
    } else {
      setDrinking(true);
    }
  };

  const onChangeRequirementsOther = (req: string) => {
    setRequirementsOther(req);
  };

  const submitHousingPreferences = async () => {
    setIsLoading(true);
    setGenericError('');

    const requirements: string[] = selectedOptions;

    if (requirements.includes(HousingRequirements.OTHER) && requirementsOther) {
      // remove OTHER
      const otherIndex = requirements.indexOf(HousingRequirements.OTHER);
      requirements.splice(otherIndex, 1);

      requirements.push(requirementsOther);
    }

    try {
      await dispatch(housingPreferences(requirements));
      await dispatch(storyDrinking(drinking));
      await dispatch(storySmoking(smoking));
      history.push('/onboarding');
    } catch {
      setGenericError('Oops, there was an issue. Please refresh and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const title = currentUser.profile?.location?.housing
    ? 'What does your home offer?'
    : 'What do you need in your new home?';

  const subtitle = <>Select all that apply:</>;

  const otherPlaceholder = currentUser.profile?.location?.housing
    ? 'What else does your home offer'
    : 'What other home requirements do you need';

  return (
    <>
      <OnboardingLayout title={title} subtitle={subtitle}>
        <div className="-mt-4 sm:-mt-10">
          <PillsInput
            values={selectedOptions}
            onChange={onChangeOptions}
            options={requirementOptions}
            selectOne={false}
          />
        </div>
        {selectedOptions.includes(HousingRequirements.OTHER) && (
          <FormField
            type="text"
            name="other"
            placeholder={otherPlaceholder}
            value={requirementsOther}
            className="max-w-md"
            onChange={(e) => onChangeRequirementsOther(e.target.value)}
            data-hj-allow
          />
        )}

        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <LoadingButton
          onClick={submitHousingPreferences}
          isLoading={isLoading}
          className="hidden w-full mt-6 text-lg md:flex"
          variant="primary-white"
        >
          Save and continue
        </LoadingButton>
      </OnboardingLayout>
      <div className="fixed bottom-0 w-full px-2 bg-white md:hidden">
        <LoadingButton
          onClick={submitHousingPreferences}
          isLoading={isLoading}
          className="w-full text-lg"
          variant="primary"
        >
          Save and continue
        </LoadingButton>
      </div>
    </>
  );
};

export default HousingPreferences;
