import { housingLiving, housingPreferences, storyDrinking, storySmoking } from 'actions/onboarding';
import PillsInput, { PillOptions } from 'components/Common/PillsInput';
import SliderInput from 'components/Common/Slider';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import { parseGoogleAddress } from 'helpers/parseGoogleAddress';
import { messageOnlyToast } from 'helpers/toasts';
import React, { useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import toast from 'react-hot-toast';
import { AiFillHome, AiOutlineHome } from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { RootState } from 'reducers';
import { AppDispatch } from 'store';
import { HousingRequirements } from 'types';
import SettingsLayout from '../Layout/SettingsLayout';
import Map from './Map';

enum SITUATION_OPTIONS {
  LOOKING = 'LOOKING',
  HAVE_PLACE = 'HAVE_PLACE',
}

type MapConfig = {
  lat: number;
  lng: number;
  radius?: number;
  includeCircle: boolean;
  hideMarker: boolean;
};

const housingSituationOptions: PillOptions = [
  { label: 'I am looking for a new place', value: SITUATION_OPTIONS.LOOKING },
  { label: 'I have an extra bedroom', value: SITUATION_OPTIONS.HAVE_PLACE },
];

const homeReqs = [
  {
    label: 'Close to public transportation',
    value: HousingRequirements.CLOSE_TO_PUBLIC_TRANSIT,
  },
  { label: '1st floor bedroom', value: HousingRequirements.FIRST_FLOOR_BEDROOM },
  { label: 'Cannot have stairs', value: HousingRequirements.NO_STAIRS },
  { label: 'Roll in shower', value: HousingRequirements.ROLL_IN_SHOWER },
  { label: 'Wheelchair accessible', value: HousingRequirements.WHEELCHAIR_ACCESSIBLE },
];

const autoCompleteStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#6B7280' : '#9CA3AF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    background: 'white',
    outline: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
    borderRadius: 8,
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    '&:hover': {},
    paddingLeft: 35,
    paddingRight: 2,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 1,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 999,
    position: 'relative',
  }),
};

const reactSelectStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#6B7280' : '#9CA3AF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    background: 'white',
    outline: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
    borderRadius: 8,
    boxShadow: '0 4px 2px -2px rgba(0,0,0,0.1)',
    '&:hover': {},
    padding: 2,
    marginTop: 1,
    marginBottom: 10,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#3C81F6',
    border: 'none',
    borderRadius: 20,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  }),
};

const LocationSettings = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const [location, setLocation] = useState(null as any);

  const [mapsLocation, setMapsLocation] = useState(null as any);
  const [lat, setLat] = useState<number>(+currentUser?.profile?.location?.lat);
  const [lng, setLng] = useState<number>(+currentUser?.profile?.location?.lng);
  const [radius, setRadius] = useState<number>(currentUser?.profile?.location?.radius || 50);
  const [address, setAddress] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [housingSituation, setHousingSituation] = useState<any>(
    currentUser?.profile?.location?.housing
      ? SITUATION_OPTIONS.HAVE_PLACE
      : currentUser?.profile?.location
      ? SITUATION_OPTIONS.LOOKING
      : null,
  );

  const dispatch: AppDispatch = useDispatch();
  const isInitialMount = useRef(true);

  const type = currentUser.profile.type === 'CAREGIVER' ? 'Rumi' : 'Supportive Roommate';
  const hasOwnPlace = housingSituation === SITUATION_OPTIONS.HAVE_PLACE;

  const mapConfig: MapConfig = {
    lat,
    lng,
    radius: hasOwnPlace ? 1 : radius,
    hideMarker: false,
    includeCircle: true,
  };

  const submitUpdatedLocation = async () => {
    setGenericError('');
    setIsLoading(true);

    let locationPayload: any = {
      lat,
      lng,
      address:
        address?.home || address?.street
          ? `${address?.home} ${address?.street}`
          : mapsLocation?.address,
      city: address?.city || mapsLocation?.city,
      state: address?.region || mapsLocation?.state,
      zip: address?.postal_code || mapsLocation?.zip,
      radius: +radius,
      housing: hasOwnPlace,
    };

    if (!lat || !lng) {
      setGenericError('Please enter a valid location');
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(housingLiving(locationPayload));
      toast.remove();
      toast.success('Location has been saved');
    } catch (error) {
      setGenericError('An unknown server error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    submitUpdatedLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, housingSituation]);

  useEffect(() => {
    setGenericError('');
    if (!location && currentUser.profile.location) {
      const loc = currentUser.profile.location;
      setMapsLocation({
        lat: loc.lat,
        lng: loc.lng,
        address: loc.address,
        city: loc.city,
        state: loc.state,
        zip: loc.zip,
      });
    }

    const geocode = async () => {
      if (location?.label) {
        const results = await geocodeByAddress(location.label);
        const result = results[0];
        const address = parseGoogleAddress(result.address_components);
        try {
          const latLng = await getLatLng(result);
          setLat(latLng.lat);
          setLng(latLng.lng);
          setMapsLocation({
            lat: latLng.lat,
            lng: latLng.lng,
            city: address.city,
            state: address.region,
            zip: address.postal_code,
            address: (address.home + ' ' + address.street).trim(),
          });
          setAddress(address);
        } catch (error) {
          console.error(error);
        }
      }
    };
    geocode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const submitHomeRequirements = async (reqs) => {
    setIsLoading(true);

    try {
      await dispatch(housingPreferences(reqs));

      toast.success('Home requirements have been saved.');
    } catch {
      messageOnlyToast(
        'An unknown server error occurred. Your home requirements have not been saved. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SettingsLayout title="Housing">
      <div className="flex flex-col content-start max-w-2xl mb-40 space-y-10 justify-items-auto">
        <div className="space-y-4">
          <div className="form-group">
            <p className="mb-2 text-lg font-semibold text-gray-700">HOUSING</p>
            {genericError && (
              <div className="mb-4">
                <StatusMessage type={StatusType.ERROR} message={genericError} />
              </div>
            )}
            <label htmlFor="status" className="text-gray-700 opacity-80">
              Housing Status:
            </label>
            <PillsInput
              onChange={(val) => setHousingSituation(val[0])}
              values={housingSituation ? [housingSituation] : []}
              options={housingSituationOptions}
              selectOne={true}
              light={true}
            />
          </div>

          <div>
            {hasOwnPlace ? (
              <>
                <AiFillHome size={24} className="inline mb-1 mr-2" /> I have a place to live with an
                extra bedroom to rent to a {type}.
              </>
            ) : (
              <>
                <AiOutlineHome size={24} className="inline mb-1 mr-2" /> I am looking for housing to
                rent with a {type}.
              </>
            )}
          </div>

          <div className="relative form-group">
            <>
              <label htmlFor="location" className="text-lg font-bold">
                {housingSituation === SITUATION_OPTIONS.LOOKING
                  ? 'Desired Location:'
                  : 'Home Address:'}
              </label>
              <GooglePlacesAutocomplete
                debounce={400}
                minLengthAutocomplete={4}
                selectProps={{
                  location: location,
                  id: 'location',
                  onChange: setLocation,
                  styles: autoCompleteStyles,
                  placeholder: mapsLocation
                    ? `${mapsLocation.address ? mapsLocation.address + ', ' : ''}${
                        mapsLocation.city && mapsLocation.city + ', '
                      }
                    ${mapsLocation.state}`
                    : 'Enter an address',
                }}
                onLoadFailed={(error) => console.error('Could not inject Google script', error)}
                data-hj-allow
              />
            </>
            <HiOutlineLocationMarker size={30} className="absolute left-2 top-9" />
            {hasOwnPlace ? (
              <div className="mb-2 text-gray-400">
                Only your approximate home location will only be seen by others.
              </div>
            ) : (
              <div className="mb-2 text-gray-400">
                Your desired location will be seen by others.
              </div>
            )}
          </div>

          <div className="relative flex-1">
            {mapConfig && !!lat && !!lng && <Map mapConfig={mapConfig} />}
          </div>

          {housingSituation === SITUATION_OPTIONS.LOOKING && !!lat && !!lng && (
            <div className="my-2 form-group">
              <div>
                <label htmlFor="radius" className="font-bold text-gray-700">
                  Max Distance:
                </label>
              </div>
              <div>
                <SliderInput
                  domain={[5, 50]}
                  step={5}
                  values={[radius]}
                  left={true}
                  right={false}
                  onSliderChange={(range) => range}
                  onSliderUpdate={(range) => {
                    setRadius(range[0]);
                  }}
                  onMouseUp={() => submitUpdatedLocation()}
                  sliderMode={(curr, next) => {
                    return next;
                  }}
                />
              </div>
              <div className="w-full text-gray-700 text-md opacity-80">
                {+radius === 50 ? (
                  <div className="w-full text-md">
                    <b>Anywhere</b> near {mapsLocation?.city}, {mapsLocation?.state}
                  </div>
                ) : (
                  <div className="w-full text-md">
                    <b>Within {radius} miles</b>{' '}
                    {mapsLocation && `of ${mapsLocation?.city}, ${mapsLocation?.state}`}
                  </div>
                )}
              </div>
            </div>
          )}

          <div>
            <label className="text-gray-700 opacity-80">Home Requirements</label>
            <CreatableSelect
              disabled={isLoading}
              options={homeReqs}
              defaultValue={homeReqs.filter(
                (ref) => currentUser.profile?.preferences?.housing?.indexOf(ref.value) > -1,
              )}
              isMulti
              name="careTasks"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              onChange={(e) => {
                let tasks = e?.map(function (item) {
                  return item['value'];
                });

                submitHomeRequirements(tasks);
              }}
              styles={reactSelectStyles}
            />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default LocationSettings;
