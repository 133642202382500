import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import LandingLayout from '../Layout/LandingLayout';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Rumi</title>
      </Helmet>
      <LandingLayout>
        <div className="text-center">
          <div>
            <Link className="button button-primary w-full" to="/login">
              Login
            </Link>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-300">
            <p>Don't have an account?</p>
            <div className="mt-2">
              <Link className="button button-secondary w-full" to="/signup">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </LandingLayout>
    </>
  );
};

export default Home;
