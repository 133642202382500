import React from 'react';

const ProgressLine: React.FC<{ isPassed: boolean }> = ({ isPassed }) => {
  return (
    <svg width="60" height="4" viewBox="0 0 60 4" fill="none">
      <rect
        width="60"
        height="4"
        rx="2"
        fill={isPassed ? '#E9F1FC' : '#ECF3F9'}
        opacity={isPassed ? 1 : 0.25}
      />
    </svg>
  );
};

export default ProgressLine;
