import { Dialog } from '@headlessui/react';
import { sendWaveMessage } from 'actions/connection';
import { hideModal } from 'actions/modals';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';

const WaveMessageModal = ({ cancelButtonRef, details }) => {
  const dispatch: AppDispatch = useDispatch();
  const [messageCharCount, setMessageCharCount] = React.useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    message: string;
  }>();

  const handleSendWaveMessage = ({ message }: { message: string }) => {
    const connectionId = details.connectionId;
    dispatch(sendWaveMessage(connectionId, message))
      .then(() => {
        toast.success('Sent message to ' + details.name + '!');
        dispatch(hideModal());
      })
      .finally(() => {
        dispatch(hideModal());
      });
  };

  const onMessageChange = (value) => {
    setMessageCharCount(value.length);
  };
  return (
    <>
      <div>
        <div className="my-3 text-center">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            You waved at {details.name}!
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-gray-500">
              Optionally, send a short message to
              <br /> {details.name} with your wave.
            </p>
          </div>
        </div>
        <form noValidate onSubmit={handleSubmit(handleSendWaveMessage)}>
          <label className="font-medium text-gray-600" htmlFor="message">
            Message:
            <textarea
              className="flex flex-1 w-full px-4 py-2 text-gray-700 placeholder-gray-400 transition bg-white border border-gray-300 rounded-lg focus:border-transparent focus:outline-none focus:ring-blue-500 focus:ring-2"
              {...register('message', {
                required: 'Please enter a message to send with your wave',
              })}
              id="message"
              onChange={(e) => onMessageChange(e.target.value)}
              rows={6}
              maxLength={180}
              data-hj-allow
            ></textarea>
            <span className="float-right text-sm text-gray-400">{messageCharCount}/180</span>
            {errors && errors['message'] && (
              <div className="text-red-400">{errors['message'].message}</div>
            )}
          </label>
        </form>
      </div>
      <div className="mt-5 sm:grid sm:gap-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:mt-6">
        <button
          type="button"
          className="inline-flex justify-center w-full button button-primary sm:col-start-2 sm:text-sm"
          onClick={() => handleSubmit(handleSendWaveMessage)()}
        >
          Send message
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full button button-secondary sm:col-start-1 sm:text-sm"
          onClick={() => dispatch(hideModal())}
          ref={cancelButtonRef}
        >
          Skip
        </button>
      </div>
    </>
  );
};

export default WaveMessageModal;
