import { addFavoriteUser, deleteFavoriteUser } from 'actions/user';
import { history } from 'helpers/history';
import { messageOnlyToast } from 'helpers/toasts';
import { usePotentialConnectionStatus } from 'helpers/usePotentialConnectionStatus';
import { usePotentialFavorite } from 'helpers/usePotentialFavorite';
import { ConnectionStatus, WaveStatus } from 'models/connectionStatus';
import { ProfileType } from 'models/profileType';
import React from 'react';
import toast from 'react-hot-toast';
import { AiOutlineStop } from 'react-icons/ai';
import { BsHouse, BsHouseFill } from 'react-icons/bs';
import { FaCheck, FaRegSmile } from 'react-icons/fa';
import { MdCake, MdPets } from 'react-icons/md';
import { RiWheelchairFill } from 'react-icons/ri';
import { TiStarFullOutline, TiStarOutline } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { ChoiceType, GenderProfile, Pet } from 'types';
import { AppDispatch } from '../../store';

const SearchCard = ({ user }) => {
  const dispatch: AppDispatch = useDispatch();

  const potentialConnectionStatus = usePotentialConnectionStatus(user);
  const potentialFavorite = usePotentialFavorite(user);

  const viewProfile = (user: any, e: any) => {
    e.stopPropagation();
    const url = '/profile/' + user.slug;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const favoriteUser = async (user: any, e) => {
    e.stopPropagation();
    await dispatch(addFavoriteUser(user)).then(
      (response) => {
        toast.success(response);
      },
      (error) => {
        if (error.statusCode === 409) {
          messageOnlyToast(error.message);
        } else {
          messageOnlyToast('Please refresh and try again.');
        }
      },
    );
  };

  const unfavoriteUser = async (user: any, e) => {
    e.stopPropagation();
    await dispatch(deleteFavoriteUser(user.id)).then(
      (response) => {
        toast.success(`${user.name} is removed from your favorites`);
      },
      (error) => {
        messageOnlyToast('Please refresh and try again.');
      },
    );
  };

  return (
    <div key={user.id}>
      <div className="flex items-start">
        <div className="w-24 h-full space-y-1 sm:w-40">
          <img
            alt="avatar"
            className="object-contain w-full overflow-hidden rounded-lg"
            src={user.image || require('../../assets/default_avatar.png').default}
          />
          <p className="text-xs font-semibold text-center">
            {user.type === ProfileType.RUMI ? 'Rumi' : 'Supportive Roommate'}
          </p>

          <div className="flex flex-col items-center font-semibold">
            {potentialConnectionStatus === WaveStatus.SENT && (
              <div className="w-full py-1 bg-gray-100 rounded-lg">
                <div className="text-xs text-center text-yellow-600 sm:text-sm md:text-base">
                  <span className="animate-wave">👋</span> Waved
                </div>
              </div>
            )}
            {potentialConnectionStatus === WaveStatus.RECEIVED && (
              <>
                <div className="w-full py-1 bg-red-600 rounded-lg">
                  <div className="text-xs text-center text-white sm:text-sm md:text-base">
                    👋 Wave back!
                  </div>
                </div>
              </>
            )}
            {potentialConnectionStatus === ConnectionStatus.IGNORED && (
              <div className="w-full py-1 bg-white border-2 border-red-500 rounded-lg">
                <div className="text-xs text-center text-red-500 sm:text-sm md:text-base">
                  Passed
                </div>
              </div>
            )}
            {potentialConnectionStatus === ConnectionStatus.ACCEPTED && (
              <div className="w-full py-1 bg-green-500 rounded-lg">
                <div className="text-xs text-center text-white sm:text-sm md:text-base">
                  🎉 Connected
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 ml-4">
          <div>
            <div className="flex justify-between">
              <div className="inline-flex flex-wrap">
                <h4 className="mr-2 text-xl font-semibold hover:underline">{user.name}</h4>
                {user.verified && (
                  <span className="flex flex-row items-center">
                    <span className="flex flex-row items-center py-1 pl-2 pr-3 text-sm text-white bg-blue-400 rounded-full">
                      <FaCheck className="mr-2" />
                      Verified
                    </span>
                  </span>
                )}
              </div>
            </div>
            <p>
              <MdCake className="inline mb-1 text-purple-600" /> {user.age} years old
            </p>
            <p className="flex items-center">
              <FaRegSmile className="inline mr-1 text-black" />
              {GenderProfile[user.gender]}
            </p>
            <div>
              {!user.pets ||
              !user.pets.length ||
              user.pets[0] === Pet.NONE ||
              user.pets[0] === ChoiceType.WANTS ? (
                <div>
                  <AiOutlineStop className="inline mb-1 text-red-600" /> Does not have pets
                </div>
              ) : (
                <div>
                  <MdPets className="inline mb-1 text-yellow-800" /> Has pet(s)
                </div>
              )}
            </div>
            <p className="flex flex-wrap">
              {user.hasHousing ? (
                <span>
                  <BsHouseFill className="inline mb-1 text-yellow-500" /> Has an extra bedroom
                </span>
              ) : (
                <span>
                  <BsHouse className="inline mb-1 text-yellow-500" /> Looking for housing
                </span>
              )}
            </p>
            <p className="flex flex-wrap">
              {user.hasHousing && user.preferences.wheelchairAcs && (
                <span className="font-semibold text-blue-600">
                  <RiWheelchairFill size={18} className="inline mb-1" /> Home is wheelchair
                  accessible
                </span>
              )}
              {!user.hasHousing && user.preferences.wheelchairAcs && (
                <span className="font-semibold text-blue-600">
                  <RiWheelchairFill size={18} className="inline mb-1" /> Needs wheelchair
                  accessibility
                </span>
              )}
            </p>
          </div>
          <div>
            <span className="font-medium">BIO</span>
            <p className="text-gray-600 line-clamp-6">
              {user.bio ? user.bio : <i className="text-gray-400">No bio yet</i>}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-row flex-wrap justify-end mt-4 sm:text-sm">
          <button className="w-full button button-primary" onClick={(e) => viewProfile(user, e)}>
            View Profile
          </button>
          {potentialFavorite ? (
            <button
              className="w-full text-gray-400 button button-subtle hover:text-gray-500 focus:text-gray-500"
              onClick={(e) => unfavoriteUser(user, e)}
            >
              <TiStarFullOutline className="mr-1 text-yellow-400" size={20} />
              Favorited
            </button>
          ) : (
            <button
              className="w-full button button-secondary"
              onClick={(e) => favoriteUser(user, e)}
            >
              <TiStarOutline className="mr-1" size={20} />
              Favorite
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
