import { getMyConnections, readConnection, unmatch } from 'actions/connection';
import { fetchMessages, sendMessage } from 'actions/message';
import { reportUser } from 'actions/user';
import classNames from 'classnames';
import DropdownMenu from 'components/Common/DropdownMenu';
import Modal from 'components/Common/Modal';
import Spinner from 'components/Common/Spinner';
import { history } from 'helpers/history';
import { messageOnlyToast, waveToast } from 'helpers/toasts';
import useConnectedUser from 'helpers/useConnectedUser';
import { isMobileOrTablet } from 'helpers/utils';
import { ConnectionStatus } from 'models/connectionStatus';
import { ROLE } from 'models/roles';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown, BiLeftArrowAlt } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { ImUndo } from 'react-icons/im';
import { MdReport } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'reducers';
import {
  getConnectionBySlug,
  getMessagesForConnection,
  getUnreadConnections,
  getUnreadMessages,
} from 'selectors';
import { AppDispatch } from 'store';
import { ProfileStatus } from 'types';
import SingleMessage from './SingleMessage';

const AlwaysScrollToBottom = () => {
  const elementRef: any = useRef();
  useEffect(() => elementRef?.current?.scrollIntoView());
  return <div ref={elementRef} />;
};

const Message = () => {
  const { slug }: any = useParams();
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const connection: any = useSelector((state: RootState) => getConnectionBySlug(state, slug));
  const initiator = connection?.initiator.id === currentUser.id;
  const textInput: any = useRef(null);

  const connectedUser = useConnectedUser(+connection?.id);

  const messages = useSelector((state: RootState) =>
    getMessagesForConnection(state, connection?.id),
  );
  const unreadConnections = useSelector(getUnreadConnections);
  const unreadMessages = useSelector(getUnreadMessages);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  // const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const isMobileDevice = isMobileOrTablet();

  const [showReportModal, setShowReportModal] = useState(false);
  const {
    register: reportUserRegister,
    handleSubmit: handleSubmitReportUser,
    formState: { errors: reportUserErrors },
  } = useForm<{
    reportReason: string;
  }>();

  const [showUnmatchModal, setShowUnmatchModal] = useState(false);
  const {
    register: unmatchUserRegister,
    handleSubmit: handleSubmitUnmatchUser,
    formState: { errors: unmatchUserErrors },
  } = useForm<{
    unmatchReason: string;
  }>();

  useEffect((): any => {
    let mounted = true;

    if (mounted) {
      const getMessages = async () => {
        setLoading(true);
        try {
          dispatch(fetchMessages(connection.id, currentUser));
          dispatch(readConnection(connection.id, currentUser));
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      getMessages();
      if (!isMobileDevice) textInput.current?.focus();
      return () => (mounted = false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection?.id, dispatch]);

  useEffect((): any => {
    let mounted = true;

    if (currentUser.profile.status !== ProfileStatus.PAUSED) {
      if (mounted) {
        if (
          unreadConnections.some((conn: any) => conn.id === connection?.id) ||
          unreadMessages.some((message: any) => message.id === connection?.id)
        ) {
          dispatch(readConnection(connection.id, currentUser));
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadConnections, unreadMessages, connection?.id, dispatch]);

  useEffect((): any => {
    let mounted = true;
    if (mounted) {
      if (messages) {
        setTimeout(() => setLoading(false), 800);
      }
    }
    return () => (mounted = false);
  }, [messages]);

  const onSendMessage = () => {
    if (!!newMessage.trim() && !loading) {
      dispatch(sendMessage(connection.id, newMessage));
      setNewMessage('');
    }
  };

  const onKeyUpValue = (event: any) => {
    if (event.keyCode === 13) {
      onSendMessage();
    }
  };

  const handleReportUser = ({ reportReason }: { reportReason: string }) => {
    dispatch(reportUser(reportReason, connectedUser.id))
      .then(() => {
        setShowReportModal(false);
        messageOnlyToast(`You reported ${connectedUser?.firstName}`);
        dispatch(getMyConnections()).then(() => {
          history.replace('/connections/messages');
        });
      })
      .catch((error) => {
        setShowReportModal(false);
        waveToast(error);
      });
  };

  const handleUnmatchUser = ({ unmatchReason }: { unmatchReason: string }) => {
    dispatch(unmatch(unmatchReason, connection.id))
      .then(() => {
        setShowUnmatchModal(false);
        messageOnlyToast(`You unmatched with ${connectedUser?.firstName}`);
        dispatch(getMyConnections()).then(() => {
          history.replace('/connections/messages');
        });
      })
      .catch((error) => {
        setShowUnmatchModal(false);
        waveToast(error);
      });
  };

  const messageOptions = [
    [
      {
        label: `View ${connectedUser?.firstName}'s Profile`,
        value: () => history.push('/profile/' + connectedUser?.slug),
      },
    ],
    // [
    //   {
    //     label: 'Invite Rumi Connector',
    //     value: '',
    //   },
    //   {
    //     label: 'Request a Zoom call',
    //     value: '',
    //   },
    // ],
    [
      {
        label: `Unmatch with ${connectedUser?.firstName}`,
        value: () => {
          setShowUnmatchModal(true);
        },
        className: 'text-red-500 hover:text-red-600',
      },
      {
        label: `Report ${connectedUser?.firstName}`,
        value: () => {
          setShowReportModal(true);
        },
        className: 'text-red-500 hover:text-red-600',
      },
    ],
  ];

  return (
    <>
      <div className={classNames(isMobileDevice && 'fixed z-10 inset-0', 'message w-full h-full')}>
        <div className="flex flex-1 h-full overflow-hidden">
          <div className="flex flex-col flex-1 h-full bg-white border-r">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex items-center justify-between p-4 shadow-md select-none">
                  <button
                    className="flex items-center justify-center p-2 sm:hidden"
                    onClick={() => history.replace('/connections/messages')}
                  >
                    <BiLeftArrowAlt size={28} />
                  </button>
                  <div
                    onClick={() => history.push(`/profile/${connectedUser?.slug}`)}
                    className="items-center px-3 text-2xl font-semibold bg-white cursor-pointer hover:underline"
                  >
                    {connectedUser?.firstName}
                  </div>
                  {connectedUser?.status !== ConnectionStatus.ADMIN && (
                    <div>
                      <DropdownMenu menuItems={messageOptions}>
                        <div>Options</div>
                        <BiChevronDown size={26} />
                      </DropdownMenu>
                    </div>
                  )}
                </div>

                <div className="flex flex-1 overflow-x-hidden overflow-y-scroll">
                  <div className="flex flex-col w-full px-5 mt-auto">
                    <div className="flex flex-col mt-5">
                      {connectedUser?.roles?.includes(ROLE.ADMIN) && (
                        <div className="flex flex-row items-center justify-center px-4 mx-auto mb-1 text-sm text-center text-gray-500 select-none">
                          This is a private conversation with you and the Rumi Team.
                        </div>
                      )}
                      <div>
                        {initiator ? (
                          <>
                            <SingleMessage
                              message={{ content: '👋', id: 0 }}
                              user={connection?.initiator}
                              emoji
                              isOwn
                            />
                            <SingleMessage
                              message={{ content: '👋', id: 1 }}
                              user={connection?.receiver}
                              emoji
                            />
                          </>
                        ) : (
                          <>
                            <SingleMessage
                              message={{ content: '👋', id: 0 }}
                              user={connection?.initiator}
                              emoji
                            />
                            <SingleMessage
                              message={{ content: '👋', id: 1 }}
                              user={connection?.receiver}
                              emoji
                              isOwn
                            />
                          </>
                        )}
                      </div>
                      {messages.map((m: any) => (
                        <div key={m.id}>
                          {m.userId === currentUser.id ? (
                            <SingleMessage message={m} user={currentUser} isOwn />
                          ) : (
                            <SingleMessage message={m} user={connectedUser} />
                          )}
                        </div>
                      ))}

                      {connectedUser?.profile?.status === ProfileStatus.PAUSED && (
                        <div className="p-6 mb-5 font-semibold bg-gray-100 border-2 border-yellow-400 rounded-3xl">
                          {connectedUser?.firstName} took a break from searching for a roommate on
                          Rumi. You will still be able to send them a message, but{' '}
                          {connectedUser?.firstName} will not be able to respond until they unpause
                          their account.
                        </div>
                      )}

                      <AlwaysScrollToBottom />
                    </div>
                  </div>
                </div>
                {isMobileDevice && <div className="h-20"></div>}
                <div
                  className={classNames(
                    isMobileDevice ? 'absolute z-30 bottom-0' : '',
                    'flex p-2 w-full bg-white border-t',
                  )}
                >
                  <input
                    className="flex-1 px-3 py-4 mr-2 bg-gray-200 rounded-2xl focus:outline-none focus:ring-4"
                    type="text"
                    placeholder="Type your message here"
                    value={newMessage}
                    ref={textInput}
                    // onFocus={() => handleInputFocus()}
                    // onBlur={() => handleInputBlur()}
                    onKeyUp={(e) => onKeyUpValue(e)}
                    onChange={(e) => setNewMessage(e.target.value)}
                    data-hj-allow
                  />
                  <button
                    className="flex items-center justify-center px-4 font-semibold text-white bg-blue-400 select-none whitespace-nowrap hover:bg-blue-500 rounded-2xl focus:outline-none active:ring-4 focus:ring-4"
                    aria-label="Submit"
                    onClick={() => onSendMessage()}
                  >
                    Send
                    <FiSend className="hidden ml-1.5 lg:block" size={20} />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showUnmatchModal}
        hideAction={() => setShowUnmatchModal(false)}
        onSecondary={() => setShowUnmatchModal(false)}
        onConfirm={() => handleSubmitUnmatchUser(handleUnmatchUser)()}
        loading={loading}
        title={`Unmatch with ${connectedUser?.firstName}?`}
        icon={(className: string) => <ImUndo className={className} />}
        lockOpen={true}
        confirmLabel={'Yes, unmatch'}
        cancelLabel={'No, stay connected'}
      >
        <div className="w-full">
          <p className="mb-4 text-gray-600">
            Are you sure you would like to unmatch with {connectedUser?.firstName}?{' '}
            <b>You won't be able to match with them again.</b>
          </p>
          <form noValidate onSubmit={handleSubmitUnmatchUser(handleUnmatchUser)}>
            <label className="text-gray-600" htmlFor="unmatchReason">
              Reason for unmatching:
              <textarea
                className="block w-full px-3 py-2 placeholder-gray-400 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                {...unmatchUserRegister('unmatchReason', {
                  required: `Please tell us your reason for unmatching with ${connectedUser?.firstName}.`,
                })}
                id="unmatchReason"
                rows={6}
                data-hj-allow
              />
              {unmatchUserErrors && unmatchUserErrors['unmatchReason'] && (
                <div className="text-red-400">{unmatchUserErrors['unmatchReason'].message}</div>
              )}
            </label>
          </form>
        </div>
      </Modal>

      <Modal
        show={showReportModal}
        hideAction={() => setShowReportModal(false)}
        onSecondary={() => setShowReportModal(false)}
        onConfirm={() => handleSubmitReportUser(handleReportUser)()}
        loading={loading}
        title={`Report ${connectedUser?.firstName}`}
        icon={(className: string) => <MdReport className={className} />}
        lockOpen={true}
        confirmLabel={'Send report'}
      >
        <div className="w-full">
          <p className="mb-4">
            If you have any issues with this person, please tell us what happened.
          </p>
          <form noValidate onSubmit={handleSubmitReportUser(handleReportUser)}>
            <label className="text-gray-600" htmlFor="reportReason">
              Reason for reporting:
              <textarea
                className="block w-full px-3 py-2 placeholder-gray-400 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                {...reportUserRegister('reportReason', {
                  required: `Please tell us your reason for reporting ${connectedUser?.firstName}.`,
                })}
                id="reportReason"
                rows={6}
                data-hj-allow
              />
              {reportUserErrors && reportUserErrors['reportReason'] && (
                <div className="text-red-400">{reportUserErrors['reportReason'].message}</div>
              )}
            </label>
          </form>
          <p className="mt-4 text-sm text-gray-500">
            By clicking <b>Send report</b>, you will unmatch with {connectedUser?.firstName} and you
            won't see or be able to talk to them again.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Message;
