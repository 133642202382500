import { defaultOnboardingState } from 'models/onboardingState';

export const getOnboardingLocalStorage = () => {
  try {
    let onboarding = JSON.parse(localStorage.getItem('onboarding')!);
    if (!onboarding) {
      onboarding = defaultOnboardingState;
      localStorage.setItem('onboarding', JSON.stringify(onboarding));
    }
    return onboarding;
  } catch (e) {
    localStorage.setItem('onboarding', JSON.stringify(defaultOnboardingState));
    return defaultOnboardingState;
  }
};
