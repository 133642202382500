import FormField from 'components/Common/FormField';
import LoadingButton from 'components/Common/LoadingButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CareHours as CareHoursEnum, CareTasks as CareTasksEnum } from 'types';
import { careHours, careTasks } from '../../actions/onboarding';
import { AppDispatch } from '../../store';
import PillsInput, { PillOption, PillOptions } from '../Common/PillsInput';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';

type Props = {
  history: any;
};

const careHoursOptions: PillOption[] = [
  { label: 'Less than 10 hours', value: CareHoursEnum.LESS_THAN_10_HOURS },
  { label: '10-20 hours', value: CareHoursEnum.BETWEEN_10_AND_20_HOURS },
  { label: '20-30 hours', value: CareHoursEnum.BETWEEN_20_AND_30_HOURS },
  { label: '30-40 hours', value: CareHoursEnum.BETWEEN_30_AND_40_HOURS },
  { label: '40+ hours', value: CareHoursEnum.MORE_THAN_40 },
  { label: "I'm not sure", value: CareHoursEnum.NOT_SURE },
  { label: 'No preference', value: CareHoursEnum.NO_PREFERENCE },
];

const careTaskOptions: PillOptions = [
  { label: 'Budgeting', value: CareTasksEnum.BUDGETING },
  { label: 'Companionship', value: CareTasksEnum.COMPANIONSHIP },
  { label: 'Coping skills', value: CareTasksEnum.COPING_SKILLS },
  { label: 'Household tasks', value: CareTasksEnum.CLEANING },
  { label: 'Medication', value: CareTasksEnum.MEDICATION },
  { label: 'Meal prep', value: CareTasksEnum.MEAL_PREP },
  { label: 'Mobility', value: CareTasksEnum.MOBILITY },
  { label: 'Personal care', value: CareTasksEnum.PERSONAL_CARE },
  { label: 'Problem solving', value: CareTasksEnum.PROBLEM_SOLVING },
  { label: 'Transportation', value: CareTasksEnum.TRANSPORTATION },
  { label: "I'm not sure", value: CareTasksEnum.NOT_SURE },
  { label: 'Other', value: CareTasksEnum.OTHER },
];

const CareHours = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const type = currentUser.profile.type;
  const alreadyMade = currentUser.profile.alreadyMade;

  const [selectedOptions, setSelectedOptions] = useState<CareHoursEnum[]>([]);

  const [selectedTasksOptions, setSelectedTasksOptions] = useState<CareTasksEnum[]>([]);
  const [tasksOther, setTasksOther] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState('');
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const references: any[] = careHoursOptions.map((ref) => ref.value);
    const selectedReferences = references.filter((ref) =>
      currentUser?.profile?.careInfo?.hours?.includes(ref),
    );

    setSelectedOptions(selectedReferences);
  }, [currentUser]);

  useEffect(() => {
    const references: any[] = careTaskOptions.map((ref) => ref.value);
    const selectedReferences = references.filter((ref) =>
      currentUser?.profile?.careInfo?.tasks?.includes(ref),
    );
    const otherReference = currentUser?.profile?.careInfo?.tasks?.find(
      (ref) => !references.includes(ref),
    );

    if (otherReference) {
      selectedReferences.push(CareTasksEnum.OTHER);
      setTasksOther(otherReference);
    }

    setSelectedTasksOptions(selectedReferences);
  }, [currentUser]);

  const onChangeOptions = (options: string[]) => {
    setSelectedOptions(options as CareHoursEnum[]);
  };

  const onChangeTasksOptions = (options: string[]) => {
    setSelectedTasksOptions(options as CareTasksEnum[]);
  };

  const onChangeTasksOther = (value: string) => {
    setTasksOther(value);
  };

  const submitCareHours = async () => {
    const hours = selectedOptions[0];
    const tasks: string[] = selectedTasksOptions;
    setGenericError('');
    setIsLoading(true);

    if (tasks.includes(CareTasksEnum.OTHER) && tasksOther) {
      // remove OTHER
      const otherIndex = tasks.indexOf(CareTasksEnum.OTHER);
      tasks.splice(otherIndex, 1);

      tasks.push(tasksOther);
    }

    try {
      await dispatch(careHours(hours));
      await dispatch(careTasks(tasks));
      history.push('/onboarding');
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const title = alreadyMade
    ? 'How many hours of care are you currently providing per week?'
    : type === 'CAREGIVER'
    ? 'How many hours of support can you provide per week?'
    : 'How much support do you get during the week?';

  const tasksTitle = alreadyMade
    ? 'What tasks do you help with on a daily basis?'
    : type === 'CAREGIVER'
    ? 'What tasks can you help with on a daily basis?'
    : 'What tasks do you need help with daily?';

  const placeholder = alreadyMade
    ? 'Please list other tasks you help with'
    : type === 'CAREGIVER'
    ? 'Please list other tasks you can help with'
    : 'Please list other tasks you need help with';

  let options = careHoursOptions.filter((o) => {
    if (type === 'CAREGIVER') {
      return true;
    } else {
      return o.value !== CareHoursEnum.NO_PREFERENCE;
    }
  });

  return (
    <>
      <OnboardingLayout title={title}>
        <PillsInput
          onChange={onChangeOptions}
          options={options}
          values={selectedOptions}
          selectOne={true}
        />

        <p className="mt-8 text-3xl font-bold text-white sm:mb-4 sm:text-4xl">{tasksTitle}</p>
        <PillsInput
          onChange={onChangeTasksOptions}
          values={selectedTasksOptions}
          options={careTaskOptions}
          selectOne={false}
        />
        {selectedTasksOptions.includes(CareTasksEnum.OTHER) && (
          <FormField
            type="text"
            name="other"
            placeholder={placeholder}
            value={tasksOther}
            className="max-w-md"
            onChange={(e) => onChangeTasksOther(e.target.value)}
            data-hj-allow
          />
        )}

        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <LoadingButton
          isLoading={isLoading}
          disabled={isEmpty(selectedOptions)}
          onClick={submitCareHours}
          className="hidden w-full mt-6 text-lg md:flex"
          variant="primary-white"
        >
          Save and continue
        </LoadingButton>
      </OnboardingLayout>
      <div className="fixed bottom-0 w-full px-2 bg-white md:hidden">
        <LoadingButton
          isLoading={isLoading}
          disabled={isEmpty(selectedOptions)}
          onClick={submitCareHours}
          className="w-full text-lg"
          variant="primary"
        >
          Save and continue
        </LoadingButton>
      </div>
    </>
  );
};

export default CareHours;
