import classNames from 'classnames';
import SliderInput from 'components/Common/Slider';
import { history } from 'helpers/history';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Form from 'react-validation/build/form';
import { RootState } from 'reducers';
import { GenderPreference, PetsPreference, WheelchairPreference } from 'types';

type PetsOption = {
  value: PetsPreference | null;
  label: string;
};

type GenderOption = {
  value: GenderPreference | null;
  label: string;
};

type WheelchairOption = {
  value: WheelchairPreference | null;
  label: string;
};

export type FilterFormState = {
  maxDistance: number[] | null;
  gender: GenderOption | null;
  pets: PetsOption | null;
  ageRange: number[] | null;
  wheelchair: WheelchairOption | null;
};

export type FilterValues = {
  maxDistance: number[] | null;
  gender: GenderPreference | null;
  pets: PetsPreference | null;
  ageRange: number[] | null;
  wheelchair: WheelchairPreference | null;
};

type PropTypes = {
  displayFilters: boolean;
  disabled: boolean;
  filters: any;
  users: any[];
  onSubmit: (data: Partial<FilterValues>) => void;
  onHideFilters: () => void;
  location: string;
};

const genderOptions = [
  { value: GenderPreference.NO_PREFERENCE, label: 'No preference' },
  { value: GenderPreference.MALE, label: 'Male roommate' },
  { value: GenderPreference.FEMALE, label: 'Female roommate' },
  { value: GenderPreference.NONBINARY, label: 'Non-Binary roommate' },
];

const petsOptions = [
  { value: PetsPreference.NO_PREFERENCE, label: 'No preference' },
  { value: PetsPreference.NONE, label: 'No pets' },
  { value: PetsPreference.CAT, label: 'No cats' },
  { value: PetsPreference.DOG, label: 'No dogs' },
];

const wheelchairOptions = [
  { value: WheelchairPreference.NO_PREFERENCE, label: 'No preference' },
  { value: WheelchairPreference.REQUIRED, label: 'Required' },
];

const reactSelectStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#6B7280' : '#9CA3AF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    background: 'white',
    outline: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#4B5563' : '#D1D5DB',
    borderRadius: 8,
    boxShadow: '0 4px 2px -2px rgba(0,0,0,0.1)',
    '&:hover': {},
    padding: 2,
    marginTop: 1,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 999,
  }),
};

const SearchFilters = ({
  displayFilters,
  disabled,
  onSubmit,
  filters,
  users,
  onHideFilters,
  location,
}: PropTypes) => {
  const defaultValues = {
    pets: petsOptions.find((pet) => pet.value === filters.pets),
    gender: genderOptions.find((gender) => gender.value === filters.gender),
    maxDistance: filters.maxDistance || [50],
    ageRange: filters.ageRange,
    wheelchair: wheelchairOptions.find((opt) => opt.value === filters.wheelchair),
  };

  const defaultValuesOptions = {
    pets: petsOptions[0],
    gender: genderOptions[0],
    maxDistance: [50],
    ageRange: [18, 99],
    wheelchair: wheelchairOptions[0],
  };

  const filterStorage = JSON.parse(sessionStorage.getItem('searchFilters')!);
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const hasHousing = !!currentUser.profile?.location?.housing;
  const form: Form = useRef();
  const [ageRangeVal, setAgeRangeVal] = useState<readonly number[]>(
    filterStorage?.ageRange?.length ? filterStorage?.ageRange : defaultValuesOptions.ageRange,
  );
  const [radiusRangeVal, setRadiusRangeVal] = useState<readonly number[]>(
    filterStorage?.maxDistance?.length
      ? filterStorage?.maxDistance
      : defaultValuesOptions.maxDistance,
  );

  const { control, watch, setValue } = useForm<FilterFormState>({ defaultValues });

  const { pets, gender, maxDistance, ageRange, wheelchair } = watch();

  useEffect(() => {
    if (!disabled) {
      submitSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pets, gender, ageRange, maxDistance, wheelchair]);

  const submitSearch = () => {
    if (disabled) {
      return;
    }
    const filterValues: Partial<FilterValues> = {};
    if (pets) {
      filterValues.pets = pets.value;
    }
    if (gender) {
      filterValues.gender = gender.value;
    }
    if (maxDistance) {
      filterValues.maxDistance = maxDistance;
    }
    if (ageRange) {
      filterValues.ageRange = ageRange;
    }
    if (wheelchair) {
      filterValues.wheelchair = wheelchair.value;
    }

    onSubmit(filterValues);
  };

  const resetFields = () => {
    setValue('gender', defaultValuesOptions.gender);
    setValue('pets', defaultValuesOptions.pets);
    setValue('maxDistance', defaultValuesOptions.maxDistance);
    setValue('ageRange', defaultValuesOptions.ageRange);
    setValue('wheelchair', defaultValuesOptions.wheelchair);

    setAgeRangeVal([18, 99]);
    setRadiusRangeVal([50]);
    sessionStorage.removeItem('searchFilters');
    sessionStorage.removeItem('scrollState');

    submitSearch();
  };

  return (
    <div
      id="searchFilters"
      className={classNames(
        !displayFilters && 'hidden',
        'flex flex-col w-full h-full bg-white border-r lg:flex lg:w-80',
      )}
    >
      <div className="select-none header">
        <nav className="flex flex-row items-center bg-white border-b">
          <button className="tab active">Preferences</button>
          <button onClick={() => onHideFilters()} className="tab lg:hidden">
            Search Results
          </button>
        </nav>
      </div>
      <div className="flex flex-col justify-between flex-1 h-full overflow-y-auto">
        <form ref={form} className="p-4 space-y-4">
          <div className="form-group">
            <label htmlFor="pets">Pets:</label>
            <Controller
              name="pets"
              control={control}
              render={({ field }) => (
                <Select
                  isDisabled={disabled}
                  isSearchable={false}
                  options={petsOptions}
                  styles={reactSelectStyles}
                  {...field}
                />
              )}
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender:</label>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <Select
                  isDisabled={disabled}
                  isSearchable={false}
                  options={genderOptions}
                  styles={reactSelectStyles}
                  {...field}
                />
              )}
            />
          </div>
          <div className="form-group">
            <label htmlFor="wheelchair">Wheelchair Accessibility:</label>
            <Controller
              name="wheelchair"
              control={control}
              render={({ field }) => (
                <Select
                  isSearchable={false}
                  isDisabled={disabled}
                  options={wheelchairOptions}
                  styles={reactSelectStyles}
                  {...field}
                />
              )}
            />
          </div>

          <div className="relative form-group">
            <label htmlFor="ageRange">Age Range:</label>
            <p className="absolute top-0 right-0 text-md">
              {ageRangeVal[0]}-{ageRangeVal[1]} years old
            </p>
            <Controller
              name="ageRange"
              control={control}
              render={({ field: { onChange, value } }: any) => (
                <>
                  <SliderInput
                    domain={[18, 99]}
                    step={1}
                    values={value}
                    left={false}
                    right={false}
                    onSliderChange={onChange}
                    onSliderUpdate={(range) => setAgeRangeVal(range)}
                    sliderMode={(curr, next) => {
                      // for a range slider (two handles) with min separation of 12 and not allowing handles to cross over each other
                      const [{ val: val1 }, { val: val2 }] = next;

                      for (let i = 0; i < curr.length; i++) {
                        if (Math.abs(val2 - val1) < 12) {
                          return curr;
                        }

                        if (curr[i].key !== next[i].key) {
                          return curr;
                        }

                        if (next[i + 1] && next[i].val === next[i + 1].val) {
                          return curr;
                        }
                      }

                      return next;
                    }}
                  />
                </>
              )}
            />
          </div>

          {!hasHousing && (
            <>
              <div className="relative form-group">
                <label htmlFor="maxDistance">Max Distance:</label>
                <p className="absolute top-0 right-0 text-md">{+radiusRangeVal[0]} mile radius</p>
                <Controller
                  name="maxDistance"
                  control={control}
                  render={({ field: { onChange, value } }: any) => (
                    <>
                      <SliderInput
                        domain={[5, 50]}
                        step={5}
                        values={value}
                        left={true}
                        right={false}
                        onSliderChange={onChange}
                        onSliderUpdate={(range) => setRadiusRangeVal(range)}
                        sliderMode={(curr, next) => {
                          return next;
                        }}
                      />
                    </>
                  )}
                />
              </div>

              <div className="relative">
                <label>Location:</label>
                <p className="absolute top-0 right-0 text-md">
                  {+radiusRangeVal[0] !== 50 ? (
                    <>
                      <b>{location}</b>{' '}
                      <button className="link" onClick={() => history.push('/settings/housing')}>
                        Edit
                      </button>
                    </>
                  ) : (
                    <b>Anywhere</b>
                  )}
                </p>
              </div>
            </>
          )}

          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => resetFields()}
              disabled={disabled}
              className="w-1/2 button button-secondary"
            >
              Reset filters
            </button>
            <button
              type="button"
              disabled={disabled}
              onClick={() => onHideFilters()}
              className="w-full button button-primary lg:hidden"
            >
              Show {+users?.length} results
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchFilters;
