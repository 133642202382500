import { HIDE_MODAL, SHOW_MODAL } from 'actions/types';

const initialState = {
  modalType: null,
  isOpen: false,
  details: {},
  onConfirm: () => {},
  onCancel: () => {},
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        isOpen: true,
        modalType: action.modalType,
        details: action.details,
        onConfirm: action.onConfirm,
        onCancel: action.onCancel,
        type: action.type,
      };
    case HIDE_MODAL:
      return {
        ...state,
        isOpen: false,
        onConfirm: () => {},
        onCancel: () => {},
      };
    default:
      return state;
  }
};

export default modals;
