import React from 'react';
import classNames from 'classnames';
import { RiLoader5Line } from 'react-icons/ri';

export type LoadingButtonProps = {
  isLoading: boolean;
  variant?: string;
  className?: string;
  [x: string]: any;
};

const LoadingButton = ({
  isLoading,
  variant = 'primary',
  className,
  children,
  ...rest
}: LoadingButtonProps) => {
  const buttonClass = classNames('button', `button-${variant}`, className);
  return (
    <button className={buttonClass} disabled={isLoading} {...rest}>
      {isLoading ? <RiLoader5Line className="h-full animate-spin" size={28} /> : children}
    </button>
  );
};

export default LoadingButton;
