import {
  ADD_EVENT,
  FETCH_ADMIN_MESSAGES_SUCCESS,
  NEW_ADMIN_MESSAGE,
  NEW_ADMIN_MESSAGE_CONNECTION,
  SET_CONNECTIONS,
  SET_REPORTS,
  SET_USERS,
  SET_USER_EVENTS,
} from 'actions/types';
import { groupById } from 'helpers/groupBy';
import {
  ADMIN_NOTIFICATIONS,
  ADMIN_POTENTIAL_NOTIFICATION_USERS,
  ADMIN_DELETE_MESSAGE,
} from './../actions/types';

const initialState = {
  events: {} as any,
  users: {} as any,
  connections: {} as any,
  notifications: [],
  potentialNotificationUsers: [],
  messages: {} as any,
  reports: {} as any,
};

const admin = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_EVENTS: {
      const { events } = payload;
      return {
        ...state,
        events: {
          ...state.events,
          ...groupById(events),
        },
      };
    }
    case ADD_EVENT: {
      const event = payload;
      return {
        ...state,
        events: {
          ...state.events,
          [event.id]: event,
        },
      };
    }
    case SET_USERS:
      return {
        ...state,
        users: groupById(action.payload),
      };
    case SET_CONNECTIONS:
      return {
        ...state,
        connections: action.payload,
      };
    case SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case NEW_ADMIN_MESSAGE_CONNECTION: {
      return {
        ...state,
        users: {
          ...state.users,
          [payload.message.userId]: {
            ...state.users[payload.message.userId],
            adminConnections: state.users[payload.message.userId]?.adminConnections?.map((con) =>
              con.id === payload.connection.id ? payload.connection : con,
            ),
          },
        },
      };
    }
    case NEW_ADMIN_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [payload.message.id]: payload.message,
        },
      };
    case FETCH_ADMIN_MESSAGES_SUCCESS: {
      const messages = groupById(payload);
      return { ...state, messages: messages };
    }
    case ADMIN_NOTIFICATIONS: {
      return {
        ...state,
        notifications: payload,
      };
    }
    case ADMIN_DELETE_MESSAGE: {
      return {
        ...state,
      };
    }

    case ADMIN_POTENTIAL_NOTIFICATION_USERS: {
      return {
        ...state,
        potentialNotificationUsers: payload,
      };
    }
    default:
      return state;
  }
};

export default admin;
