import { CommType } from './../../../server/src/admin/comm-type.enum';
import axios from './api.service';

const ADMIN_API_URL = '/admin/';

const getAllUsers = () => {
  return axios.get(ADMIN_API_URL + 'users');
};

const getDeniedUsers = () => {
  return axios.get(ADMIN_API_URL + 'users/denied');
};

const getNonDeniedUsers = () => {
  return axios.get(ADMIN_API_URL + 'users/nondenied');
};

const getReferencesReport = () => {
  return axios.get(ADMIN_API_URL + 'report/references');
};

const getUsersReport = () => {
  return axios.get(ADMIN_API_URL + 'report/users');
};

const getUser = (id: number) => {
  return axios.get(ADMIN_API_URL + 'users/' + id);
};

const getAllReports = () => {
  return axios.get(ADMIN_API_URL + 'reports');
};

const getReport = (id: number) => {
  return axios.get(ADMIN_API_URL + 'reports/' + id);
};

const adminUpdateUser = (
  id: number,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  textNotification: boolean,
  emailNotification: boolean,
) => {
  return axios.put(ADMIN_API_URL + 'users/' + id, {
    firstName,
    lastName,
    phone,
    email,
    textNotification,
    emailNotification,
  });
};

const adminUpdateUserProfile = (id: number, data: any) => {
  return axios.put(ADMIN_API_URL + 'users/' + id + '/profile', data);
};

const adminUpdateUserContact = (id: number, data: any) => {
  return axios.put(ADMIN_API_URL + 'users/' + id + '/contact', data);
};

const getUserConnections = (id: number) => {
  return axios.get(ADMIN_API_URL + 'users/' + id + '/connections');
};

const getAllConnections = () => {
  return axios.get(ADMIN_API_URL + 'connections');
};

const getConnection = (id: number) => {
  return axios.get(ADMIN_API_URL + 'connection/' + id);
};

const createNote = (userId: number, note: string) => {
  return axios.post(ADMIN_API_URL + 'users/' + userId + '/notes', {
    content: note,
  });
};

const createConnectionNote = (connectionId: number, note: string) => {
  return axios.post(ADMIN_API_URL + 'connection/' + connectionId + '/notes', {
    content: note,
  });
};

const getUserEvents = (userId: number) => {
  return axios.get(ADMIN_API_URL + 'users/' + userId + '/events');
};

const deleteUser = (id: number) => {
  return axios.delete(ADMIN_API_URL + 'users/' + id);
};

const createConnection = (id: number) => {
  return axios.post(ADMIN_API_URL + 'users/connection/' + id, id);
};

const shareUser = (id: number, shareUserId: number) => {
  return axios.post(ADMIN_API_URL + 'users/' + id + '/share', { shareUserId });
};

const loginAsUserId = async (id: number) => {
  return axios.post(ADMIN_API_URL + 'users/' + id + '/login').then((response) => {
    if (response.status === 200) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });
};

const marketingSendPush = async (type: CommType, options: any, copy: string) => {
  return axios
    .post(ADMIN_API_URL + 'notifications/' + type + '/send', { options, copy })
    .then((response) => {
      console.log('Successfully sent push notification!');
    })
    .catch((err) => {
      console.error('Failed to send push notifications, err:', err);
    });
};

const marketingSendText = async (type: CommType, options: any, copy: string) => {
  return axios
    .post(ADMIN_API_URL + 'notifications/' + type + '/send', { options, copy })
    .then((response) => {
      console.log('Successfully sent text notifications!');
    })
    .catch((err) => {
      console.error('Failed to send text notifications, err:', err);
    });
};

const fetchAdminNotificationHistory = (type: CommType) => {
  return axios.get(ADMIN_API_URL + 'notifications/' + type).then((response) => {
    return response.data;
  });
};

const fetchPotentialUsersForNotification = (options: any, type: CommType) => {
  return axios
    .post(ADMIN_API_URL + 'notifications/' + type + '/users', { options })
    .then((response) => {
      return response.data;
    });
};

const deleteMessage = (messageId: number) => {
  return axios.delete(ADMIN_API_URL + '/messages/' + messageId);
};

const adminService = {
  getAllUsers,
  getDeniedUsers,
  getNonDeniedUsers,
  getUser,
  getUserConnections,
  getReferencesReport,
  getUsersReport,
  createNote,
  getUserEvents,
  getAllConnections,
  adminUpdateUser,
  adminUpdateUserProfile,
  adminUpdateUserContact,
  getAllReports,
  deleteUser,
  shareUser,
  loginAsUserId,
  getConnection,
  createConnectionNote,
  getReport,
  createConnection,
  marketingSendPush,
  marketingSendText,
  fetchAdminNotificationHistory,
  fetchPotentialUsersForNotification,
  deleteMessage,
};

export default adminService;
