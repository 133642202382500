import { updateProfile } from 'actions/user';
import Modal from 'components/Common/Modal';
import Map from 'components/Settings/Map';
import { history } from 'helpers/history';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineFieldTime, AiOutlineStop } from 'react-icons/ai';
import { BiDrink, BiHeart, BiHome, BiPencil } from 'react-icons/bi';
import { FaCat, FaDog, FaRegSmile, FaSmile } from 'react-icons/fa';
import { FiClock, FiMapPin } from 'react-icons/fi';
import { GiPerson, GiSittingDog } from 'react-icons/gi';
import { GrWheelchairActive } from 'react-icons/gr';
import { HiPencil } from 'react-icons/hi';
import { MdPets, MdSmokingRooms } from 'react-icons/md';
import { RiUserHeartLine } from 'react-icons/ri';
import { SiGumtree } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import {
  CareHoursProfile,
  CareTasksProfile,
  GenderPreference,
  GenderProfile,
  HousingRequirementsProfile,
  PetProfile,
  PetsPreference,
} from 'types';
import CollapsibleCard from './CollapsibleCard';
import ProfileAboutMeCareHours from './ProfileAboutMeCareHours';
import ProfileAboutMeCareReqs from './ProfileAboutMeCareTasks';
import ProfileAboutMeHomeReqs from './ProfileAboutMeHomeReqs';
import ProfileAboutMePets from './ProfileAboutMePets';
import ProfileAboutMePreferences from './ProfileAboutMePreferences';

const PetsBlock = ({ profile }) => {
  if (
    (profile.preferences.pets?.includes(PetsPreference.DOG) &&
      profile.preferences.pets?.includes(PetsPreference.CAT)) ||
    profile.preferences.pets?.includes(PetsPreference.NONE)
  ) {
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
        <div className="flex flex-col p-4 leading-normal">
          <div className="flex items-center justify-between">
            <AiOutlineStop size={40} className="mr-2" />
            <div className="flex flex-col flex-1">
              <span className="text-sm font-bold uppercase">No pets</span>
              <p>Looking for a roommate without pets.</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (profile.preferences.pets?.includes(PetsPreference.DOG)) {
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
        <div className="flex flex-col p-4 leading-normal">
          <div className="flex items-center justify-between">
            <GiSittingDog size={40} className="mr-2" />
            <div className="flex flex-col flex-1">
              <span className="text-sm font-bold uppercase">No dogs</span>
              <p>Looking for a roommate without dogs.</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (profile.preferences.pets?.includes(PetsPreference.CAT)) {
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
        <div className="flex flex-col p-4 leading-normal">
          <div className="flex items-center justify-between">
            <FaCat size={40} className="mr-2" />
            <div className="flex flex-col flex-1">
              <span className="text-sm font-bold uppercase">No cats</span>
              <p>Looking for a roommate without cats.</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
        <div className="flex flex-col p-4 leading-normal">
          <div className="flex items-center justify-between">
            <MdPets size={40} className="mr-2" />
            <div className="flex flex-col flex-1">
              <span className="text-sm font-bold uppercase">Pet friendly</span>
              <p>Willing to live with someone who owns a pet.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const ProfileAboutMe = ({ profile, isMyProfile }) => {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [saving, setSaving] = useState(false);
  const [showBioModal, setShowBioModal] = useState(false);
  const [charCount, setCharCount] = React.useState(0);
  const dispatch: AppDispatch = useDispatch();

  const lat = parseFloat(profile?.location?.lat);
  const lng = parseFloat(profile?.location?.lng);

  const {
    register: editBioRegister,
    handleSubmit: handleSubmitEditBio,
    formState: { errors: editBioErrors },
  } = useForm<{
    editBio: string;
  }>();

  const handleEditBio = async ({ editBio }: { editBio: string }) => {
    setSaving(true);

    try {
      await dispatch(updateProfile({ bio: editBio.trim() }));
      setShowBioModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const hasHousing = !!profile?.hasHousing;

  const latitude = hasHousing && lat ? parseFloat(lat.toFixed(2)) : lat;
  const longitude = hasHousing && lng ? parseFloat(lng.toFixed(2)) : lng;

  return (
    <>
      <div className="space-y-4">
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
          <div className="relative flex flex-col justify-between flex-1 p-4 leading-normal">
            <div className="flex items-center mb-2">
              <BiPencil size={24} className="mr-1" />
              <span className="text-xl font-bold">Bio</span>
              {isMyProfile && (
                <button
                  id="editBioButton"
                  className="absolute button button-flat right-3 top-1"
                  onClick={() => {
                    setCharCount(currentUser?.profile?.bio?.length || 0);
                    setShowBioModal(true);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
            <p className="whitespace-pre-wrap">
              {/* className={classNames(showMoreBio && 'line-clamp-6')} */}
              {profile?.bio || <span className="text-gray-500">No bio yet</span>}
            </p>
            {/* {profile.bio.length > 570 && (
            <button onClick={() => setShowMoreBio(!showMoreBio)} className="button button-flat">
              {showMoreBio ? (
                <span>
                  Show more
                  <FaChevronDown className="inline-block mb-1 ml-3" />
                </span>
              ) : (
                <span>
                  Show less
                  <FaChevronUp className="inline-block mb-1 ml-3" />
                </span>
              )}
            </button>
          )} */}
          </div>
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
          <div className="relative flex flex-col justify-between flex-1 p-4 leading-normal">
            <div className="flex items-center mb-2">
              <FaDog size={24} className="mr-1.5" />
              <span className="text-xl font-bold">Pets</span>
              {isMyProfile && <ProfileAboutMePets profile={profile} />}
            </div>
            <div className="flex flex-row flex-wrap">
              {profile?.pets?.map((pet) => (
                <p className="p-1" key={pet}>
                  <span className="inline-block px-3 py-1 text-sm font-semibold text-gray-700 rounded-full bg-blue-50">
                    {PetProfile[pet] || pet}
                  </span>
                </p>
              ))}
            </div>
            <p>{!profile?.pets?.length && <span className="text-gray-500">No pets</span>}</p>
          </div>
        </div>

        <CollapsibleCard
          collapsed={false}
          title={'Housing'}
          icon={<BiHome className="mr-1" size={24} />}
        >
          <div className="p-2">
            <span className="flex flex-row items-center flex-1 mb-1 text-xs uppercase">
              <FiMapPin className="mr-1" />
              {hasHousing ? <>Location</> : <>Ideal Living Area</>}
            </span>
            <div className="relative space-y-4">
              {isMyProfile && (
                <button
                  className="absolute z-20 bg-white button button-flat right-3 top-1"
                  onClick={() => {
                    history.push('/settings/housing');
                  }}
                >
                  Edit
                </button>
              )}
              {latitude && longitude ? (
                <>
                  <Map
                    mapConfig={{
                      lat: latitude,
                      lng: longitude,
                      includeCircle: true,
                      hideMarker: true,
                      radius: hasHousing ? 1 : profile?.location?.radius || 50,
                    }}
                  />
                  <span className="text-gray-500">
                    {hasHousing
                      ? `Has an extra bedroom available in ${profile.location?.city}`
                      : `Looking for housing within ${profile?.location?.radius} miles of ${profile.location?.city}`}
                  </span>
                </>
              ) : (
                <div className="font-semibold text-gray-700">
                  This user doesn't have a location set yet
                </div>
              )}
              <div className="relative space-y-4 bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                <div className="flex flex-col justify-between p-4 leading-normal">
                  <div className="flex items-center justify-between mb-2">
                    <span className="flex flex-row items-center flex-1 text-sm font-bold uppercase">
                      <GrWheelchairActive size={40} className="mr-1" />
                      Home Requirements
                      {isMyProfile && <ProfileAboutMeHomeReqs profile={profile} />}
                    </span>
                  </div>
                  <div className="flex flex-row flex-wrap">
                    {profile?.preferences?.housing?.map((req) => (
                      <p className="p-1" key={req}>
                        <span className="inline-block px-3 py-1 text-sm font-semibold text-gray-700 rounded-full bg-blue-50">
                          {HousingRequirementsProfile[req] || req}
                        </span>
                      </p>
                    ))}
                    {!profile?.preferences?.housing?.length && (
                      <span className="text-gray-500">No housing requirements listed</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CollapsibleCard>

        <CollapsibleCard
          collapsed={false}
          title={'Care'}
          icon={<FiClock className="mr-1" size={24} />}
        >
          <div className="p-2 space-y-4">
            <div className="relative bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
              <div className="flex flex-col justify-between p-4 leading-normal">
                <div className="flex items-center justify-between mb-2">
                  <span className="flex flex-row items-center flex-1 text-sm font-bold uppercase">
                    <RiUserHeartLine size={40} className="mr-1" />
                    Care Requirements
                    {isMyProfile && <ProfileAboutMeCareReqs profile={profile} />}
                  </span>
                </div>
                <div className="flex flex-row flex-wrap">
                  {profile?.careInfo?.tasks?.map((care) => (
                    <p className="p-1" key={care}>
                      <span className="inline-block px-3 py-1 text-sm font-semibold text-gray-700 rounded-full bg-blue-50">
                        {CareTasksProfile[care] || care}
                      </span>
                    </p>
                  ))}
                  {!profile?.careInfo?.tasks?.length && (
                    <span className="text-gray-500">No care requirements listed</span>
                  )}
                </div>
              </div>
            </div>
            <div className="relative bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
              <div className="flex flex-col justify-between p-4 leading-normal">
                <div className="flex items-center justify-between mb-2">
                  <span className="flex flex-row items-center flex-1 text-sm font-bold uppercase">
                    <AiOutlineFieldTime size={40} className="mr-1" />
                    Hours of care per week
                    {isMyProfile && <ProfileAboutMeCareHours profile={profile} />}
                  </span>
                </div>
                <div className="flex flex-row flex-wrap">
                  <p className="p-1">
                    {profile?.careInfo?.hours ? (
                      <span className="inline-block px-3 py-1 text-sm font-semibold text-gray-700 rounded-full bg-blue-50">
                        {CareHoursProfile[profile?.careInfo?.hours]}*
                      </span>
                    ) : (
                      <span className="text-gray-500">No care hours listed</span>
                    )}
                  </p>
                </div>
                {profile?.careInfo?.hours && (
                  <p className="p-2 text-xs text-gray-500">*Hours may vary</p>
                )}
              </div>
            </div>
            {/* <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
            <div className="flex flex-col justify-between p-4 leading-normal">
              <div className="flex items-center justify-between mb-2">
                <span className="flex flex-row items-center flex-1 text-sm font-bold uppercase">
                  <GrAccessibility size={40} className="mr-1" />
                  Accessibility
                </span>
              </div>
              <div className="flex flex-row flex-wrap">
                {['Motor', 'Cognitive'].map((care) => (
                  <p className="p-1" key={care}>
                    <span className="inline-block px-3 py-1 text-sm font-semibold text-gray-700 rounded-full bg-blue-50">
                      {care}
                    </span>
                  </p>
                ))}
              </div>
              <p className="p-2">Help with exercise and walking</p>
            </div>
          </div> */}
          </div>
        </CollapsibleCard>

        <CollapsibleCard
          collapsed={false}
          title={'Preferences'}
          icon={<BiHeart className="mr-1" size={24} />}
        >
          <div className="relative">
            {isMyProfile && <ProfileAboutMePreferences profile={profile} />}
          </div>

          <div className="p-2">
            <span className="relative flex flex-row items-center flex-1 mb-1 text-xs uppercase">
              <SiGumtree className="mr-1" />
              Lifestyle Choices
            </span>

            <div className="space-y-4">
              {(() => {
                switch (profile.smoking) {
                  case false:
                    return (
                      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                        <div className="flex flex-col p-4 leading-normal">
                          <div className="flex items-center justify-between">
                            <AiOutlineStop size={40} className="mr-2" />
                            <div className="flex flex-col flex-1">
                              <span className="text-sm font-bold uppercase">No smoking</span>
                              <p>Looking for a smoke-free household.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case true:
                    return (
                      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                        <div className="flex flex-col p-4 leading-normal">
                          <div className="flex items-center justify-between">
                            <MdSmokingRooms size={40} className="mr-2" />
                            <div className="flex flex-col flex-1">
                              <span className="text-sm font-bold uppercase">Tobacco friendly</span>
                              <p>Does not require a smoke-free household.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                }
              })()}

              {(() => {
                switch (profile.drinking) {
                  case false:
                    return (
                      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                        <div className="flex flex-col p-4 leading-normal">
                          <div className="flex items-center justify-between">
                            <AiOutlineStop size={40} className="mr-2" />
                            <div className="flex flex-col flex-1">
                              <span className="text-sm font-bold uppercase">No drinking</span>
                              <p>Looking for a alcohol-free household.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case true:
                    return (
                      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                        <div className="flex flex-col p-4 leading-normal">
                          <div className="flex items-center justify-between">
                            <BiDrink size={40} className="mr-2" />
                            <div className="flex flex-col flex-1">
                              <span className="text-sm font-bold uppercase">Alcohol friendly</span>
                              <p>Does not require an alcohol-free household.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                }
              })()}

              <PetsBlock profile={profile} />

              {profile?.preferences?.gender &&
              profile?.preferences?.gender !== GenderPreference.NO_PREFERENCE ? (
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                  <div className="flex flex-col p-4 leading-normal">
                    <div className="flex items-center justify-between">
                      <FaRegSmile size={40} className="mr-2" />
                      <div className="flex flex-col flex-1">
                        <span className="text-sm font-bold uppercase">
                          {GenderProfile[profile?.preferences?.gender] ||
                            profile?.preferences?.gender}{' '}
                          Roommate
                        </span>
                        <p>
                          Looking for a{' '}
                          {GenderProfile[profile?.preferences?.gender] ||
                            profile?.preferences?.gender}{' '}
                          roommate.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
                  <div className="flex flex-col p-4 leading-normal">
                    <div className="flex items-center justify-between">
                      <FaRegSmile size={40} className="mr-2" />
                      <div className="flex flex-col flex-1">
                        <span className="text-sm font-bold uppercase">Any gender</span>
                        <p>No gender preference.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CollapsibleCard>
      </div>

      <Modal
        show={showBioModal}
        hideAction={() => setShowBioModal(false)}
        onSecondary={() => setShowBioModal(false)}
        onConfirm={() => handleSubmitEditBio(handleEditBio)()}
        title={`Edit bio`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <p className="mb-4 text-gray-600">
              Some questions to get you started:
              <ul className="ml-8 list-disc">
                <li>What makes you the ideal roommate?</li>
                <li>What are your favorite hobbies?</li>
                <li>Are you a morning person or a night owl?</li>
              </ul>
            </p>
            <div className="w-full">
              <form noValidate onSubmit={handleSubmitEditBio(handleEditBio)}>
                <label className="text-gray-600" htmlFor="editBio">
                  <textarea
                    className="block w-full px-3 py-2 placeholder-gray-400 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                    {...editBioRegister('editBio', {
                      required: 'Please tell others about yourself',
                      maxLength: { value: 5000, message: 'This exceeds the max length allowed' },
                    })}
                    maxLength={5000}
                    rows={6}
                    defaultValue={currentUser?.profile?.bio}
                    onChange={(e) => setCharCount(e.target.value.length)}
                    data-hj-allow
                  />
                  <span className="float-right text-gray-500">{charCount}/5000</span>
                  {editBioErrors && editBioErrors['editBio'] && (
                    <div className="text-red-400">{editBioErrors['editBio'].message}</div>
                  )}
                </label>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMe;
