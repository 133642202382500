import { logout } from 'actions/auth';
import { startTour } from 'actions/user';
import classNames from 'classnames';
import { history } from 'helpers/history';
import { useDetectOutsideClick } from 'helpers/useDetectOutsideClick';
import React, { useRef } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { HiExternalLink } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'store';
import { ProfileStatus } from 'types';

const HelpButton = () => {
  const supportRef = useRef(null);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [toggleSupport, setToggleSupport] = useDetectOutsideClick(supportRef, false);
  const dispatch: AppDispatch = useDispatch();
  const { location } = useHistory<History>();
  const isOnboarding = location.pathname.split('/')[1] === 'onboarding';

  const displayWalkthroughTour = () => {
    dispatch(startTour());
  };

  return (
    <>
      <button
        id="helpButton"
        className={classNames(
          toggleSupport ? 'text-blue-600 bg-gray-100' : 'text-white',
          'button z-10 py-2 pl-2 pr-3 whitespace-nowrap ring-white sm:ring-2',
        )}
        onClick={() => {
          setToggleSupport(!toggleSupport);
        }}
      >
        <BiHelpCircle className="sm:mr-1" size={34} />
        <span className="hidden p-0 sm:block">Contact us</span>
      </button>
      {toggleSupport && (
        <div ref={supportRef} id="helpDropdown" className="relative inline-block">
          <div
            className="absolute z-10 right-0 w-80 rounded-md shadow-xl border overflow-hidden origin-top-right"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="flex flex-col text-right text-black divide-gray-300 divide-y">
              <p className="px-5 py-3 bg-white">
                If you need assistance, please call or email the Rumi team.
              </p>
              <a className="px-5 py-3 bg-white" id="help-button-phone" href="tel:6516955805">
                Phone: <span className="hover:underline font-bold">651-695-5805</span>
                <p className="text-green-500 text-sm font-semibold">Mon-Fri 8am-5pm</p>
              </a>
              <a
                className="px-5 py-3 bg-white"
                id="help-button-email"
                href="mailto:info@meetmyrumi.com"
              >
                Email: <span className="hover:underline font-bold">info@meetmyrumi.com</span>
                <p className="text-green-500 text-sm font-semibold">
                  Usually responds within 24 hours
                </p>
              </a>
              <a
                className="px-5 py-3 bg-white cursor-pointer"
                href="https://meetmyrumi.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span>Website: </span>
                <span className="hover:underline font-bold"> meetmyrumi.com</span>{' '}
                <HiExternalLink className="inline mb-0.5" />
              </a>
              {isOnboarding && (
                <div
                  className="px-5 py-3 hover:underline bg-white rounded-b-md cursor-pointer"
                  onClick={() => dispatch(logout()).then(() => history.replace('/login'))}
                >
                  Sign out
                </div>
              )}
              {currentUser?.profile.status === ProfileStatus.ACTIVE && (
                <div
                  id="walkthroughTour"
                  onClick={() => displayWalkthroughTour()}
                  className="flex items-center justify-end px-5 py-3 hover:underline bg-white rounded-b-md cursor-pointer"
                >
                  <BsBoxArrowInRight className="mr-2" /> Display help tour again
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpButton;
