import { combineReducers } from 'redux';
import admin from './admin';
import auth from './auth';
import connections from './connections';
import message from './message';
import modals from './modals';
import users from './user';
import analytics from './analytics';

const rootReducer = combineReducers({
  auth,
  message,
  connections,
  admin,
  users,
  modals,
  analytics,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
