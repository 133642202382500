import { groupById } from 'helpers/groupBy';
import { FETCH_MESSAGES_SUCCESS, SET_MESSAGE } from '../actions/types';

const initialState = { messages: {} };

const message = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      const messages = state.messages;
      return {
        ...state,
        messages: {
          ...messages,
          [payload.id]: payload,
        },
      };

    case FETCH_MESSAGES_SUCCESS: {
      const messages = groupById(payload);
      return { ...state, messages };
    }

    default:
      return state;
  }
};

export default message;
