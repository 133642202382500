import React from 'react';

const Home: React.FC<{ opacity: number; colorClass?: string }> = ({
  opacity,
  colorClass = 'text-white',
}) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${colorClass} stroke-current`}
    >
      <path
        d="M1 9.88014C1 8.92659 1.45332 8.02983 2.22115 7.46442L11 1L19.7788 7.46443C20.5467 8.02983 21 8.92659 21 9.88014V20.5C21 21.8807 19.8807 23 18.5 23H15C14.4477 23 14 22.5523 14 22V16C14 15.7239 13.7761 15.5 13.5 15.5H8.5C8.22386 15.5 8 15.7239 8 16V22C8 22.5523 7.55228 23 7 23H3.5C2.11929 23 1 21.8807 1 20.5V9.88014Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity={opacity}
      />
    </svg>
  );
};

export default Home;
