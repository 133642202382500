import FormField from 'components/Common/FormField';
import LoadingButton from 'components/Common/LoadingButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ReferenceOption } from 'types';
import { storyReference } from '../../actions/onboarding';
import { AppDispatch } from '../../store';
import PillsInput from '../Common/PillsInput';
import StatusMessage, { StatusType } from '../Common/StatusMessage';
import OnboardingLayout from '../Layout/OnboardingLayout';

type Props = {
  history: any;
};

const referenceOptions = [
  {
    label: 'Bridges Employee/Bridges Resident',
    value: ReferenceOption.BRIDGES_EMPLOYEE_OR_RESIDENT,
  },
  { label: 'Bus/Light Rail', value: ReferenceOption.BUS_OR_LIGHT_RAIL },
  { label: 'Case Manager', value: ReferenceOption.CASE_MANAGER_OR_SOCIAL_WORKER },
  { label: 'Google', value: ReferenceOption.GOOGLE },
  { label: 'Indeed', value: ReferenceOption.INDEED },
  { label: 'TV Commercial', value: ReferenceOption.TV_COMMERCIAL },
  {
    label: 'Facebook',
    value: ReferenceOption.FACEBOOK,
  },
  {
    label: 'Instagram',
    value: ReferenceOption.INSTAGRAM,
  },
  {
    label: 'LinkedIn',
    value: ReferenceOption.LINKEDIN,
  },
  {
    label: "I'm not sure",
    value: ReferenceOption.IM_NOT_SURE,
  },
  { label: 'Other', value: ReferenceOption.OTHER },
];

const Reference = ({ history }: Props) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [referencesOther, setReferencesOther] = useState<string>();
  const [genericError, setGenericError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const references: any[] = referenceOptions.map((ref) => ref.value);
  const selectedReferences = references.filter((ref) =>
    currentUser?.profile?.references?.includes(ref),
  );
  const otherReference = currentUser?.profile?.references?.find((ref) => !references.includes(ref));

  useEffect(() => {
    if (otherReference) {
      selectedReferences.push(ReferenceOption.OTHER);
      setReferencesOther(otherReference);
    }

    setSelectedOptions(selectedReferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const onChangeOptions = (options: string[]) => {
    setSelectedOptions(options as ReferenceOption[]);
  };

  const onChangeReferencesOther = (value: string) => {
    setReferencesOther(value);
  };

  const submitReference = async () => {
    setGenericError('');
    setIsLoading(true);

    const references: string[] = selectedOptions;
    if (references.includes(ReferenceOption.OTHER) && referencesOther) {
      // remove OTHER
      const otherIndex = references.indexOf(ReferenceOption.OTHER);
      references.splice(otherIndex, 1);

      references.push(referencesOther);
    }

    try {
      await dispatch(storyReference(references));
      history.push('/onboarding');
    } catch (error) {
      console.error(error);
      setGenericError('An unknown server error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <OnboardingLayout
        title={
          <>
            How did you hear about us?{' '}
            <BiPaperPlane size={34} className="inline mb-1 ml-2 text-white" />
          </>
        }
      >
        <PillsInput
          onChange={onChangeOptions}
          options={referenceOptions}
          values={selectedOptions}
          selectOne={true}
        />
        {selectedOptions.includes(ReferenceOption.OTHER) && (
          <FormField
            type="text"
            name="other"
            placeholder="Please list other references"
            value={referencesOther}
            className="max-w-md"
            onChange={(e) => onChangeReferencesOther(e.target.value)}
            data-hj-allow
          />
        )}

        {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
        <LoadingButton
          isLoading={isLoading}
          disabled={
            isEmpty(selectedOptions) ||
            (selectedOptions.includes(ReferenceOption.OTHER) && !referencesOther)
          }
          className="hidden w-full mt-6 text-lg md:flex"
          onClick={submitReference}
          variant="primary-white"
        >
          Save and continue
        </LoadingButton>
      </OnboardingLayout>
      <div className="fixed bottom-0 w-full px-2 bg-white md:hidden">
        <LoadingButton
          isLoading={isLoading}
          disabled={
            isEmpty(selectedOptions) ||
            (selectedOptions.includes(ReferenceOption.OTHER) && !referencesOther)
          }
          className="w-full text-lg"
          onClick={submitReference}
          variant="primary"
        >
          Save and continue
        </LoadingButton>
      </div>
    </>
  );
};

export default Reference;
