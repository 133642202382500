import { lazyWithRetry } from 'helpers/lazyWithRetry';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));
const Analytics = lazyWithRetry(() => import('./pages/Analytics'));
const UserProfileReport = lazyWithRetry(() => import('./components/Reports/UserProfileReport'));
const UserProfile = lazyWithRetry(() => import('./components/Users/UserProfile'));
const Users = lazyWithRetry(() => import('./components/Users/UsersNew'));
const UsersDenied = lazyWithRetry(() => import('./components/Users/UsersDeniedNew'));
const Connections = lazyWithRetry(() => import('./components/Connections/ConnectionsNew'));
const ConnectionDetails = lazyWithRetry(() => import('./components/Connections/ConnectionDetails'));
const Reported = lazyWithRetry(() => import('./components/Reported/ReportedNew'));
const ReportList = lazyWithRetry(() => import('./components/Reports/ReportList'));
const UsersReport = lazyWithRetry(() => import('./components/Reports/UsersReport'));
const ReferencesReport = lazyWithRetry(() => import('./components/Reports/ReferencesReport'));
const AccountSettings = lazyWithRetry(() => import('./components/Settings/Account'));
const PushNotifications = lazyWithRetry(
  () => import('./components/Marketing/PushNotifications/PushNotifications'),
);
const TextNotifications = lazyWithRetry(
  () => import('./components/Marketing/TextNotifications/TextNotifications'),
);

/*
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 */

const routes = [
  {
    path: '/admin/dashboard', // the url
    component: Dashboard, // view rendered
    exact: true,
  },
  {
    path: '/admin/analytics', // the url
    component: Analytics, // view rendered
    exact: true,
  },
  {
    path: '/admin/users', // the url
    component: Users, // view rendered
    exact: true,
  },
  {
    path: '/admin/users/denied', // the url
    component: UsersDenied, // view rendered
    exact: true,
  },
  {
    path: '/admin/users/blocked',
    component: Reported,
    exact: true,
  },
  {
    path: '/admin/users/blocked/:id',
    component: Reported,
    exact: false,
  },
  {
    path: '/admin/users/:id/report', // the url
    component: UserProfileReport, // view rendered
    exact: false,
  },
  {
    path: '/admin/users/:id', // the url
    component: UserProfile, // view rendered
    exact: false,
  },
  {
    path: '/admin/connections',
    component: Connections,
    exact: true,
  },
  {
    path: '/admin/connection/:id', // the url
    component: ConnectionDetails, // view rendered
    exact: false,
  },

  {
    path: '/admin/reports',
    component: ReportList,
    exact: true,
  },
  {
    path: '/admin/reports/users',
    component: UsersReport,
    exact: true,
  },
  {
    path: '/admin/reports/references',
    component: ReferencesReport,
    exact: true,
  },
  {
    path: '/admin/settings',
    component: AccountSettings,
    exact: true,
  },
  {
    path: '/admin/marketing/push',
    component: PushNotifications,
    exact: true,
  },
  {
    path: '/admin/marketing/text',
    component: TextNotifications,
    exact: true,
  },
];

export default routes;
