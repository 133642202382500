import LoadingButton from 'components/Common/LoadingButton';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { authMe, login } from '../../actions/auth';
import FormField from '../../components/Common/FormField';
import LandingLayout from '../../components/Layout/LandingLayout';
import { AppDispatch } from '../../store';

type Props = {
  history: any;
};

type FormData = {
  email: string;
  password: string;
};

const Login = ({ history }: Props) => {
  const { register, handleSubmit } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [genericError, setGenericError] = useState('');

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');

  const handleLogin = async ({ email, password }: FormData) => {
    if (isLoading) {
      return;
    }
    setGenericError('');
    setIsLoading(true);
    try {
      await dispatch(login(email, password));
      await dispatch(authMe());
      history.replace('/connections');
    } catch (error) {
      if (error?.response?.data?.message === 'PASSWORDLESS_LOGIN') {
        history.push(`/magic-link?email=${email}`);
        return;
      } else if (error?.response?.data?.message === 'DENIED_PROFILE') {
        setGenericError('There was an error with your account, please call 651-695-5805');
      } else if (error?.response?.status === 401 || error?.response?.status === 400) {
        setGenericError('Incorrect email or password');
      } else if (error?.response?.status === 429) {
        setGenericError(
          'Too many attempts, please try again in a few minutes or call 651-760-8730 for assistance.',
        );
      } else {
        setGenericError('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Rumi</title>
      </Helmet>
      <LandingLayout>
        <div className="flex-grow w-full text-base">
          <h1 className="mb-4">Login</h1>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="w-full mx-auto">
              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
              <div className="py-2">
                <FormField
                  label="Email address"
                  type="email"
                  {...register('email')}
                  defaultValue={email || ''}
                  placeholder="example@gmail.com"
                  autoFocus
                  required
                  data-hj-allow
                />
              </div>
              <div className="py-2">
                <FormField
                  label="Password"
                  {...register('password')}
                  type="password"
                  placeholder="Password"
                  minLength={6}
                  required
                />
              </div>
              <LoadingButton variant="primary" className="w-full mt-4" isLoading={isLoading}>
                Login
              </LoadingButton>
            </div>
          </form>
          <div className="mt-4 text-center">
            <Link to="/forgot-password" className="font-bold text-blue-600">
              Forgot password?
            </Link>
          </div>
          <div className="pt-4 mt-4 text-center border-t border-gray-300">
            <p>Don't have an account?</p>
            <div className="mt-4">
              <Link className="w-full button button-secondary" to="/signup">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </LandingLayout>
    </>
  );
};

export default Login;
