import { authMe } from 'actions/auth';
import classNames from 'classnames';
import heic2any from 'heic2any';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { BiPencil, BiPlus } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import userService from 'services/user.service';
import { AppDispatch } from 'store';
import { getCroppedImg } from '../../helpers/canvasUtils';
import LoadingButton from './LoadingButton';

type ModalProps = {
  image?: any;
  onStart?: () => void;
  onCancel?: () => void;
  onFinish: (img: any) => void;
};

const ImageUploader: React.FC<ModalProps> = ({
  image = null,
  onStart = () => {},
  onCancel = () => {},
  onFinish,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null as any);
  const [croppedImageToUse, setCroppedImageToUse] = useState(null as any);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setCroppedImageToUse(image);
  }, [image]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onDiscard = () => {
    setImageSrc(null);
    onCancel();
  };

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      setLoading(true);
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      const data = new FormData();
      data.append('image', croppedImage);
      try {
        await userService.updateUserImage(data);
        await dispatch(authMe());
        const croppedImageToUse = URL.createObjectURL(croppedImage);
        setCroppedImageToUse(croppedImageToUse);
        onFinish(croppedImageToUse);
      } finally {
        setLoading(false);
        setImageSrc(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageSrc, croppedAreaPixels, rotation],
  );

  const onFileChange = async (files: any) => {
    if (files.length > 0) {
      onStart();
      const file = files[0];
      let imageDataUrl;
      if (file.type === 'image/heic') {
        let image: any = await heic2any({ blob: file, quality: 0.5, toType: 'image/jpeg' });
        imageDataUrl = await readFile(image);
      } else {
        imageDataUrl = await readFile(file);
      }
      setImageSrc(imageDataUrl);
    }
  };

  const handleFileChange = (event: any) => {
    onFileChange([...event.target.files]);
  };

  const handleDragEvent = (isDraggingValue: boolean) => (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(isDraggingValue);
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const files = [...event.dataTransfer.files];
    onFileChange(files);
  };

  const dropZoneClass = classNames(
    'flex flex-col items-center justify-center w-full hover:border-solid cursor-pointer',
    {
      'border-dashed': !isDragging,
      'p-4 border-2 border-gray-300 rounded-md': !imageSrc,
      'h-full': imageSrc,
    },
  );

  const PhotoLabel = ({ children }) => (
    <label
      htmlFor="photo"
      className={dropZoneClass}
      onDrop={(e) => handleDrop(e)}
      onDragOver={handleDragEvent(true)}
      onDragEnter={handleDragEvent(true)}
      onDragLeave={handleDragEvent(false)}
    >
      {children}
    </label>
  );

  return (
    <>
      {imageSrc ? (
        <React.Fragment>
          <div className="relative w-full h-96">
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={2 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="p-4 w-full text-xl">
            Zoom
            <input
              className="w-full rounded-full"
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => setZoom(+e.target.value)}
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button onClick={onDiscard} className="button button-secondary">
              Cancel
            </button>
            <LoadingButton onClick={handleSubmit} isLoading={loading}>
              Save
            </LoadingButton>
          </div>
        </React.Fragment>
      ) : (
        <>
          {croppedImageToUse ? (
            <label
              htmlFor="photo"
              onDrop={(e) => handleDrop(e)}
              onDragOver={handleDragEvent(true)}
              onDragEnter={handleDragEvent(true)}
              onDragLeave={handleDragEvent(false)}
            >
              <div className="relative w-full h-full cursor-pointer">
                <img src={croppedImageToUse} alt="Profile" className="rounded overflow-hidden" />
                {true && (
                  <button className="absolute z-10 bottom-4 right-4 flex items-center px-4 py-2 text-white font-semibold bg-blue-600 hover:bg-blue-700 border-2 border-white rounded-full focus:outline-none focus:ring-2">
                    Edit <BiPencil className="ml-2" size={20} />
                  </button>
                )}
              </div>
            </label>
          ) : (
            <div className="aspect-w-1 aspect-h-1">
              <PhotoLabel>
                <div className="flex flex-col items-center">
                  <BiPlus className="text-gray-300" size={40} />
                  <div className="mt-4 text-center text-xl">Click to upload a profile picture</div>
                </div>
              </PhotoLabel>
            </div>
          )}

          <input
            type="file"
            id="photo"
            name="photo"
            accept="image/*,.heic,video/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </>
      )}
    </>
  );
};

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export default ImageUploader;
