import { myPets } from 'actions/onboarding';
import FormField from 'components/Common/FormField';
import Modal from 'components/Common/Modal';
import PillsInput from 'components/Common/PillsInput';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import { arraysEqual } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { ChoiceType, Pet } from 'types';

const choiceOptions = [
  { label: 'Yes', value: ChoiceType.YES },
  { label: 'No', value: ChoiceType.NO },
  { label: 'No, but I want one', value: ChoiceType.WANTS },
];

const petOptions = [
  { label: 'Cat', value: Pet.CAT },
  { label: 'Dog', value: Pet.DOG },
  { label: 'Other', value: Pet.OTHER },
];

const ProfileAboutMePets = ({ profile }) => {
  const [petBoolean, setPetBoolean] = useState<ChoiceType[]>([]);
  const [petTypes, setPetTypes] = useState<Pet[]>([]);
  const [petsOther, setPetsOther] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [showPetsModal, setShowPetsModal] = useState(false);
  const [genericError, setGenericError] = useState('');
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (profile.pets) {
      resetDefault(profile.pets);
    }
  }, [profile]);

  const resetDefault = (pets) => {
    if (pets && !arraysEqual(pets, [Pet.NONE]) && !arraysEqual(pets, [ChoiceType.WANTS])) {
      setPetBoolean([ChoiceType.YES]);
      const petTypes = pets?.filter(
        (pet) => pet === Pet.DOG || pet === Pet.CAT || pet === Pet.OTHER,
      );
      const otherPets = pets?.find(
        (pet) => pet !== Pet.DOG && pet !== Pet.CAT && pet !== Pet.OTHER && pet !== Pet.NONE,
      );
      setPetsOther(otherPets);
      if (otherPets) {
        petTypes.push(Pet.OTHER);
      }
      setPetTypes(petTypes);
    } else if (arraysEqual(pets, [ChoiceType.WANTS])) {
      setPetBoolean([ChoiceType.WANTS]);
    } else if (arraysEqual(pets, [Pet.NONE])) {
      setPetBoolean([ChoiceType.NO]);
    }
  };

  const cancel = () => {
    resetDefault(profile?.pets);
    setShowPetsModal(false);
  };

  const onChangePetBoolean = (option: string[]) => {
    setPetBoolean(option as ChoiceType[]);
  };

  const onChangeMyPet = (options: string[]) => {
    setGenericError('');
    setPetTypes(options as Pet[]);
  };

  const onChangePetsOther = (petsOther: string) => {
    setPetsOther(petsOther);
  };

  const submitPets = async () => {
    let pets: string[] = [];
    switch (petBoolean[0]) {
      case ChoiceType.YES:
        pets = petTypes;
        if (!pets.length) {
          setGenericError('Please select any animals you currently own');
          return;
        }
        if (pets.includes(Pet.OTHER) && petsOther) {
          // remove OTHER
          const otherIndex = pets.indexOf(Pet.OTHER);
          pets.splice(otherIndex, 1);

          pets.push(petsOther);
        }
        break;
      case ChoiceType.NO:
        pets = [Pet.NONE];
        break;
      case ChoiceType.WANTS:
        pets = [ChoiceType.WANTS];
        break;
      default:
        pets = [];
    }

    setGenericError('');
    setSaving(true);

    try {
      await dispatch(myPets(pets));
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setSaving(false);
      resetDefault(profile?.pets);
      setShowPetsModal(false);
    }
  };

  return (
    <>
      <button
        className="button button-flat absolute right-3 top-1"
        onClick={() => setShowPetsModal(true)}
      >
        Edit
      </button>

      <Modal
        show={showPetsModal}
        hideAction={() => setShowPetsModal(false)}
        onSecondary={() => cancel()}
        onConfirm={() => submitPets()}
        title={`Pets`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <p className="text-xl">Do you own any pets?</p>
            <div className="w-full">
              <PillsInput
                onChange={onChangePetBoolean}
                values={petBoolean}
                options={choiceOptions}
                selectOne={true}
                light={true}
              />

              {petBoolean.includes(ChoiceType.YES) && (
                <>
                  <h3 className="my-4 text-xl">Which pets do you currently have?</h3>
                  <p>Select all that apply:</p>
                  <PillsInput
                    onChange={onChangeMyPet}
                    values={petTypes}
                    options={petOptions}
                    selectOne={false}
                    light={true}
                  />
                  {petTypes.includes(Pet.OTHER) && (
                    <FormField
                      type="text"
                      name="other"
                      value={petsOther}
                      placeholder="Please specify other animals you live with"
                      className="max-w-md"
                      onChange={(e) => onChangePetsOther(e.target.value)}
                      data-hj-allow
                    />
                  )}
                </>
              )}
              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMePets;
