import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Card from './Card';

type Props = {
  children?: React.ReactNode;
  label: string | any;
  className?: string;
  description: string;
  expandAction?: () => void;
  contractAction?: () => void;
  expanded: boolean;
  onExpanded?: (value: boolean) => void;
};

const ExpandableCard = (props: Props) => {
  const { expanded, onExpanded } = props;

  return (
    <Card className={props.className} expanded={expanded}>
      <div
        className={`${
          expanded ? 'flex-col-reverse' : 'flex-col '
        } flex md:items-center justify-between md:flex-row cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          onExpanded && !expanded && onExpanded(!expanded);
        }}
      >
        <div className="space-y-2 md:w-9/12">
          <div className="text-3xl font-bold">{props.label}</div>
          <div className="text-base">{props.description}</div>
        </div>

        {expanded ? (
          <>
            <button
              className="mb-5 text-lg button button-secondary w-36 md:self-center md:mb-0 md:mt-0 md:w-28"
              onClick={(e) => {
                e.preventDefault();
                onExpanded && onExpanded(!expanded);
              }}
            >
              <MdKeyboardArrowLeft className="md:hidden" size={30} /> Change
            </button>
            <div className="flex justify-between my-4 md:hidden">
              <img
                src={require('../../assets/rumi-dark.svg').default}
                className="w-32 h-12"
                alt="Rumi logo"
              />
            </div>
          </>
        ) : (
          <button
            className="self-center w-full mt-5 text-lg button button-primary md:mt-0 md:w-28"
            onClick={(e) => {
              e.preventDefault();
              onExpanded && onExpanded(!expanded);
            }}
          >
            Select
          </button>
        )}
      </div>
      {expanded && <div className="pb-12 md:pb-0">{props.children}</div>}
    </Card>
  );
};

export default ExpandableCard;
