import { PetsPreference } from 'types';
import { AppDispatch } from '../store';
import { updateProfile } from './user';

export const housingLiving =
  (data: any): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ location: data }));
    const successMessage = 'Successfully saved housing preferences';
    return successMessage;
  };

export const updatePreferences =
  (data: any): any =>
  async (dispatch: AppDispatch) => {
    return dispatch(updateProfile({ preferences: data }));
  };

export const housingPreferences =
  (preferences: string[]): any =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(updatePreferences({ housing: preferences }));
      const successMessage = 'Successfully saved housing preferences';
      return successMessage;
    } catch (errorMessage) {
      Promise.reject(errorMessage);
    }
  };

export const updateCareInfo =
  (data: any): any =>
  (dispatch: AppDispatch) => {
    return dispatch(updateProfile({ careInfo: data }));
  };

export const careHours =
  (hours: string): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateCareInfo({ hours }));
    return 'Successfully saved care hours';
  };

export const careTasks =
  (tasks: string[]): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateCareInfo({ tasks }));
    return 'Successfully saved care tasks';
  };

export const roommateGender =
  (gender: string): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updatePreferences({ gender }));
    return 'Successfully saved roommate gender';
  };

export const myPets =
  (pets: string[]): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ pets }));
    return 'Successfully saved my pets';
  };

export const roommatePets =
  (roommatePet: PetsPreference[]): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updatePreferences({ pets: roommatePet }));
    return 'Successfully saved roommate pets.';
  };

export const storyGender =
  (gender: string): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ gender }));
    return 'Successfully saved my gender.';
  };

export const storyDrinking =
  (drinking: boolean): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ drinking }));
    return 'Successfully saved drinking preferences.';
  };

export const storySmoking =
  (smoking: boolean): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ smoking }));
    return 'Successfully saved smoking preferences.';
  };

export const storyReference =
  (references: string[]): any =>
  async (dispatch: AppDispatch) => {
    await dispatch(updateProfile({ references }));
    return 'Successfully saved your reference.';
  };
