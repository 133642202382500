import React from 'react';

const Time: React.FC<{ opacity: number; colorClass?: string }> = ({
  opacity,
  colorClass = 'text-white',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${colorClass} stroke-current`}
    >
      <circle cx="12" cy="12" r="11" opacity={opacity} strokeWidth="2" />
      <path
        d="M12 7V13L14.5 15.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity={opacity}
      />
    </svg>
  );
};

export default Time;
