import classNames from 'classnames';
import { history } from 'helpers/history';
import React, { useState } from 'react';
import { BiHomeHeart, BiUser } from 'react-icons/bi';
import { FaRegMap } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../reducers';
import SignOutModal from './SignOutModal';
import SupportModal from './SupportModal';

const SettingsMenu = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);

  const settingsLinks: any = [
    {
      label: 'Edit profile',
      to: '/profile',
      icon: (size, className) => <BiUser size={size} className={className} />,
    },
    {
      label: 'Account settings',
      to: '/settings/account',
      icon: (size, className) => <FiSettings size={size} className={className} />,
    },
    {
      label: 'Housing preferences',
      to: '/settings/housing',
      icon: (size, className) => <FaRegMap size={size} className={className} />,
      badge: 5,
    },
    {
      label: 'Living preferences',
      to: '/settings/preferences',
      icon: (size, className) => <BiHomeHeart size={size} className={className} />,
    },

    // {
    //   label: 'Notifications',
    //   to: '/settings/notifications',
    //   icon: (size, className) => <FaRegBell size={size} className={className} />,
    // },
  ];

  return (
    <div className="flex justify-center w-full md:max-w-2xl">
      <div className="w-full md:max-w-lg">
        <div className="flex flex-col items-center justify-center pb-8 pt-16 bg-gray-300 rounded-b-3xl">
          <div className="flex flex-wrap justify-center mb-4 select-none">
            <div className="flex items-center w-32 h-32 bg-white border-2 border-white rounded-full shadow overflow-hidden">
              <Link to="/profile" className="text-center">
                <img
                  className="w-full"
                  src={currentUser.image || require('../../assets/default_avatar.png').default}
                  alt={currentUser.name + ' profile photo'}
                />
              </Link>
            </div>
          </div>
          <Link id="viewMyProfile" to="/profile" className="text-center">
            <h1>{currentUser.firstName}</h1>
            View my profile
          </Link>
        </div>

        <div className="flex flex-col mx-6 my-4 text-gray-800 leading-4">
          {settingsLinks.map((navLink) => (
            <Link
              key={navLink.label}
              to={navLink.to}
              className={classNames(
                'flex items-center p-3 font-semibold hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-100 border-b',
                history.location.pathname === navLink.to && 'active',
              )}
            >
              {navLink.icon(28, 'mr-4')} {navLink.label}
            </Link>
          ))}
        </div>
        <div className="flex flex-col mx-6 pb-6">
          <SupportModal show={showSupportModal} onHide={setShowSupportModal}>
            <button
              className="button button-primary w-full"
              onClick={() => setShowSupportModal(true)}
            >
              Contact support
            </button>
          </SupportModal>
          <SignOutModal show={showLogoutModal} onHide={setShowLogoutModal}>
            <button
              className="button button-secondary w-full"
              onClick={() => setShowLogoutModal(true)}
            >
              Sign out
            </button>
          </SignOutModal>
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
