import { logout } from 'actions/auth';
import React from 'react';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(logout()).then(() => {
    window.location.reload();
  });
  return <></>;
};

export default Logout;
