import { AppDispatch } from 'store';
import { CommType, ContactType } from 'types';
import adminService from '../services/admin.service';
import {
  ADD_EVENT,
  ADMIN_NOTIFICATIONS,
  ADMIN_POTENTIAL_NOTIFICATION_USERS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  NEW_ADMIN_MESSAGE,
  NEW_ADMIN_MESSAGE_CONNECTION,
  SET_CONNECTIONS,
  SET_REPORTS,
  SET_USERS,
  SET_USER_EVENTS,
} from './types';

export const createNote =
  (userId: number, note: string): any =>
  async (dispatch: AppDispatch) => {
    const resp = await adminService.createNote(userId, note);
    dispatch(addEvent(resp.data));
  };

export const createConnectionNote =
  (connectionId: number, note: string): any =>
  async () => {
    return adminService.createConnectionNote(connectionId, note);
  };

export const fetchUserEvents = (userId: number) => async (dispatch: AppDispatch) => {
  const resp = await adminService.getUserEvents(userId);
  dispatch(setUserEvents(userId, resp.data));
};

export const fetchAllUsers = (): any => async (dispatch: AppDispatch) => {
  const resp = await adminService.getAllUsers();
  await dispatch(setUsers(resp.data));
  return Promise.resolve();
};

export const fetchNonDeniedUsers = (): any => async (dispatch: AppDispatch) => {
  const resp = await adminService.getNonDeniedUsers();
  await dispatch(setUsers(resp.data));
  return Promise.resolve();
};

export const fetchDeniedUsers = (): any => async (dispatch: AppDispatch) => {
  const resp = await adminService.getDeniedUsers();
  await dispatch(setUsers(resp.data));
  return Promise.resolve();
};

export const fetchReferencesReport = (): any => async (dispatch: AppDispatch) => {
  return adminService.getReferencesReport().then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const errorMessage = 'Failed to load references report';
      return Promise.reject(errorMessage);
    },
  );
};

export const getAllConnections = () => async (dispatch: AppDispatch) => {
  const resp = await adminService.getAllConnections();
  dispatch(setConnections(resp.data));
};

export const fetchAllReports = () => async (dispatch: AppDispatch) => {
  const resp = await adminService.getAllReports();
  dispatch(setReports(resp.data));
};

export const adminUpdateUser =
  (
    id: number,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    textNotification: boolean,
    emailNotification: boolean,
  ) =>
  async () => {
    return adminService.adminUpdateUser(
      id,
      firstName,
      lastName,
      phone,
      email,
      textNotification,
      emailNotification,
    );
  };

export const adminUpdateProfile =
  (
    id: number,
    profileId: number,
    bio: string,
    birthDate: Date,
    gender: string,
    status: string,
    alreadyMade: boolean,
    verified: boolean,
    administrator: number,
    statusNotification: boolean,
  ) =>
  async () => {
    return adminService.adminUpdateUserProfile(id, {
      id: profileId,
      bio,
      birthDate,
      gender,
      status,
      alreadyMade,
      verified,
      administratorId: administrator,
      statusNotification,
    });
  };

export const adminUpdateUserContact =
  (id: number, firstName: string, lastName: string, email, phone: string, type: ContactType) =>
  async () => {
    return adminService.adminUpdateUserContact(id, {
      firstName,
      lastName,
      email,
      phone,
      type,
    });
  };

export const deleteUser = (id: number) => async () => {
  return adminService.deleteUser(id);
};

export const deleteMessage = (messageId: number) => async () => {
  return adminService.deleteMessage(messageId);
};

export const createConnection = (id: number) => async () => {
  return adminService.createConnection(id);
};

export const shareUser =
  (id: number, shareUserId: number): any =>
  async () => {
    return adminService.shareUser(id, shareUserId).then(
      async (data) => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  };

export const loginAsUserId =
  (id: number): any =>
  async (dispatch: AppDispatch) => {
    return adminService.loginAsUserId(id).then(
      async (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: LOGIN_FAIL,
        });

        return Promise.reject(error);
      },
    );
  };

export const setUsers = (users: any): any => ({
  type: SET_USERS,
  payload: users,
});

export const setUserEvents = (userId: number, events: any[]) => ({
  type: SET_USER_EVENTS,
  payload: { userId, events },
});

export const addEvent = (event: any) => ({
  type: ADD_EVENT,
  payload: event,
});

export const setConnections = (connections: any[]) => ({
  type: SET_CONNECTIONS,
  payload: connections,
});

export const setReports = (reports: any[]) => ({
  type: SET_REPORTS,
  payload: reports,
});

export const adminReceivedNewMessage =
  (payload: { message; connection }) => (dispatch: AppDispatch) => {
    dispatch({
      type: NEW_ADMIN_MESSAGE_CONNECTION,
      payload: payload,
    });

    dispatch({
      type: NEW_ADMIN_MESSAGE,
      payload: payload,
    });
  };

export const marketingSendPush = (type: CommType, options: any, copy: string) => async () => {
  return adminService.marketingSendPush(type, options, copy);
};

export const marketingSendText = (type: CommType, options: any, copy: string) => async () => {
  return adminService.marketingSendText(type, options, copy);
};

export const fetchAdminNotificationHistory = (type: CommType) => async (dispatch: AppDispatch) => {
  const payload = await adminService.fetchAdminNotificationHistory(type);
  dispatch({
    type: ADMIN_NOTIFICATIONS,
    payload: payload,
  });
};

export const fetchPotentialUsersForNotification =
  (options: any, type: CommType) => async (dispatch: AppDispatch) => {
    try {
      const payload = await adminService.fetchPotentialUsersForNotification(options, type);
      dispatch({
        type: ADMIN_POTENTIAL_NOTIFICATION_USERS,
        payload: payload,
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
