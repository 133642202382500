import { careTasks } from 'actions/onboarding';
import FormField from 'components/Common/FormField';
import Modal from 'components/Common/Modal';
import PillsInput from 'components/Common/PillsInput';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { CareTasks } from 'types';

const careReqs = [
  { label: 'Budgeting', value: CareTasks.BUDGETING },
  { label: 'Companionship', value: CareTasks.COMPANIONSHIP },
  { label: 'Coping skills', value: CareTasks.COPING_SKILLS },
  { label: 'Household tasks', value: CareTasks.CLEANING },
  { label: 'Medication', value: CareTasks.MEDICATION },
  { label: 'Meal prep', value: CareTasks.MEAL_PREP },
  { label: 'Mobility', value: CareTasks.MOBILITY },
  { label: 'Personal care', value: CareTasks.PERSONAL_CARE },
  { label: 'Problem solving', value: CareTasks.PROBLEM_SOLVING },
  { label: 'Transportation', value: CareTasks.TRANSPORTATION },
  { label: "I'm not sure", value: CareTasks.NOT_SURE },
  { label: 'Other', value: CareTasks.OTHER },
];

const ProfileAboutMeCareReqs = ({ profile }) => {
  const [saving, setSaving] = useState(false);
  const [showCareReqsModal, setShowCareReqsModal] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [selectedReqs, setSelectedReqs] = useState<CareTasks[]>([]);
  const [selectedReqsOther, setSelectedReqsOther] = useState<string>();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    resetDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const resetDefault = () => {
    const references: any[] = careReqs.map((ref) => ref.value);
    const selectedReferences = references.filter((ref) => profile?.careInfo?.tasks?.includes(ref));
    const otherReference = profile?.careInfo?.tasks?.find((ref) => !references.includes(ref));

    if (otherReference) {
      selectedReferences.push(CareTasks.OTHER);
      setSelectedReqsOther(otherReference);
    }

    setSelectedReqs(selectedReferences);
  };

  const cancel = () => {
    resetDefault();
    setShowCareReqsModal(false);
  };

  const onChangeOptions = (options: string[]) => {
    setSelectedReqs(options as CareTasks[]);
  };

  const onChangeRequirementsOther = (otherReqs: string) => {
    setSelectedReqsOther(otherReqs);
  };

  const submitHomeReqs = async () => {
    setGenericError('');
    const requirements: string[] = selectedReqs;

    if (requirements.includes(CareTasks.OTHER) && selectedReqsOther) {
      const otherIndex = requirements.indexOf(CareTasks.OTHER);
      requirements.splice(otherIndex, 1);

      requirements.push(selectedReqsOther);
    }

    try {
      await dispatch(careTasks(selectedReqs));
    } catch (error) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setSaving(false);
      resetDefault();
      setShowCareReqsModal(false);
    }
  };

  return (
    <>
      <button
        className="button button-flat absolute right-3 top-1"
        onClick={() => setShowCareReqsModal(true)}
      >
        Edit
      </button>

      <Modal
        show={showCareReqsModal}
        hideAction={() => setShowCareReqsModal(false)}
        onSecondary={() => cancel()}
        onConfirm={() => submitHomeReqs()}
        title={`Care requirements`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <p className="text-xl">Do you have any care requirements?</p>
            <div className="w-full">
              <PillsInput
                values={selectedReqs}
                onChange={onChangeOptions}
                options={careReqs}
                selectOne={false}
                light={true}
              />
              {selectedReqs.includes(CareTasks.OTHER) && (
                <FormField
                  type="text"
                  name="other"
                  placeholder="Please list other care requirements"
                  value={selectedReqsOther}
                  className="max-w-md"
                  onChange={(e) => onChangeRequirementsOther(e.target.value)}
                  data-hj-allow
                />
              )}

              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMeCareReqs;
