import { format } from 'date-fns';
import React from 'react';
import { toast } from 'react-hot-toast';
import { BiMessage } from 'react-icons/bi';
import { FaRegSmile } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';
import { RiChatSmile3Line } from 'react-icons/ri';
import { history } from './history';

const DEFAULT_TOAST_DURATION = 100000;

const genericToast = (message, options = {}, connected = false) => {
  const dismissToast = (e, t) => {
    e.preventDefault();
    toast.dismiss(t.id);
  };

  toast(
    (t) => (
      <>
        <div
          className="flex items-center w-full cursor-pointer"
          onClick={(e) => dismissToast(e, t)}
        >
          <div className="pr-4">
            <h5 className="font-semibold">{message}</h5>
            {connected && <p className="text-sm">Send them a message</p>}
          </div>
          <button className="ml-auto" onClick={(e) => dismissToast(e, t)}>
            <IoClose />
          </button>
        </div>
      </>
    ),
    {
      duration: DEFAULT_TOAST_DURATION,
      ...options,
    },
  );
};

export const newMessageToast = (message, connection) => {
  const timestamp = new Date(message.timestamp);

  const viewMessage = (e, t) => {
    e.preventDefault();
    toast.dismiss(t.id);
    history.push(`/connections/messages/${connection.slug}`);
  };

  const closeToast = (e, t) => {
    e.preventDefault();
    e.stopPropagation();
    toast.dismiss(t.id);
  };

  toast(
    (t) => (
      <>
        <div
          className="flex flex-col items-center mx-auto cursor-pointer"
          onClick={(e) => viewMessage(e, t)}
        >
          <div className="relative flex">
            <div className="flex p-2">
              <BiMessage size={40} />
              <p className="mt-1 ml-3 text-sm text-gray-600">You have a new message!</p>
            </div>

            <span className="absolute bottom-0 right-0 text-xs font-semibold text-gray-500 uppercase">
              {format(timestamp, 'M/d h:mm a')}
            </span>
          </div>
          <div className="flex w-full space-x-2 text-sm">
            <button
              type="button"
              className="w-full button button-secondary"
              onClick={(e) => closeToast(e, t)}
            >
              Close
            </button>
            <button
              type="button"
              className="w-full button button-primary whitespace-nowrap"
              onClick={(e) => viewMessage(e, t)}
            >
              View Message
            </button>
          </div>
        </div>
      </>
    ),
    {
      duration: DEFAULT_TOAST_DURATION,
      style: {
        padding: '0px',
      },
      className: 'border-2 border-blue-500 hover:bg-gray-100',
    },
  );
};

export const newAdminMessageToast = (message, connection) => {
  const timestamp = new Date(message.timestamp);

  const viewMessage = (e, t) => {
    e.preventDefault();
    toast.dismiss(t.id);
    history.push(`/admin/users/${connection.receiverId}/chat`);
  };

  const closeToast = (e, t) => {
    e.preventDefault();
    e.stopPropagation();
    toast.dismiss(t.id);
  };

  toast(
    (t) => (
      <div className="relative">
        <div
          onClick={(e) => closeToast(e, t)}
          className="absolute top-0 left-0 flex justify-center w-4 h-4 -mt-2 -ml-4 align-middle bg-gray-100 border rounded-full hover:bg-gray-300"
        >
          <GrFormClose size={14} />
        </div>
        <div
          className="flex flex-col items-center mx-auto cursor-pointer hover:bg-gray-100"
          onClick={(e) => viewMessage(e, t)}
        >
          <div className="relative flex">
            <div className="flex p-2">
              <BiMessage size={40} />
              <p className="mt-1 ml-3 text-sm text-gray-600">You have a new message!</p>
            </div>

            <span className="absolute bottom-0 right-0 text-xs font-semibold text-gray-500 uppercase">
              {format(timestamp, 'M/d h:mm a')}
            </span>
          </div>
        </div>
      </div>
    ),
    {
      duration: DEFAULT_TOAST_DURATION,
      style: {
        padding: '0px',
      },
    },
  );
};

export const newWaveToast = (wave, currentUser) => {
  const timestamp = new Date(wave.timestamp);

  const action = (e, t) => {
    e.preventDefault();
    toast.dismiss(t.id);
    if (wave.connection.status === 'PENDING') {
      history.push(`/connections/requests`);
    } else if (wave.connection.status === 'ACCEPTED') {
      history.push(`/connections/messages/${wave.connection.slug}`);
    }
  };

  const connectionUser =
    wave.connection.initiator.id === currentUser.id
      ? wave.connection.receiver
      : wave.connection.initiator;

  toast(
    (t) => (
      <div
        className="relative flex items-center p-6 mx-auto cursor-pointer"
        onClick={(e) => action(e, t)}
      >
        <span className="absolute top-0 right-0 px-2 mt-2 mr-2 text-xs font-bold text-white uppercase bg-blue-500 border rounded-full">
          New
        </span>
        <span className="absolute bottom-0 right-0 py-1 m-1 mr-3 text-xs font-semibold text-gray-500 uppercase">
          {format(timestamp, 'M/d h:mm')}
        </span>

        {wave.connection.status === 'PENDING' && <FaRegSmile size={40} />}
        {wave.connection.status === 'ACCEPTED' && <RiChatSmile3Line size={40} />}

        <div className="ml-5">
          <h4 className="text-lg font-semibold leading-tight text-gray-900">
            {connectionUser.firstName}
          </h4>
          <p className="text-sm text-gray-600">
            {wave.connection.status === 'PENDING' && <>Waved at you!</>}
            {wave.connection.status === 'ACCEPTED' && <>Waved back! Send them a message!</>}
          </p>
        </div>
      </div>
    ),
    {
      duration: DEFAULT_TOAST_DURATION,
      style: {
        padding: '0px',
      },
    },
  );
};

export const waveToast = (message, connected = false) => {
  return genericToast(message, { icon: '👋' }, connected);
};

export const messageOnlyToast = (message, connected = false) => {
  return genericToast(message, {}, connected);
};
