import React from 'react';
import ModalLayout from '../Layout/ModalLayout';

type ModalProps = {
  show: boolean;
  hideAction: () => void;
  thin?: boolean;
  lockOpen?: boolean;
  children: React.ReactNode;
  icon?;
  title: string;
  onConfirm?: () => void;
  onSecondary?: () => void;
  cancelLabel?: string;
  loading?: boolean;
  confirmLabel?: string;
};

const Modal: React.FC<ModalProps> = ({
  show,
  hideAction,
  lockOpen = false,
  children,
  title,
  loading = false,
  icon = null,
  onConfirm,
  onSecondary,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
}) => {
  return (
    <>
      {show && (
        <>
          <div
            className="fixed inset-0 z-50 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="items-end justify-center block min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <ModalLayout
                icon={icon}
                title={title}
                onConfirm={onConfirm}
                onSecondary={onSecondary}
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}
                loading={loading}
              >
                {children}
              </ModalLayout>
            </div>
          </div>
          <div>
            <div
              className="fixed inset-0 z-30 transition-opacity ease-in bg-gray-500 bg-opacity-75"
              aria-hidden="true"
              onClick={(e) => {
                if (e.target === e.currentTarget && !lockOpen) {
                  hideAction();
                }
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};
export default Modal;
