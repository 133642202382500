import { authMe } from 'actions/auth';
import OnboardingLayout from 'components/Layout/OnboardingLayout';
import { history } from 'helpers/history';
import { ProfileType } from 'models/profileType';
import React from 'react';
import toast from 'react-hot-toast';
import { HiExternalLink } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { isStaging } from 'selectors';
import userService from 'services/user.service';
import { AppDispatch } from 'store';
import { ProfileStatus } from 'types';

const FinishedOnboarding = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();

  const getStarted = async () => {
    await userService.setActive();
    dispatch(authMe()).then(() => {
      history.push('/');
      toast.success("You've been accepted!");
    });
  };

  const isMigrating = currentUser?.profile?.status === ProfileStatus.MIGRATING || isStaging();
  const isRumi = currentUser?.profile?.type === ProfileType.RUMI;

  const isOutOfState =
    currentUser?.profile?.location?.state && currentUser?.profile?.location?.state !== 'Minnesota';

  const videoUrl = currentUser.profile.alreadyMade
    ? 'https://vimeo.com/568526935'
    : isRumi
    ? 'https://vimeo.com/564229999'
    : 'https://vimeo.com/564230198';
  const title = isMigrating ? 'Welcome to the new Rumi!' : `Thanks for signing up!`;
  const subtitle = isMigrating ? (
    <>
      Thanks for finishing your new Rumi profile! <br />
      <br />
      Watch the video and click "Start searching" to get started.
    </>
  ) : isOutOfState ? (
    <>
      Unfortunately, we're only available in Minnesota at this time.
      <br />
      <br />
      If you have plans to move to Minnesota, or believe this is in error, please contact us at{' '}
      <a href="mailto:info@meetmyrumi.com" className="font-semibold underline">
        info@meetmyrumi.com
      </a>
      .
    </>
  ) : (
    <>
      We can't wait to have you on board. For the safety of our community, your profile is now being
      reviewed. <br />
      <br /> Expect a phone call from us within a day or two to discuss next steps, or give us a
      call at <b>651-695-5805</b> to get the process started right away.
    </>
  );

  return (
    <>
      <OnboardingLayout childrenPadding="0" title={title} subtitle={subtitle}>
        <div className="flex flex-col">
          <div className="relative mb-8">
            {!isOutOfState && (
              <ReactPlayer
                width="100%"
                className="relative z-20"
                url={videoUrl}
                controls={true}
                onStart={() => {
                  userService.watchedVideo();
                }}
              />
            )}
          </div>
          {isMigrating ? (
            <div>
              <span className="font-semibold text-white">When you're finished...</span>
              <button
                onClick={getStarted}
                className="relative z-20 w-full button button-primary-white"
              >
                Start searching
              </button>
            </div>
          ) : (
            <div>
              {!isOutOfState && <span className="font-semibold text-white">While you wait...</span>}
              <button
                onClick={() => window.open('https://meetmyrumi.com')}
                className="relative z-20 w-full button button-primary-white"
              >
                Visit our website <HiExternalLink className="ml-1.5" size={20} />
              </button>
            </div>
          )}
          <div className="relative bottom-0 right-0 z-0 mt-8 mb-24 opacity-50 sm:absolute sm:w-1/2">
            <img alt="" src={require('../../assets/story.svg').default} />
          </div>
        </div>
      </OnboardingLayout>
    </>
  );
};

export default FinishedOnboarding;
