import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const CollapsibleCard = ({ collapsed, children, title, icon }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

  return (
    <>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm lg:flex">
        <div className="flex flex-1 flex-col justify-between p-4 leading-normal">
          <div
            className="flex justify-between cursor-pointer"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <span className="flex flex-row items-center text-xl font-bold">
              {icon}
              {title}
            </span>

            <button className="button button-flat text-black">
              {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          </div>

          <div className={`mt-2 ${isCollapsed ? 'hidden' : 'block'}`} aria-expanded={isCollapsed}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapsibleCard;
