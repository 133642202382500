import { isProduction, isStaging } from 'selectors';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './reducers';

let middleware = [thunk as ThunkMiddleware<any, any>];

if (!isProduction() && !isStaging()) {
  middleware = [...middleware, logger];
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type AppDispatch = typeof store.dispatch;

export default store;
