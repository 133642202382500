import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckButton from 'react-validation/build/button';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { changeEmail } from '../../actions/auth';
import { required, validEmail } from '../../helpers/validators';
import { AppDispatch } from '../../store';

const ChangeEmail = () => {
  const form: Form = useRef();
  const checkBtn: CheckButton = useRef();

  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);

      dispatch(changeEmail(newEmailAddress))
        .then((res) => {
          setMessage(res);
          setSuccessful(true);
          setLoading(false);
        })
        .catch((err) => {
          setMessage(err);
          setSuccessful(false);
          setLoading(false);
        });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <Form onSubmit={handleSubmit} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="newEmail">New Email</label>
                <Input
                  type="email"
                  className="form-control"
                  name="newEmail"
                  onChange={(e) => setNewEmailAddress(e.target.value)}
                  validations={[required, validEmail]}
                  data-hj-allow
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block" disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm"></span>}
                  Change Email
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default ChangeEmail;
