import Spinner from 'components/Common/Spinner';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { getCurrentUser } from 'selectors';
import Main from './AdminMain';
import AdminLayout from './components/Common/AdminLayout';
import routes from './Routes';

const Admin = ({ user }) => {
  return (
    <>
      <AdminLayout>
        <Main>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {routes.map((route, i) => {
                return route.component ? (
                  <Route
                    key={i}
                    exact={route.exact}
                    path={`${route.path}`}
                    render={(props: any) => {
                      return <route.component {...props} />;
                    }}
                  />
                ) : null;
              })}
              <Redirect to="/admin/dashboard?r=admin" />
            </Switch>
          </Suspense>
        </Main>
      </AdminLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

export default connect(mapStateToProps)(Admin);
