import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AlreadyMadeMatchRumi from './Authentication/AlreadyMadeMatchRumi';
import CareHours from './OnboardingCare/CareHours';
import CareLanding from './OnboardingCare/CareLanding';
import Reference from './OnboardingCare/Reference';
import HousingLivingSituationNew from './OnboardingHousing/HousingLivingSituationNew';
import HousingPreferences from './OnboardingHousing/HousingPreferences';
import AlternativeContact from './OnboardingStory/AlternativeContact';
import CaseManager from './OnboardingStory/CaseManager';
import FinishedOnboarding from './OnboardingStory/FinishedOnboarding';
import OnboardingIndex from './OnboardingStory/Index';
import ProfilePhoto from './OnboardingStory/ProfilePhoto';
import StoryBio from './OnboardingStory/StoryBio';
import StoryGender from './OnboardingStory/StoryGender';
import StoryLanding from './OnboardingStory/StoryLanding';
import StoryPets from './OnboardingStory/StoryPets';

const Onboarding = () => {
  return (
    <Switch>
      <Route exact path="/onboarding/friends-family" component={AlreadyMadeMatchRumi} />
      <Route exact path="/onboarding/case-manager" component={CaseManager} />
      <Route exact path="/onboarding/alternative-contact" component={AlternativeContact} />
      <Route exact path="/onboarding/profile" component={StoryLanding} />
      <Route exact path="/onboarding/profile/gender" component={StoryGender} />
      <Route exact path="/onboarding/profile/pets" component={StoryPets} />
      <Route exact path="/onboarding/profile/bio" component={StoryBio} />
      <Route exact path="/onboarding/profile/picture" component={ProfilePhoto} />
      <Route
        exact
        path="/onboarding/housing/living-situation"
        component={HousingLivingSituationNew}
      />
      <Route exact path="/onboarding/housing/preferences" component={HousingPreferences} />
      <Route exact path="/onboarding/care" component={CareLanding} />
      <Route exact path="/onboarding/care/hours" component={CareHours} />
      <Route exact path="/onboarding/care/reference" component={Reference} />
      <Route exact path="/onboarding/thanks" component={FinishedOnboarding} />
      <Route exact path="/onboarding" component={OnboardingIndex} />
      <Redirect to="/onboarding" />
    </Switch>
  );
};

export default Onboarding;
