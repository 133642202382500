import animateScrollTo from 'animated-scroll-to';
import HelpButton from 'components/Common/HelpButton';
import MobileLogo from 'components/Common/MobileLogo';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BiHomeHeart, BiLeftArrowAlt } from 'react-icons/bi';
import { FaRegMap } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { history } from '../../helpers/history';
import Header from '../Common/Header';
import AccountSettings from './AccountSettings';
import LivingPreferencesSettings from './LivingPreferencesSettings';
import LocationSettings from './LocationSettings';
import SettingsMenu from './SettingsMenu';

const Settings = () => {
  const navLinks: any = [
    {
      label: 'Account',
      to: '/settings/account',
      icon: (size, className) => <FiSettings size={size} className={className} />,
    },
    {
      label: 'Housing',
      to: '/settings/housing',
      icon: (size, className) => <FaRegMap size={size} className={className} />,
    },
    {
      label: 'Preferences',
      to: '/settings/preferences',
      icon: (size, className) => <BiHomeHeart size={size} className={className} />,
    },
    // {
    //   label: 'Notifications',
    //   to: '/settings/notifications',
    // },
  ];

  useEffect((): any => {
    let mounted = true;

    history.listen((location) => {
      if (mounted) {
        const scrollDiv: any = document.querySelector('#settings');
        if (scrollDiv) {
          animateScrollTo(0, { elementToScroll: scrollDiv, maxDuration: 800 });
        }
      }
    });
    return () => (mounted = false);
  }, []);

  const isSubLevel = navLinks.find((nav) => history.location.pathname.indexOf(nav.to) > -1);

  return (
    <>
      <Helmet>
        <title>Settings | Rumi</title>
      </Helmet>
      <div className="settings flex flex-col h-full overflow-hidden">
        <div className="flex flex-col">
          <Header className="header header-small relative justify-between">
            {!isSubLevel && <MobileLogo />}
            <div className="flex flex-row items-center">
              {isSubLevel ? (
                <>
                  <button
                    className="flex items-center justify-center mr-2 p-2 md:hidden"
                    onClick={() => history.goBack()}
                  >
                    <BiLeftArrowAlt size={28} /> <h2>Settings</h2>
                  </button>
                  <h2 className="hidden md:flex">Settings</h2>
                </>
              ) : (
                <h2>Settings</h2>
              )}
            </div>
            <div className="flex flex-col">
              <HelpButton />
            </div>
          </Header>
          <div className="hidden flex-col bg-white border-b border-t select-none md:flex">
            <div className="flex flex-1 max-w-xl sm:flex-row">
              {navLinks.map((navLink) => (
                <Link
                  key={navLink.label}
                  to={navLink.to}
                  className={`tab flex justify-center ${
                    history.location.pathname === navLink.to ? 'active' : ''
                  }`}
                >
                  {navLink.icon(24, 'mr-2')}
                  {navLink.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto" id="settings">
          <Switch>
            <Route exact path="/settings" component={SettingsMenu} />
            <Route exact path="/settings/account" component={AccountSettings} />
            <Route exact path="/settings/preferences" component={LivingPreferencesSettings} />
            <Route exact path="/settings/housing" component={LocationSettings} />
            {/* <Route exact path="/settings/notifications" component={NotificationSettings} /> */}
            <Redirect to="/settings" />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default React.memo(Settings, () => false);
