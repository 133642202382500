import { authMe } from 'actions/auth';
import { updateContact } from 'actions/user';
import Card from 'components/Common/Card';
import FormField from 'components/Common/FormField';
import LoadingButton from 'components/Common/LoadingButton';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import { getOnboardingLocalStorage } from 'helpers/getOnboardingState';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { RootState } from 'reducers';
import { AppDispatch } from 'store';
import { ContactType } from 'types';
import OnboardingLayout from '../Layout/OnboardingLayout';

type FormData = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
};

const AlternativeContact = ({ history }) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      firstName:
        currentUser?.contacts?.find((contact) => contact.type === ContactType.ALTERNATIVE)
          ?.firstName || '',
      lastName:
        currentUser?.contacts?.find((contact) => contact.type === ContactType.ALTERNATIVE)
          ?.lastName || '',
      email:
        currentUser?.contacts?.find((contact) => contact.type === ContactType.ALTERNATIVE)?.email ||
        '',
      phone:
        currentUser?.contacts?.find((contact) => contact.type === ContactType.ALTERNATIVE)?.phone ||
        '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const alternativeContact = !!currentUser?.contacts?.find(
    (contact) => contact.type === ContactType.ALTERNATIVE,
  );

  const handleSaveAlternativeContact = async ({ firstName, lastName, email, phone }: FormData) => {
    if (isLoading) {
      return;
    }

    setGenericError('');
    setIsLoading(true);
    const type = ContactType.ALTERNATIVE;
    const phoneNumber = phone?.replace(/[-() ]/g, '');

    try {
      if (firstName || lastName || email || phone) {
        await dispatch(updateContact(firstName, lastName, email, phoneNumber, type));
        await dispatch(authMe());
      } else {
        let onboarding = getOnboardingLocalStorage();
        onboarding.altSkipped = true;
        localStorage.setItem('onboarding', JSON.stringify(onboarding));
      }
      history.push('/onboarding');
    } catch (error) {
      console.error(error);
      setGenericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const form = (
    <form onSubmit={handleSubmit(handleSaveAlternativeContact)}>
      {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
      <div className="flex space-x-2">
        <div className="w-full mb-4">
          <FormField
            label="First name"
            type="text"
            {...register('firstName')}
            placeholder="First"
            errors={errors}
            data-hj-allow
          />
        </div>
        <div className="w-full mb-4">
          <FormField
            label="Last name"
            type="text"
            {...register('lastName')}
            placeholder="Last"
            errors={errors}
            data-hj-allow
          />
        </div>
      </div>
      <div className="mb-4">
        <FormField
          label="Email"
          type="email"
          {...register('email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Please enter a valid email address',
            },
          })}
          placeholder="example@email.com"
          errors={errors}
          data-hj-allow
        />
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          defaultValue=""
          name="phone"
          rules={{
            pattern: {
              value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
              message: 'Please enter a valid phone number',
            },
          }}
          render={({ field }) => (
            <MaskedInput
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              {...field}
              render={(ref, props) => (
                <FormField
                  label="Phone number"
                  type="tel"
                  placeholder="(xxx) xxx-xxxx"
                  errors={errors}
                  data-hj-allow
                  ref={(input) => ref(input)}
                  {...props}
                />
              )}
            />
          )}
        />
      </div>
      <div className="flex justify-around">
        <LoadingButton
          isLoading={isLoading}
          className="w-full mt-4"
          variant={isDirty || alternativeContact ? 'primary' : 'secondary'}
        >
          {isDirty || alternativeContact ? 'Save and continue' : 'Skip'}
        </LoadingButton>
      </div>
    </form>
  );

  return (
    <OnboardingLayout
      childrenPadding="0"
      title={
        <div className="flex flex-row space-x-5">
          <h3 className="text-3xl font-bold text-white sm:text-4xl">Alternative Contact</h3>
          <img
            className="w-12 h-12 text-white"
            src={require('../../assets/people.svg').default}
            alt="User profile"
          />
        </div>
      }
      subtitle="Please enter your alternative contact's information."
    >
      <Card expanded={false} className="mb-20">
        {form}
      </Card>
    </OnboardingLayout>
  );
};

export default AlternativeContact;
