import React from 'react';

type Props = {
  children: any;
  className?: any;
};

const Header = (props: Props) => {
  let className = `text-white select-none`;
  if (props.className) {
    className += ' ' + props.className;
  }
  return <header className={className}>{props.children}</header>;
};

export default Header;
