import { housingPreferences } from 'actions/onboarding';
import FormField from 'components/Common/FormField';
import Modal from 'components/Common/Modal';
import PillsInput, { PillOptions } from 'components/Common/PillsInput';
import StatusMessage, { StatusType } from 'components/Common/StatusMessage';
import React, { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { HousingRequirements } from 'types';

const homeReqs: PillOptions = [
  {
    label: 'Close to public transportation',
    value: HousingRequirements.CLOSE_TO_PUBLIC_TRANSIT,
  },
  { label: 'Cannot have stairs', value: HousingRequirements.NO_STAIRS },
  { label: '1st floor bedroom', value: HousingRequirements.FIRST_FLOOR_BEDROOM },
  { label: 'Roll in shower', value: HousingRequirements.ROLL_IN_SHOWER },
  { label: 'Wheelchair accessible', value: HousingRequirements.WHEELCHAIR_ACCESSIBLE },
  { label: 'Other', value: HousingRequirements.OTHER },
];

const ProfileAboutMeHomeReqs = ({ profile }) => {
  const [saving, setSaving] = useState(false);
  const [showHomeReqsModal, setShowHomeReqsModal] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [selectedReqs, setSelectedReqs] = useState<HousingRequirements[]>([]);
  const [selectedReqsOther, setSelectedReqsOther] = useState<string>();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (profile.preferences.housing) {
      resetDefault(profile.preferences.housing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const resetDefault = (reqs) => {
    const references: any[] = homeReqs.map((ref) => ref.value);

    const selectedReferences = references.filter((ref) =>
      profile?.preferences?.housing?.includes(ref),
    );

    const otherReference = profile?.preferences?.housing?.find((ref) => !references.includes(ref));

    if (otherReference) {
      selectedReferences.push(HousingRequirements.OTHER);
      setSelectedReqsOther(otherReference);
    }

    setSelectedReqs(selectedReferences);
  };

  const cancel = () => {
    resetDefault(profile?.pets);
    setShowHomeReqsModal(false);
  };

  const onChangeOptions = (options: string[]) => {
    setSelectedReqs(options as HousingRequirements[]);
  };

  const onChangeRequirementsOther = (otherReqs: string) => {
    setSelectedReqsOther(otherReqs);
  };

  const submitHomeReqs = async () => {
    setGenericError('');
    const requirements: string[] = selectedReqs;

    if (requirements.includes(HousingRequirements.OTHER) && selectedReqsOther) {
      const otherIndex = requirements.indexOf(HousingRequirements.OTHER);
      requirements.splice(otherIndex, 1);

      requirements.push(selectedReqsOther);
    }

    try {
      await dispatch(housingPreferences(selectedReqs));
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setGenericError('Please answer all fields');
      } else {
        setGenericError('An unknown server error occurred');
      }
    } finally {
      setSaving(false);
      resetDefault(profile.preferences.housing);
      setShowHomeReqsModal(false);
    }
  };

  return (
    <>
      <button
        className="absolute button button-flat right-3 top-1"
        onClick={() => setShowHomeReqsModal(true)}
      >
        Edit
      </button>

      <Modal
        show={showHomeReqsModal}
        hideAction={() => setShowHomeReqsModal(false)}
        onSecondary={() => cancel()}
        onConfirm={() => submitHomeReqs()}
        title={`Home requirements`}
        icon={(className) => <HiPencil className={className} />}
        confirmLabel={'Save changes'}
        cancelLabel={'Cancel'}
        lockOpen={true}
        loading={saving}
      >
        <div className="space-y-4">
          <p className="text-gray-600"></p>
          <div className="flex flex-col">
            <p className="text-xl">Do you have any home requirements?</p>
            <div className="w-full">
              <PillsInput
                values={selectedReqs}
                onChange={onChangeOptions}
                options={homeReqs}
                selectOne={false}
                light={true}
              />
              {selectedReqs.includes(HousingRequirements.OTHER) && (
                <FormField
                  type="text"
                  name="other"
                  placeholder="Please list other home requirements"
                  value={selectedReqsOther}
                  className="max-w-md"
                  onChange={(e) => onChangeRequirementsOther(e.target.value)}
                  data-hj-allow
                />
              )}

              {genericError && <StatusMessage type={StatusType.ERROR} message={genericError} />}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileAboutMeHomeReqs;
