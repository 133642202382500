import React, { createRef, useEffect, useState } from 'react';
import './Map.scss';

type MapConfig = {
  lat: number;
  lng: number;
  radius?: number;
  includeCircle?: boolean;
  hideMarker?: boolean;
};

const milesToMeters = (distInMiles) => {
  return distInMiles * 1609.34;
};

const mapZoom = (radiusPref) => {
  if (radiusPref > 25) {
    return 8;
  } else if (radiusPref > 15) {
    return 9;
  } else if (radiusPref > 5) {
    return 10;
  } else if (radiusPref > 2) {
    return 11;
  } else if (radiusPref > 1) {
    return 12;
  } else {
    return 13;
  }
};

const Map: React.FC<{ mapConfig: MapConfig }> = ({ mapConfig, children }) => {
  const mapRef = createRef<HTMLDivElement>();
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [circle, setCircle] = useState<any>(null);
  const { lat, lng, radius, includeCircle, hideMarker } = mapConfig;

  useEffect(() => {
    if (!mapRef.current) return;

    if (!map) {
      const newMap = new google.maps.Map(mapRef.current, {
        center: { lat, lng },
        zoom: mapZoom(radius),
        streetViewControl: false,
        mapTypeControl: false,
        disableDoubleClickZoom: true,
        draggable: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        zoomControl: false,
      });
      setMap(newMap);
      return;
    } else {
      map.setCenter({ lat, lng });
      map.setZoom(mapZoom(radius));
    }

    const radiusMeters = milesToMeters(radius);

    if (includeCircle && radius) {
      if (
        !circle ||
        circle?.getCenter()?.lat() !== lat ||
        circle?.getCenter()?.lng() !== lng ||
        circle?.getRadius() !== radiusMeters
      ) {
        if (circle) circle.setMap(null);
        const newCircle = new google.maps.Circle({
          map,
          strokeWeight: 0,
          fillColor: '#00BA88',
          fillOpacity: 0.25,
          center: { lat, lng },
          radius: milesToMeters(radius),
        });
        setCircle(newCircle);
      }
    }

    if (
      !hideMarker &&
      (!marker || marker?.getPosition()?.lat() !== lat || marker?.getPosition()?.lng() !== lng)
    ) {
      if (marker) marker.setMap(null);
      const newMarker = new google.maps.Marker({
        map,
        position: { lat, lng },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          strokeColor: '#fff',
          strokeWeight: 2,
          fillColor: '#00BA88',
          fillOpacity: 1,
        },
      });
      setMarker(newMarker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, radius, includeCircle, map]);

  return (
    <div className="relative">
      {children}
      <div className="w-100 h-96" ref={mapRef} id="map"></div>
      <div
        className="absolute z-10 left-0 top-0 w-full h-96 bg-transparent cursor-default"
        id="mapOverlay"
      ></div>
    </div>
  );
};

export default Map;
