import React from 'react';

function Main({ children }) {
  return (
    <main className="flex-1 overflow-y-auto">
      <div className="flex flex-col px-6">{children}</div>
    </main>
  );
}

export default Main;
