import classNames from 'classnames';
import React from 'react';

export type PillOption = {
  label: string;
  value: string;
};

export type PillOptions = PillOption[];

const PillsInput: React.FC<{
  onChange: (values: string[]) => void;
  options: PillOptions;
  selectOne?: boolean;
  disabled?: boolean;
  values: string[];
  light?: boolean;
}> = ({ onChange, options, selectOne, disabled = false, values, light = false }) => {
  const selectedOptions = values;

  const select = (option: PillOption) => {
    let newValue: string[];
    if (selectOne) {
      newValue = [option.value];
    } else {
      const hasOption = selectedOptions.some((sOption) => sOption === option.value);
      newValue = hasOption
        ? selectedOptions.filter((sOption) => sOption !== option.value)
        : [...selectedOptions, option.value];
    }
    onChange(newValue);
  };

  const styles = (option: PillOption) => {
    const selected = selectedOptions.some((sOption) => sOption === option.value);
    return classNames(
      light
        ? selected
          ? 'bg-blue-500 text-white ring-blue-500'
          : 'bg-white ring-gray-300'
        : selected
        ? 'button-primary-white'
        : 'button-primary',
      light
        ? 'button mr-4 px-5 py-3 text-sm rounded-full ring-offset-2 ring-2'
        : 'button m-2 mr-2 text-sm rounded-full ring-white ring-offset-2 focus:ring-offset-4 ring-1 focus:ring-1',
    );
  };

  return (
    <div className="flex flex-wrap my-2">
      {options.map((option) => (
        <button
          key={option.value}
          disabled={disabled}
          onClick={() => select(option)}
          className={styles(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default PillsInput;
